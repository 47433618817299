import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Select,
  Spacer,
  Text,
} from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useStarDataContext } from "../../StarDataContext";
import React, { useState } from "react";
import { Deductibles } from "./Deductibles";
import { sortMutliDimensionArray } from "../../../../common/ArrayFunctions";
import ReactTooltip from "react-tooltip";

import { TiInfoLarge } from "react-icons/ti";
import { FcInfo } from "react-icons/fc";
import { currencyFormat } from "../../../../../services/commonService";

const medicalLiability = [
  { value: 1000, title: "$1,000" },
  { value: 2000, title: "$2,000" },
  { value: 5000, title: "$5,000" },
];
export const STAR_COSTTOREBUILD_MIN = 75000;

export default function Coverage() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");
  const {
    coverages,
    setCoverages,
    baseDD,
    otherStructuresPercentage,
    setOtherStructuresPercentage,
    contentsPercentage,
    setContentsPercentage,
    saveDDCoverages,
    setSaveDDCoverages,
    stateCodeVal,
    validationError,
    coveragesTriggered,
    setCoveragesTriggered,
  } = useStarDataContext();
  const [basicCoverages] = useState(baseDD?.all_basic_Coverage ?? []);
  const [additionalData] = useState(baseDD?.all_endorsement_Coverage ?? []);
  const [contentsData] = useState(baseDD?.CPersonalPropPer ?? []);
  const [errorMessage, setErrorMessage] = useState("");

  let otherStructureLimits = [];
  for (let i = 0; i <= 15; i++) {
    otherStructureLimits.push(i);
  }

  let contentsLimit = [];
  for (let i = 0; i <= 75; i++) {
    contentsLimit.push(i);
  }

  const handleOtherStructuresPercentageChange = (e) => {
    setOtherStructuresPercentage(parseInt(e.target.value));
  };

  const handleContentsPercentageChange = (e) => {
    setContentsPercentage(parseInt(e.target.value));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setCoverages((prev) => {
      if (name === "HOMCVGA") {
        return {
          ...prev,
          basicCoverages: {
            ...prev.basicCoverages,
            costToRebuildHome: Math.round(parseFloat(value) / 1000) * 1000,
          },
        };
      } else if (name === "HOMCVGB") {
        return {
          ...prev,
          basicCoverages: {
            ...prev.basicCoverages,
            otherStructures:
              value.replace(/[^\d.]/g, "").length === 0
                ? null
                : parseFloat(value.replace(/[^\d.]/g, "")),
          },
        };
      } else if (name === "HOMCVGC") {
        return {
          ...prev,
          basicCoverages: {
            ...prev.basicCoverages,
            contents:
              value.replace(/[^\d.]/g, "").length === 0
                ? null
                : parseFloat(value.replace(/[^\d.]/g, "")),
          },
        };
        // } else if (name === "HOMCVGD") {
        //   return {
        //     ...prev,
        //     basicCoverages: {
        //       ...prev.basicCoverages,
        //       personalLiability: parseFloat(value),
        //     },
        //   };
      } else if (name === "HOMCVGE") {
        return {
          ...prev,
          basicCoverages: {
            ...prev.basicCoverages,
            personalLiability: parseFloat(value),
          },
        };
      } else if (name === "HOMCVGF") {
        return {
          ...prev,
          basicCoverages: {
            ...prev.basicCoverages,
            medicalPaymentsToOthers: parseFloat(value),
          },
        };
      }
    });
    if (name === "HOMCVGE" || name === "HOMCVGF") {
      setSaveDDCoverages({ ...saveDDCoverages, [name]: value });
    }
  };

  const handleBlur = (e) => {
    let inputValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setCoveragesTriggered(true);
    if (isNaN(inputValue)) {
      setErrorMessage("");
      return;
    }
    if (inputValue % 1000 !== 0) {
      setErrorMessage(
        "The value must be in increments of $1,000. Please correct the value."
      );
      return;
    }
    if (inputValue < STAR_COSTTOREBUILD_MIN) {
      setErrorMessage(
        `The minimum dwelling limit is ${currencyFormat(
          STAR_COSTTOREBUILD_MIN
        )}.`
      );
    } else {
      setErrorMessage("");
    }
    setCoverages((prevCoverages) => ({
      ...prevCoverages,
      basicCoverages: {
        ...prevCoverages.basicCoverages,
        costToRebuildHome: Math.round(inputValue / 1000) * 1000,
      },
    }));
  };

  const renderTootTip = (id) => {
    return (
      <>
        <ReactTooltip
          id={id}
          delayHide={500}
          effect="solid"
          className={id && "custom-tooltip"}
          place="right"
        >
          {id === "Cost to Rebuild Home" && (
            <Box fontSize={"15px"}>
              This covers the cost to repair your home after a loss. The cost
              depends on factors like materials, style, and age. If you're
              switching insurers, you might use the replacement cost on your
              current insurance as guidance. Keep in mind this is not the same
              as the market value or the purchase price of your home. Tip: This
              coverage is generally referred to as Dwelling or (Coverage A).
            </Box>
          )}
          {id === "Other Structures" && (
            <Box fontSize={"15px"}>
              This covers other structures on your property that aren't your
              house, like a shed, fence, gazebo, or detached garage. If you're
              switching insurers, you might use your previous coverage as
              guidance. Tip: This coverage is generally referred to as (Coverage
              B).
            </Box>
          )}
          {id === "Contents" && (
            <Box fontSize={"15px"}>
              This covers your belongings, like your furniture, clothing, and
              electronics. Estimate how much it would cost to replace all your
              stuff in case of a fire or robbery or at the very least, the
              things you can't live without. Tip: This coverage is generally
              referred to as Personal Property or (Coverage C).
            </Box>
          )}
          {id === "Personal Liability" && (
            <Box fontSize={"15px"}>
              This covers you in the event you're involved in an accident where
              someone is injured or their property is damaged, regardless of
              location. This coverage can help pay for any resulting legal fees,
              medical fees, or damage for which you are held responsible. Tip:
              This coverage is affordable, even at the maximum amounts. This is
              coverage is generally referred to as (Coverage E).
            </Box>
          )}
          {id === "Medical Payments To Others" && (
            <Box fontSize={"15px"}>
              If a guest is injured on your property, you may have to cover
              their medical costs. This coverage will help you proactively
              assist your injured guest. Tip: This coverage is affordable at the
              maximum amounts. This coverage is generally referred to as
              (Coverage F).
            </Box>
          )}
        </ReactTooltip>
        <style>
          {`
          .custom-tooltip {
            width: 500px;
            height: auto;
          }
        `}
        </style>
      </>
    );
  };

  return (
    <>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
        }}
        gap={10}
        p="25px 10px 25px 10px"
        height={"100%"}
      >
        <GridItem
          borderLeft={"1px solid var(--chakra-colors-neutral-100)"}
          borderRight={"1px solid var(--chakra-colors-neutral-100)"}
          borderBottom={"1px solid var(--chakra-colors-neutral-100)"}
          borderRadius={"12px"}
        >
          <Accordion
            borderColor={"transparent"}
            allowMultiple
            width={"100%"}
            defaultIndex={[0]}
          >
            <AccordionItem>
              <h2>
                <AccordionButton
                  // className="coverageTitle"
                  backgroundColor=" var(--chakra-colors-primary-50)"
                  borderRightRadius={"10px"}
                  borderLeftRadius={"10px"}
                  borderBottomLeftRadius={"0px"}
                  borderBottomRightRadius={"0px"}
                  borderBottom={"1px solid var(--chakra-colors-neutral-100)"}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontSize={"xl"} pt={3} pl={1}>
                      {t("basic_coverages")}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {basicCoverages?.map((item) => {
                  return (
                    <>
                      <Flex>
                        <HStack>
                          <Text
                            pl="10px"
                            fontWeight="var(--chakra-fontWeights-medium)"
                          >
                            {item?.s_ScreenName}
                          </Text>
                          <Text ml={"-2px"} color={"red"}>
                            *
                          </Text>
                          <a
                            style={{
                              marginBottom: "18px",
                              marginLeft: "-10px",
                              cursor: "pointer",
                            }}
                            data-tip
                            data-for={item?.s_ScreenName}
                          >
                            {" "}
                            <Box ml={1}>
                              <FcInfo size={18} />
                            </Box>
                            {renderTootTip(item?.s_ScreenName)}
                          </a>
                        </HStack>
                        <Spacer />
                        {item?.s_LimitCodeType === "NOEDIT" && (
                          <>
                            <Flex
                              gap={4}
                              alignItems={"center"}
                              justifyContent="flex-end"
                            >
                              <Select
                                width="85px"
                                value={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? otherStructuresPercentage
                                    : item?.s_CoverageCode === "HOMCVGC"
                                    ? contentsPercentage
                                    : ""
                                }
                                isDisabled={item?.s_CoverageCode === "HOMCVGB"}
                                cursor={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? "not-allowed"
                                    : "pointer"
                                }
                                onChange={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? handleOtherStructuresPercentageChange
                                    : item?.s_CoverageCode === "HOMCVGC"
                                    ? handleContentsPercentageChange
                                    : undefined
                                }
                                style={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? {
                                        height: "54px",
                                        border:
                                          "2px solid var(--chakra-colors-neutral-500)",
                                        borderRadius: "9px",
                                        // width:"50%"
                                        backgroundColor: "#E5E5E5",
                                      }
                                    : {
                                        height: "54px",
                                        border:
                                          "2px solid var(--chakra-colors-neutral-500)",
                                        borderRadius: "9px",
                                        // width:"50%"
                                      }
                                }
                                _disabled={{ opacity: 1 }}
                              >
                                {item?.s_CoverageCode === "HOMCVGB" &&
                                  otherStructureLimits?.map((limit) => (
                                    <option key={limit} value={limit}>
                                      {limit}%
                                    </option>
                                  ))}
                                {item?.s_CoverageCode === "HOMCVGC" &&
                                  contentsData?.map((limit) => (
                                    <option
                                      key={limit?.label}
                                      value={
                                        limit?.value &&
                                        Number(limit?.value ?? 0)
                                      }
                                    >
                                      {limit?.label}
                                    </option>
                                  ))}
                              </Select>
                              <CurrencyInput
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                className="coverageSelectField"
                                style={{
                                  padding: "25px 5px",
                                  width: "40%",
                                  border:
                                    "2px solid var(--chakra-colors-neutral-500)",
                                  borderRadius: "9px",
                                  textAlign: "right",
                                  backgroundColor: "#E5E5E5",
                                }}
                                prefix="$"
                                value={
                                  item.s_CoverageCode === "HOMCVGF"
                                    ? coverages?.basicCoverages
                                        ?.medicalPaymentsToOthers
                                    : item.s_CoverageCode === "HOMCVGB"
                                    ? coverages.basicCoverages.otherStructures
                                    : item.s_CoverageCode === "HOMCVGC"
                                    ? coverages.basicCoverages.contents
                                    : item.s_CoverageCode === "HOMCVGD"
                                    ? coverages.basicCoverages.personalLiability
                                    : ""
                                }
                                disabled={
                                  item?.s_CoverageCode === "HOMCVGB" ||
                                  item?.s_CoverageCode === "HOMCVGC"
                                }
                                name={item.s_CoverageCode}
                                // onChange={(e) => handleChange(e)}
                              />
                            </Flex>
                          </>
                        )}
                        {item.s_LimitCodeType === "NUMBER" ? (
                          <Flex direction={"column"} width={"24%"}>
                            <CurrencyInput
                              intlConfig={{ locale: 'en-US', currency: 'USD' }}
                              className="coverageSelectField"
                              style={{
                                padding: "25px 5px",
                                // width: "24%",
                                border:
                                  "2px solid var(--chakra-colors-neutral-500)",
                                borderRadius: "9px",
                                textAlign: "right",
                              }}
                              prefix="$"
                              defaultValue={
                                coverages.basicCoverages.costToRebuildHome
                              }
                              name={item.s_CoverageCode}
                              onBlur={(e) => handleBlur(e)}
                              onValueChange={(float, name, values) =>
                                setCoverages(() => ({
                                  ...coverages,
                                  basicCoverages: {
                                    ...coverages.basicCoverages,
                                    costToRebuildHome: values?.float,
                                  },
                                }))
                              }
                            />
                            {validationError.coverages &&
                            !coverages?.basicCoverages?.costToRebuildHome &&
                            !errorMessage ? (
                              <Text fontSize="14.2px" mb={0} color="red">
                                {t("Cost to rebuild home cannot be empty")}.
                              </Text>
                            ) : (
                              errorMessage && (
                                <Text fontSize="14.2px" color="red">
                                  {t(errorMessage)}
                                </Text>
                              )
                            )}
                            {validationError.coverages &&
                              !errorMessage &&
                              coverages?.basicCoverages?.costToRebuildHome >
                                0 &&
                              coverages?.basicCoverages?.costToRebuildHome <
                                STAR_COSTTOREBUILD_MIN &&
                              coveragesTriggered && (
                                <Text fontSize="14.2px" mb={0} color="red">
                                  {t("The minimum dwelling limit is")}{" "}
                                  {`${currencyFormat(STAR_COSTTOREBUILD_MIN)}`}
                                </Text>
                              )}
                          </Flex>
                        ) : item.s_LimitCodeType === "DROPDOWN" ? (
                          <Flex width={"25%"} direction={"column"}>
                            <Select
                              className="coverageSelectField"
                              // width="25%"
                              value={
                                saveDDCoverages?.[item.s_CoverageCode] || ""
                              }
                              name={item.s_CoverageCode}
                              onChange={(e) => handleChange(e)}
                              textAlign="right"
                              style={{
                                height: "54px",
                                border:
                                  "2px solid var(--chakra-colors-neutral-500)",
                                borderRadius: "9px",
                              }}
                            >
                              <option value={""}></option>
                              {item?.s_CoverageCode === "HOMCVGF" &&
                              stateCodeVal === "ms"
                                ? medicalLiability?.map((ddOptions, index) => (
                                    <option
                                      key={index}
                                      value={ddOptions?.value}
                                    >
                                      {" "}
                                      {ddOptions?.title}
                                    </option>
                                  ))
                                : sortMutliDimensionArray(
                                    "n_LimitDisplaySeq",
                                    item?.dropDown
                                  )?.map((ddOptions, index) => (
                                    <option
                                      value={ddOptions?.n_LimitIncident}
                                      selected={!index ? "selected" : false}
                                    >
                                      {ddOptions?.s_LimitScreenName}
                                    </option>
                                  ))}
                            </Select>
                            {validationError.coverages &&
                              item?.s_CoverageCode === "HOMCVGE" &&
                              !coverages?.basicCoverages?.personalLiability && (
                                <Text mb={0} color={"red"}>
                                  {t("Cannot be empty")}.
                                </Text>
                              )}
                            {validationError.coverages &&
                              item?.s_CoverageCode === "HOMCVGF" &&
                              !coverages?.basicCoverages
                                ?.medicalPaymentsToOthers && (
                                <Text mb={0} color={"red"}>
                                  {t("Cannot be empty")}.
                                </Text>
                              )}
                          </Flex>
                        ) : (
                          ""
                        )}
                      </Flex>

                      {basicCoverages.length - 1 !==
                        basicCoverages.indexOf(item) && (
                        <Box
                          my={4}
                          borderTop={"1px solid"}
                          borderColor={"gray.200"}
                        />
                      )}
                    </>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GridItem>
        <GridItem
          borderLeft={"1px solid var(--chakra-colors-neutral-100)"}
          borderRight={"1px solid var(--chakra-colors-neutral-100)"}
          borderBottom={"1px solid var(--chakra-colors-neutral-100)"}
          borderRadius={"12px"}
        >
          <Accordion
            borderColor={"transparent"}
            allowMultiple
            width={"100%"}
            defaultIndex={[0]}
          >
            <AccordionItem height="560px">
              <h2>
                <AccordionButton
                  // className="coverageTitle"
                  backgroundColor=" var(--chakra-colors-primary-50)"
                  borderRightRadius={"10px"}
                  borderLeftRadius={"10px"}
                  borderBottomLeftRadius={"0px"}
                  borderBottomRightRadius={"0px"}
                  borderBottom={"1px solid var(--chakra-colors-neutral-100)"}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontSize={"xl"} pt={3} pl={1}>
                      {t("additional_coverages")}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                height="507px"
                overflow={"auto"}
                overflowX="hidden"
              >
                <Box>
                  {additionalData?.map((item) => {
                    return (
                      <>
                        <Flex
                          mt="10px"
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <HStack>
                            <Text
                              pl="10px"
                              fontWeight="var(--chakra-fontWeights-medium)"
                            >
                              {item.s_ScreenName}
                            </Text>
                          </HStack>
                          <Spacer />
                          <Flex justifyContent={"flex-end"}>
                            {item.s_LimitCodeType === "NOEDIT" ? (
                              <CurrencyInput
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                className="coverageSelectField"
                                style={{
                                  padding: "25px 5px",
                                  border:
                                    "2px solid var(--chakra-colors-neutral-500)",
                                  borderRadius: "9px",
                                  textAlign: "right",
                                  width: "160px",
                                }}
                                prefix="$"
                              />
                            ) : (
                              ""
                            )}
                            {item.s_LimitCodeType === "SUPPFORM" ? (
                              <CurrencyInput
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                className="coverageSelectField"
                                style={{
                                  padding: "25px 5px",
                                  border:
                                    "2px solid var(--chakra-colors-neutral-500)",
                                  borderRadius: "9px",
                                  textAlign: "right",
                                  width: "160px",
                                }}
                                prefix="$"
                              />
                            ) : (
                              ""
                            )}
                            {item.s_LimitCodeType === "DROPDOWN" ? (
                              <Select
                                className="coverageSelectField"
                                minW={"158px"}
                                style={{
                                  height: "54px",
                                  border:
                                    "2px solid var(--chakra-colors-neutral-500)",
                                  borderRadius: "9px",
                                  width: "100%",
                                }}
                                textAlign="right"
                              >
                                {sortMutliDimensionArray(
                                  "n_LimitDisplaySeq",
                                  item?.dropDown
                                )?.map((ddOptions, index) => (
                                  <option
                                    value={ddOptions?.n_LimitIncident}
                                    selected={!index ? "selected" : false}
                                  >
                                    {ddOptions?.s_LimitScreenName}
                                  </option>
                                ))}
                              </Select>
                            ) : (
                              ""
                            )}
                          </Flex>
                        </Flex>
                        {additionalData.length - 1 !==
                          additionalData.indexOf(item) && (
                          <Box
                            my={4}
                            borderTop={"1px solid"}
                            borderColor={"gray.200"}
                          />
                        )}
                      </>
                    );
                  })}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GridItem>
      </Grid>

      <Flex px={"10px"}>
        <Accordion w="100%" allowToggle defaultIndex={[0]}>
          <AccordionItem
            borderRadius="xl"
            border="1px solid"
            borderColor={"neutral.100"}
            overflow={"hidden"}
            width="100%"
          >
            <AccordionButton bg={"primary.50"}>
              <Box as="span" flex="1" textAlign="left">
                <Text fontSize="xl" pt={3} pl={1}>
                  {t("Deductibles")}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel overflow="auto" pb={4}>
              <Deductibles />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  );
}
