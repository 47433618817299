import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { FaFileUpload } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import { isEmpty } from "lodash";
import { getCoreRequiredDocuments } from "../../../services/coreSystemService";
import AddAttachment from "../Common/Attachment/AddAttachment";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
    Spinner,
    Divider,
    IconButton,
} from "@chakra-ui/react";

const RequiredDocument = (props) => {
    const { poTransPK, policyNumber, policyPK, show, setShow } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [docList, setDocList] = useState([]);
    const [selectedDocData, setSelectedDocData] = useState({
        'docTrackTransPK': 0,
        'docTypeFK': 0
    });
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);

    useEffect(() => {
        getDocList();
    }, []);

    const getDocList = async () => {
        try {
            setLoading(true);
            const res = await dispatch(getCoreRequiredDocuments(poTransPK));

            if (res && !isEmpty(res.data)) {
                setDocList(res.data);
                setSelectedDocData({
                    'docTrackTransPK': 0,
                    'docTypeFK': 0,
                });
            } else {
                setDocList([]);
                toast.error(t('No data to display'));
            }
        } catch (error) {
            setDocList([]);
            toast.error(`Failed to fetch documents:` + error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleOnClick = (row) => {
        setSelectedDocData({
            'docTrackTransPK': row?.n_PoDocumenttrackTrans_PK || '',
            'docTypeFK': row?.n_DocumentType_FK || 0,
        });
        setShowAttachmentModal(true);
    }

    const handleAttachmentClose = () => {
        setShowAttachmentModal(!showAttachmentModal);
        getDocList();
    }

    const uploadOrViewFile = (cell, row) => {
        if (row.s_IsAttached === "N") {
            return (
                <IconButton
                    onClick={() => handleOnClick(row)}
                    size={"sm"}
                    icon={<FaFileUpload size={18} />}
                />
            );
        } else {
            return Moment(cell).format('MM-DD-YYYY');
        }
    }

    const columns = [
        {
            header: t("Name"),
            accessor: "s_SourceName",
            cell: (info) => (
                <Box mx="20px">
                  {info.getValue()}
                </Box>
            ),
        },
        {
            header: t("Attached"),
            accessor: "s_IsAttached",
            cell: (info) => (
                <Box mx="20px">
                  {info.getValue()}
                </Box>
            ),
        },
        {
            header: t("Action"),
            accessor: "d_UpdatedDate",
            cell: (info) => uploadOrViewFile(info.getValue(), info.row.original),
        }
    ];

    return (
        <Modal isOpen={show} onClose={() => setShow(false)} size="4xl" closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>{t('Required Document')}</ModalHeader>
                <ModalBody>
                    {loading ? (
                        <Flex justify="center" align="center" height="200px">
                            <Spinner size="xl" />
                        </Flex>
                    ) : (
                        <Box>
                            {!showAttachmentModal ? (
                                <TableContainer 
                                    borderRadius={"2xl"}
                                    border={"1px solid"}
                                    borderColor={"var(--chakra-colors-primary-50)"}
                                >
                                    <Table variant="simple" border={"1px solid"} borderRadius={"2xl"}  borderColor={"gray.200"} boxShadow={"md"} overflow={"hidden"}>
                                        <Thead bg={"var(--chakra-colors-agGridHeaderBg)"}>
                                            <Tr>
                                                {columns.map((column, index) => (
                                                    <Th key={index}>{column.header}</Th>
                                                ))}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {docList.map((doc, index) => (
                                                <Tr key={index} _hover={{ bg: "gray.100" }}>
                                                    {columns.map((column, colIndex) => (
                                                        <Td key={colIndex} p={2} border={"1px solid "} borderColor={"gray.200"}>
                                                            {column.cell
                                                                ? column.cell({ getValue: () => doc[column.accessor], row: { original: doc } })
                                                                : doc[column.accessor]}
                                                        </Td>
                                                    ))}
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <>
                                    <Flex justifyContent="flex-end" mb={4}>
                                        <Button
                                            size="sm"
                                            leftIcon={<FaRegCircleXmark />}
                                            onClick={handleAttachmentClose}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Flex>
                                    <AddAttachment
                                        docTrackTransPK={selectedDocData.docTrackTransPK ?? 0}
                                        policyNumber={policyNumber}
                                        policyPK={policyPK}
                                        docTypeFK={selectedDocData?.docTypeFK || 0}
                                        setShow={handleAttachmentClose}
                                    />
                                </>
                            )}
                        </Box>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        size="sm"
                        leftIcon={<AiOutlineClose />}
                        onClick={() => setShow(false)}
                    >
                        {t('Close')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default RequiredDocument;