import React, { useEffect, useState } from "react";
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { getTransSubTypeDDData } from "../../../services/coreSystemService";
import { deletePreCancelTransData, getPreCancelTransData, savePreCancelTransData } from "../../../services/floodService";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import CancellationQuestions from "./CancellationQuestions";
import Refund from "./Refund";
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "react-overlay-loader/styles.css";
import "./cancel.scss";
import { resetCoreStore } from "../../../store/actions";
import { checkDateisValid } from "../../../services/commonService";
import { InputAdapter, TextMask } from "react-text-mask-hoc";

var isTransSubTypeChanged = false;
const Index = (props) => {
    const { show, setShow, handleShowAlert, setShowAlert, showAlert, alertText, effectiveDate, policyNumber, policyPK, poTransPK, applicationData, termMasterFK } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // document for cancel reason 28 - Insurance no longer required by lender
    const lenderLetterDocDetails = {
        "docTypeForDisplay": "Lender letter", // Verification of no requirement
        "docScreenName": "Lender letter", // Verification of no requirement
        "docType": "LENDERLETTER"
    };

    // document for cancel reason 04 - cancelling for Duplicate NFIP policy, and 26 - for Duplicate other than NFIP policy
    const priorDecPageDocDetails = {
        "docTypeForDisplay": "Prior declaration page",
        "docScreenName": "Prior declaration page",
        "docType": "PRIORDECLARATIONPAGE"
    };

    // document for cancel reason - Building Sold, Removed, Destroyed, or Foreclosed/Contents Sold, Removed, or Destroyed
    const cancelSupportingDocDetails = {
        "docTypeForDisplay": "",
        "docScreenName": "Cancellation Supporting Document",
        "docType": "" // will be set on next step 
    };

    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [transSubType, setTransSubType] = useState("");
    const [transSubTypeDD, setTransSubTypeDD] = useState([]);
    const [transEffectiveFrom, setTransEffectiveFrom] = useState(null);
    const [haveSignedCancelForm, setHaveSignedCancelForm] = useState('NO');
    const [continueBtnText, setContinueBtnText] = useState('Continue');
    const [occupancyType, setOccupancyType] = useState('');
    const [cvgData, setCvgData] = useState([]);

    const [cancelJSON, setCancelJSON] = useState({
        "preCancelQuoteId": 0,
        "document": [
            {
                "docTypeForDisplay": "Signed Cancellation Form",
                "docScreenName": "Signed Cancellation Form",
                "docType": "SIGNEDCANCELLATIONFORM",
                "is_attached": "N",
                "path": "",
                "size": 0,
                "url": "",
                "fileName": "",
                "uploadedDate": ""
            }
        ],
        "cancellationQuestions": {},
        "cancelDate": "",
        "cancelReason": "",
        "haveSignedCancelForm": "NO",
        "refundAmt": 0,
        "status": "PRE_CANCEL"
    });

    useEffect(() => {
        if (policyPK) {
            getPreCancelData();
            isTransSubTypeChanged = false;
        }
        handleStateChange(new Date(Moment(effectiveDate)) || null, "transEffectiveFrom");
    }, [])

    useEffect(() => {
        var tempBtnText = "Continue";
        if (currentStep === 3) {
            tempBtnText = "Submit";
        }
        setContinueBtnText(tempBtnText);
    }, [currentStep])

    /** 
     * get pre request cancel quote data
     */
    const getPreCancelData = async () => {
        setLoading(true);
        await dispatch(getPreCancelTransData(policyPK, poTransPK)).then((res) => {
            if (res.status === 'Y') {
                if (res.data && res.data.hasOwnProperty("metadata")) {
                    var preCancelData = res.data, tempCancelJSON = { ...cancelJSON }, metadata = {};
                    metadata = JSON.parse(preCancelData.metadata);
                    tempCancelJSON = { ...metadata };
                    tempCancelJSON["preCancelQuoteId"] = preCancelData["id"];
                    setCancelJSON(tempCancelJSON);
                    // set state
                    setStatePreRequestData(tempCancelJSON);
                    handleStep(2);
                }

                if (res.data.hasOwnProperty("occupancyType")) {
                    setOccupancyType(res.data.occupancyType);
                }

                if (res.data.hasOwnProperty("cvgData")) {
                    setCvgData(res.data.cvgData);
                }
                getTransSubType();
            } else {
                toast.error(t("Something Went Wrong"));
            }
        }).catch((error) => {
            const { data } = error || {};
            var errorText = "Something Went Wrong";
            if (data.errors && data.errors.hasOwnProperty('text')) {
                errorText = data.errors.text;
            }
            toast.error(t(errorText));
        });
        setLoading(false);
    }

    /** 
     * get transaction subtype dd
     */
    const getTransSubType = async () => {
        const params = {
            TransType: 'CANCEL',
            product: 10
        }
        setLoading(true);
        await dispatch(getTransSubTypeDDData(params)).then((res) => {
            if (res.success === true) {
                setTransSubTypeDD(res.data.TransactionDropDown);
            } else {
                toast.error(t("Something Went Wrong"));
            }
        }).catch((error) => {
            const { data } = error || {};
            var errorText = "Something Went Wrong";
            if (data.errors && data.errors.hasOwnProperty('text')) {
                errorText = data.errors.text;
            }
            toast.error(t(errorText));
        });
        setLoading(false);
    }

    // set state from pre request cancel quote
    const setStatePreRequestData = (preCancelData) => {
        preCancelData.hasOwnProperty("cancelReason") && setTransSubType(preCancelData.cancelReason);
        preCancelData.hasOwnProperty("cancelDate") && setTransEffectiveFrom(new Date(Moment(preCancelData.cancelDate)) || null);
        preCancelData.hasOwnProperty("haveSignedCancelForm") && setHaveSignedCancelForm(preCancelData.haveSignedCancelForm);
    }

    // handle switch Change
    const handleSwitchChange = (e, state) => {
        var temp = 'NO';
        const { attributes, checked } = e.target;
        if (checked) {
            temp = attributes.checkedvalue.value;
        }
        switch (state) {
            case 'haveSignedCancelForm':
                setHaveSignedCancelForm(temp);
                setDataInCancelJSON(temp, "haveSignedCancelForm");
                break;

            default:
                break;
        }
    }

    // handle state Change
    const handleStateChange = (value, state) => {
        switch (state) {
            case 'transSubType':
                setTransSubType(value);
                setDataInCancelJSON(value, "cancelReason");
                // if cancel reason change
                var temp = false;
                if (value !== cancelJSON.cancelReason) {
                    temp = true;
                }
                isTransSubTypeChanged = temp;
                break;

            case 'transEffectiveFrom':
                if (checkDateisValid(value)) {
                    setTransEffectiveFrom(value);
                    setDataInCancelJSON((Moment(value).format("YYYY-MM-DD") || null), "cancelDate");
                }
                break;

            default:
                break;
        }
    }

    // update value in JSON
    const setDataInCancelJSON = (value, key, innerKey = "") => {
        var currentJSON = { ...cancelJSON };
        if (key === "document") {
            // check if present
            var index = currentJSON[key].findIndex((v) => { return v.docScreenName === value.docScreenName });
            if (value.hasOwnProperty("docScreenName")) {
                index === -1 && currentJSON[key].push(value);
            }
            // remove
            if (value.hasOwnProperty("remove")) {
                currentJSON[key].splice(index, 1);
            }
        } else {
            if (innerKey.length > 0) {
                currentJSON[key][innerKey] = value;
            } else {
                currentJSON[key] = value;
            }
        }
        setCancelJSON(currentJSON);
    }

    /**
     * set document in cancel json
     * @param {Object} documentDetails
     */
    const setRequiredDocumentInCancelJSON = (documentDetails) => {
        if (!documentDetails.docTypeForDisplay || !documentDetails.docScreenName || !documentDetails.docType) {
            // Missing required properties in documentDetails object
            return;
        }

        let tempDocJSON = {
            "docTypeForDisplay": documentDetails.docTypeForDisplay,
            "docScreenName": documentDetails.docScreenName,
            "docType": documentDetails.docType,
            "is_attached": "N",
            "path": "",
            "size": 0,
            "url": "",
            "fileName": "",
            "uploadedDate": ""
        };

        setDataInCancelJSON(tempDocJSON, "document");
    }

    /**
     * remove this doc from cancel json
     * @param {string} docScreenName
     */
    const removeRequiredDocumentFromJSON = (docScreenName) => {
        var tempDocJSON = {
            "remove": true,
            "docScreenName": docScreenName,
        };

        setDataInCancelJSON(tempDocJSON, "document");
    }

    /**
     * Step : 1 - First Screen, 2 - Cancellation questions, 3 - Cancel Refund
     * @param {int} currentStep 
     * @param {String} action // Next/Prev 
     */
    const handleStep = (currentStep, action = "NEXT") => {
        var nextStep = 1;
        if (action === "NEXT") {
            if (currentStep === 1) {
                if (transSubType.length > 0) {
                    // Skip cancellation questions for cancel reasons that are not available for self service
                    // Building Sold, Removed, Destroyed, or Foreclosed/Contents Sold, Removed, or Destroyed
                    if (["01", "02"].includes(transSubType)) {
                        var tempStep = 2;
                        setRequiredDocumentInCancelJSON(cancelSupportingDocDetails);

                        if (tempStep === 2) {
                            if (isTransSubTypeChanged === true) {
                                var tempJSON = {
                                    "supportingDocument": "",
                                    "documentionDate": null,
                                    "isAddressMatch": "NO",
                                    "isNameMatch": "NO",
                                    "policyHolderSignatute": "NO",
                                    "policyHolderLabel": ""
                                }
                                // update cancellation question to JSON
                                setDataInCancelJSON(tempJSON, "cancellationQuestions");
                            }
                        }

                        // for cancellation questions
                        if (tempStep === 3) {
                            setDataInCancelJSON({}, "cancellationQuestions");
                        }

                        nextStep = tempStep;
                    }
                    // Duplicate NFIP Policies
                    if (["04"].includes(transSubType)) {
                        if (isTransSubTypeChanged === true) {
                            var tempJSON = {
                                "isOtherNFIPPolicy": "NO",
                                "effectiveDateForNFIPPolicy": null,
                                "isPolicyHolderListedOnDuplicateDecMatch": "NO",
                                "isAddressMatch": "NO",
                                "policyHolderSignatute": "NO",
                                "policyHolderLabel": ""
                            }
                            // update cancellation question to JSON
                            setDataInCancelJSON(tempJSON, "cancellationQuestions");
                        }

                        nextStep = nextStep + 1;
                    }
                    //  Property Closing Did Not Occur
                    if (["08"].includes(transSubType)) {
                        if (isTransSubTypeChanged === true) {
                            var tempJSON = {
                                "willPropertyTransNotTakingPlace": "NO",
                                "policyHolderSignatute": "NO",
                                "policyHolderLabel": ""
                            }
                            // update cancellation question to JSON
                            setDataInCancelJSON(tempJSON, "cancellationQuestions");
                        }

                        nextStep = nextStep + 1;
                    }
                    //  Nullification Prior to Policy Effective Date
                    if (["60"].includes(transSubType)) {
                        if (isTransSubTypeChanged === true) {
                            var tempJSON = {
                                "propertyNotRequiredFI": "NO",
                                "policyHolderSignatute": "NO",
                                "policyHolderLabel": ""
                            }
                            // update cancellation question to JSON
                            setDataInCancelJSON(tempJSON, "cancellationQuestions");
                        }
                        var isValid = validateStep(1);
                        if (isValid === true) {
                            nextStep = nextStep + 1;
                        }
                    }
                    //  Insurance No Longer Required by Lender
                    if (["28"].includes(transSubType)) {
                        if (isTransSubTypeChanged === true) {
                            var tempJSON = {
                                "doAllLendersDontRequireFI": "",
                                "policyHolderSignatute": "NO",
                                "policyHolderLabel": ""
                            }
                            // update cancellation question to JSON
                            setDataInCancelJSON(tempJSON, "cancellationQuestions");

                            // 28 - Insurance no longer required by lender
                            setRequiredDocumentInCancelJSON(lenderLetterDocDetails);
                        }

                        nextStep = nextStep + 1;
                    } else {
                        removeRequiredDocumentFromJSON(lenderLetterDocDetails.docScreenName);
                    }
                    if (!["01", "02", "04", "08", "28", "60"].includes(transSubType)) {
                        removeRequiredDocumentFromJSON(cancelSupportingDocDetails.docScreenName);
                        // Skip cancellation questions
                        setDataInCancelJSON({}, "cancellationQuestions");
                        nextStep = 3;
                    }
                    // Add additional upload document for Cancellation Reasons "Duplicate Coverage" (ODS-4945)
                    if (["04", "26"].includes(transSubType)) {
                        setRequiredDocumentInCancelJSON(priorDecPageDocDetails);
                    } else {
                        removeRequiredDocumentFromJSON(priorDecPageDocDetails.docScreenName);
                    }
                } else {
                    toast.error(t("Please select cancel reason"));
                }
            }
            if (currentStep === 2) {
                nextStep = 2;
                var isValid = validateStep(2);
                if (isValid === true) {
                    nextStep = 3;
                }
            }
            if (currentStep === 3) {
                nextStep = 3;
                handleSubmit("SUBMIT");
            }
        } else {
            // handle Previous
            if (["01", "02", "04", "28", "60"].includes(transSubType)) {
                nextStep = currentStep - 1;
            } else {
                nextStep = 1;
            }
        }
        setCurrentStep(nextStep);
    }

    // validation
    const validateStep = (step) => {
        var isValid = true;

        // validate cancellation questions for cancel reasons
        if (step === 1) {
            //  Nullification Prior to Policy Effective Date
            if (["60"].includes(transSubType)) {
                // The policy can only be cancelled for this reason (by the agent) if they do it before the effective date. 
                if (Object.keys(cancelJSON).length > 0) {
                    if (
                        Moment().format("YYYY-MM-DD") > effectiveDate
                    ) {
                        isValid = false;
                        toast.error(t("The policy can only be cancelled for this reason before the effective date"));
                    }
                }
            }
            // validate cancel date
            if (!checkDateisValid(cancelJSON.cancelDate)) {
                isValid = false;
                toast.error(t("Enter Effective Date"));
            }
        }
        if (step === 2) {
            // Building Sold, Removed, Destroyed, or Foreclosed/Contents Sold, Removed, or Destroyed
            if (["01", "02"].includes(transSubType)) {
                if (Object.keys(cancelJSON.cancellationQuestions).length > 0) {
                    for (var key in cancelJSON.cancellationQuestions) {
                        if (["supportingDocument"].includes(key) && [null, "", undefined].includes(cancelJSON.cancellationQuestions[key])) {
                            isValid = false;
                            toast.error(t("Please select type supporting document"));
                        }
                        if (![null, "", undefined].includes(cancelJSON.cancellationQuestions['supportingDocument'])) {
                            if (["isAddressMatch", "isNameMatch", "policyHolderSignatute"].includes(key) &&
                                !["YES"].includes(cancelJSON.cancellationQuestions[key])) {
                                isValid = false;
                                toast.error(t("YES Answer required"));
                            }

                            if (["documentionDate"].includes(key) &&
                                (
                                    [null, "", undefined, '0000-00-00'].includes(cancelJSON.cancellationQuestions[key]) ||
                                    !checkDateisValid(cancelJSON.cancellationQuestions[key])
                                )) {
                                isValid = false;
                                toast.error(t("Please select date of event on the documentation"));
                            }
                        }
                    }
                }
            }

            //  Duplicate NFIP Policies
            if (["04"].includes(transSubType)) {
                if (Object.keys(cancelJSON.cancellationQuestions).length > 0) {
                    for (var key in cancelJSON.cancellationQuestions) {
                        if (["isOtherNFIPPolicy", "isPolicyHolderListedOnDuplicateDecMatch", "isAddressMatch", "policyHolderSignatute"].includes(key) &&
                            !["YES"].includes(cancelJSON.cancellationQuestions[key])) {
                            isValid = false;
                            toast.error(t("YES Answer required"));
                        }
                        // Validate effective date entered is earlier or equal to the policy effective date.
                        if (["effectiveDateForNFIPPolicy"].includes(key) &&
                            (
                                [null, "", undefined].includes(cancelJSON.cancellationQuestions[key]) ||
                                cancelJSON.cancellationQuestions[key] > effectiveDate
                            )
                        ) {
                            isValid = false;
                            toast.error(t("Please select Effective Date of other NFIP policy (earlier or equal to the policy effective date)"));
                        }
                    }
                }
            }

            //  Property Closing Did Not Occur
            if (["08"].includes(transSubType)) {
                if (Object.keys(cancelJSON.cancellationQuestions).length > 0) {
                    if (
                        cancelJSON.cancellationQuestions["willPropertyTransNotTakingPlace"] !== "YES" ||
                        cancelJSON.cancellationQuestions["policyHolderSignatute"] !== "YES"
                    ) {
                        isValid = false;
                        toast.error(t("YES Answer required"));
                    }
                }
            }

            //  Nullification Prior to Policy Effective Date
            if (["60"].includes(transSubType)) {
                if (Object.keys(cancelJSON.cancellationQuestions).length > 0) {
                    if (
                        cancelJSON.cancellationQuestions["propertyNotRequiredFI"] !== "YES" ||
                        cancelJSON.cancellationQuestions["policyHolderSignatute"] !== "YES"
                    ) {
                        isValid = false;
                        toast.error(t("YES Answer required"));
                    }
                }
            }

            //  Insurance No Longer Required by Lender
            if (["28"].includes(transSubType)) {
                if (Object.keys(cancelJSON.cancellationQuestions).length > 0) {
                    if (
                        cancelJSON.cancellationQuestions["doAllLendersDontRequireFI"] !== "YES" ||
                        cancelJSON.cancellationQuestions["policyHolderSignatute"] !== "YES"
                    ) {
                        isValid = false;
                        toast.error(t("YES Answer required"));
                    }
                }
            }
        }

        return isValid;
    }

    // check document is attached or not 
    const checkDocumentIsAttached = (documentArray, documentCode) => {
        let isValid = false;

        var index = documentArray.findIndex((v) => { return v.docType === documentCode });
        if (index !== -1) {
            if (documentArray[index]['is_attached'] === "Y") {
                isValid = true;
            }
        }

        return isValid;
    }

    // submit
    const handleSubmit = async (action) => {
        var params = {}, tempStatus = "PRE_CANCEL", isValid = true;
        params["currentPreCancelQuoteData"] = { ...cancelJSON };
        delete params["currentPreCancelQuoteData"]["preCancelQuoteId"];
        params["preCancelQuoteId"] = cancelJSON.preCancelQuoteId;
        params["policyPK"] = policyPK;
        params["Policy_No"] = policyNumber;
        params["d_TransEffectiveTo"] = applicationData.d_TransEffectiveTo || "";
        params["n_TermMaster_PK"] = termMasterFK || "";
        params["TransPK"] = poTransPK || "";

        if (action === "SUBMIT") {
            tempStatus = "CANCELLED"
        }
        params["currentPreCancelQuoteData"]["status"] = tempStatus;

        if (tempStatus === "CANCELLED") {
            // check if signed cancellation form is uploaded or not
            var documentArray = params['currentPreCancelQuoteData']['document'];
            if (documentArray) {
                if (checkDocumentIsAttached(documentArray, "SIGNEDCANCELLATIONFORM") === false) {
                    toast.error(t("Please upload signed cancellation form"));
                    return false;
                }

                // agents needs to upload the other policy’s declaration page when submitting their cancellation request for cancelling for Duplicate NFIP policy (cancel 04), and for Duplicate other than NFIP policy (cancel 26). (ODS-4945)
                if (["04", "26"].includes(cancelJSON.cancelReason)) {
                    if (checkDocumentIsAttached(documentArray, priorDecPageDocDetails.docType) === false) {
                        toast.error(t("Please upload prior declaration page"));
                        return false;
                    }
                }

                // Document required for Upload on Cancellation Reason - Insurance No Longer Required by Lender (ODS-5153)
                if (cancelJSON.cancelReason === "28") {
                    if (checkDocumentIsAttached(documentArray, lenderLetterDocDetails.docType) === false) {
                        toast.error(t("Please upload lender letter"));
                        return false;
                    }
                }
            }
        }

        // validate cancel date
        if (!checkDateisValid(cancelJSON.cancelDate)) {
            toast.error(t("Enter Effective Date"));
            return false;
        }

        if (isValid === true) {
            setLoading(true);
            await dispatch(savePreCancelTransData(params)).then((res) => {
                if (res.status === 'Y') {
                    setShow(false);
                    toast.success(t(res.msg));

                    // show alert after pre cancel save/update
                    if (res.data && res.data.hasOwnProperty('count')) {
                        handleShowAlert(res.data);
                    }

                    if (tempStatus === "CANCELLED") {
                        dispatch(resetCoreStore());
                        window.location.reload();
                    }
                } else {
                    toast.error(t("Something Went Wrong"));
                }
            }).catch((error) => {
                const { data } = error || {};
                var errorText = "Something Went Wrong";
                if (data.errors && data.errors.hasOwnProperty('text')) {
                    errorText = data.errors.text;
                }
                toast.error(t(errorText));
            });
            setLoading(false);
        } else {
            toast.error(t("Please upload signed cancellation form"));
        }
    }

    // delete transaction
    const deleteTransaction = async () => {
        if (cancelJSON.preCancelQuoteId > 0) {
            setLoading(true);
            await dispatch(deletePreCancelTransData(cancelJSON.preCancelQuoteId)).then((res) => {
                if (res.status === 'Y') {
                    // hide pre cancel alert after delete
                    setShowAlert(false);
                    setShow(false);
                    toast.success(t(res.msg));
                } else {
                    toast.error(t("Something Went Wrong"));
                }
                setLoading(false);
            }).catch((error) => {
                const { data } = error || {};
                var errorText = "Something Went Wrong";
                if (data.errors && data.errors.hasOwnProperty('text')) {
                    errorText = data.errors.text;
                }
                toast.error(t(errorText));
            });
            setLoading(false);
        } else {
            setShow(false);
        }
    }

    return (
        <div className="policy-details-section">
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    style={{ padding: 0 }}
                >
                    <Modal
                        className="custom-dashboard-modal"
                        size="xl"
                        show={show}
                        backdrop="static"
                    >
                        <Modal.Header className="custom-dashboard-modal-header">
                            <Modal.Title>{t('Cancel Transaction')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-dashboard-modal-body">
                            <LoadingOverlay>
                                <Loader loading={loading} />
                                {
                                    currentStep === 1 && (
                                        <>
                                            <Col xs="12">
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                >
                                                    <Row>
                                                        <Col xs="7">
                                                            <Form.Label
                                                                className="group-label"
                                                            >
                                                                {t('Cancel Reason')}:
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs="5">
                                                            <Form.Select
                                                                name="transactionType"
                                                                className="group-input"
                                                                id="select-ym-option"
                                                                value={transSubType}
                                                                onChange={(e) => {
                                                                    handleStateChange(e.currentTarget.value, "transSubType");
                                                                }}
                                                                disabled={loading}
                                                            >
                                                                <option value=''>{t('Select')}</option>
                                                                {transSubTypeDD.length > 0 && transSubTypeDD.map((v, i) => {
                                                                    return (
                                                                        <option key={i} value={v.s_AppCodeName}>
                                                                            {v.s_AppCodeNameForDisplay}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" className="mt-3">
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                >
                                                    <Row>
                                                        <Col xs="7">
                                                            <Form.Label
                                                                className="group-label"
                                                            >
                                                                {t('Expected cancellation date')}:
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs="5">
                                                            <DatePicker
                                                                id="dashboard-datepicker"
                                                                className={(loading ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                                                placeholderText="MM/DD/YYYY"
                                                                selected={transEffectiveFrom}
                                                                onChange={(date) => {
                                                                    handleStateChange(date, "transEffectiveFrom");
                                                                }}
                                                                customInput={
                                                                    <TextMask mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} Component={InputAdapter} />
                                                                }
                                                                minDate={new Date(effectiveDate)}
                                                                autoComplete="off"
                                                                disabled={loading}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )
                                }
                                {
                                    currentStep === 2 && (
                                        <CancellationQuestions
                                            loading={loading}
                                            poTransPK={poTransPK}
                                            cancelJSON={cancelJSON}
                                            applicationData={applicationData}
                                            cancellationReason={transSubType}
                                            setLoading={setLoading}
                                            setCancelJSON={setCancelJSON}
                                            setDataInCancelJSON={setDataInCancelJSON}
                                            transSubTypeDD={transSubTypeDD}
                                            occupancyType={occupancyType}
                                            cvgData={cvgData}
                                        />
                                    )
                                }
                                {
                                    currentStep === 3 && (
                                        <Refund
                                            loading={loading}
                                            policyPK={policyPK}
                                            poTransPK={poTransPK}
                                            showAlert={showAlert}
                                            alertText={alertText}
                                            termMasterFK={termMasterFK}
                                            cancelJSON={cancelJSON}
                                            setLoading={setLoading}
                                            policyNumber={policyNumber}
                                            setCancelJSON={setCancelJSON}
                                            setDataInCancelJSON={setDataInCancelJSON}
                                            transEffectiveTo={applicationData.d_TransEffectiveTo}
                                        />
                                    )
                                }
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer className="footer">
                            {
                                (currentStep > 1) && (
                                    <div className="float-left">
                                        <Button
                                            variant="outline-dark"
                                            onClick={() => handleStep(currentStep, 'Prev')}
                                            disabled={loading}
                                        >
                                            {t('Previous')}
                                        </Button>
                                    </div>

                                )
                            }{" "}
                            <Button
                                id="primary-grey-btn-flood"
                                variant="outline-dark"
                                onClick={() => {
                                    if (loading === false) {
                                        deleteTransaction();
                                    }
                                }}
                                disabled={loading}
                            >
                                {t('Exit Transaction')}
                            </Button>{" "}
                            {
                                currentStep === 3 && (
                                    <Button
                                        id="primary-colored-btn"
                                        variant="primary"
                                        onClick={() => handleSubmit("SAVE")}
                                        disabled={loading}
                                    >
                                        {t("Save for later")}
                                    </Button>
                                )
                            }{" "}
                            <Button
                                id="primary-colored-btn"
                                variant="primary"
                                onClick={() => handleStep(currentStep)}
                                disabled={loading}
                            >
                                {t(continueBtnText)}
                            </Button>{" "}
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
        </div>
    )
}

export default Index;
