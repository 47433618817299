import Http from "../Http";
import * as action from "../store/actions";
import axios from 'axios';
const policyApiUrl = process.env.REACT_APP_POLICY_CORE_URL;   
const coreServiceApiUrl = process.env.REACT_APP_CORE_API_URL;  
// To get All Products List
export function productList(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/products/list")
        .then((res) => {
          dispatch(action.setAllProducts(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Action Type Dropdown
export function actionTypeDD() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/getActionType")
        .then((res) => {
          dispatch(action.setActionTypeDD(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get List Of Issued Policies
export function getPolicyListData(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      var url = `/api/corePolicies/list?`;
      Object.entries(params).forEach(v => {
        if (v[1] !== '') {
          url = url + '&' + v[0] + '=' + v[1];
        }
      });
      Http.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To verify Policy & Agency Status
export function verifyPolicy(policyNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/verify/" + policyNo)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To Reset Core System Reducer Store
export function resetCoreStore() {
  return (dispatch) => dispatch(action.resetCoreStore());
}

//set Poilicy_PK termMaster_PK Transaction_PK in redux store
export function setPolicyPropsData(params) {
  return (dispatch) => dispatch(action.setPolicyPropsData(params));
}

//set termMaster_PK in redux store
export function setTermMaster(termMasterPK) {
  return (dispatch) => dispatch(action.setTermMaster(termMasterPK));
}

//set Transaction_PK in redux store
export function setTransaction(poTransPK) {
  return (dispatch) => dispatch(action.setTransaction(poTransPK));
}

// To get Term Selection
export function termSelection(policyNoID) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/termDD/" + policyNoID)
        .then((res) => {
          dispatch(action.setTermSelection(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}
// To get Transaction DD
export function transactionDD(policyIdPK, termMasterPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/transactionDD/" +
        policyIdPK +
        "/" +
        termMasterPK
      )
        .then((res) => {
          dispatch(action.setTransactionDD(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Policy-details --application tab
export function applicationData(transactionPk) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/getPolicyDetails/" + transactionPk)
        .then((res) => {
          dispatch(action.setApplicationData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

//get Policy Coverage Details
export function getCvg(policyPK, poTransPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/corePolicies/getCvgData/` + policyPK + "/" + poTransPK;
      Http.get(url)
        .then((res) => {
          dispatch(action.getCvgData(res.data))
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

// To get Additional Data
export function additionalData(policyPK, poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getAdditionalData/" + policyPK + "/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getAdditionalData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Mortgagee Data
export function mortgageeData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getMortgageeData/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getMortgageeData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Household Data
export function householdData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getHouseholdMember/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getHouseholdData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Additional Interest Data
export function addiIntData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getAdditionalInterest/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getAddiInterestData(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Prior Policy Loss Data
export function priorPolicyLossData(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getPriorPolicyLoss/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getPriorPolicyLossData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Form Copy Data
export function formCopyData(formInitial, poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getFormCopy/" + formInitial + "/" + poTransPK
      )
        .then((res) => {
          dispatch(action.getFormCopyData(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Billing Data
export function getBillingData(policyNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/getBilling/" + policyNo
      )
        .then((res) => {
          dispatch(action.getBillingData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Account View Data
export function accountData(termMaster, accountNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/account/" + termMaster + "/" + accountNo
      )
        .then((res) => {
          dispatch(action.getAccountData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Receivable Data
export function receivableInvoice(termMaster, accountNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/receivable/" + termMaster + "/" + accountNo
      )
        .then((res) => {
          dispatch(action.getReceivableData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Invoicing Data
export function invoiceData(termMaster, accountNo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/invoicing/" + termMaster + "/" + accountNo
      )
        .then((res) => {
          dispatch(action.getInvoiceData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// To get Invoice Premium and Fees
export function invoicePreFee(invoicePK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        "api/corePolicies/billing/invPremiumFees/" + invoicePK
      )
        .then((res) => {
          dispatch(action.getInvoicePreFeeData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

//get Policy Property Info
export function getPropertyInfo(policyNoID, termMasterPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/corePolicies/getPropertyData/` + policyNoID + '/' + termMasterPK;
      Http.get(url)
        .then((res) => {
          dispatch(action.setPropertyInfo(res.data))
          return resolve();
        }).catch((err) => {
          if (err.response) {
            console.log("error");
            return resolve(err.response);
          }
        });
    })
  );
}

//get Base Data
export function getBaseData(productIdFk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/quote/getBaseData/` + productIdFk;
      Http.get(url)
        .then((res) => {
          dispatch(action.setBaseData(res.data))
          return resolve();
        }).catch((err) => {
          if (err.response) {
            console.log("error");
            return resolve(err.response);
          }
        });
    })
  );
}

// save email Merge Commit - Developed by Vighnesh
export function saveEmail(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post("/api/corePolicies/updateEmail", params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    })
  );
}

/**
 * To get Document Type Drop Down
 * @returns 
 */
export function getDocTypes(module, subModule) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/corePolicies/getDocTypes/dd/' + module + '/' + subModule)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

/**
 * To Get Document List For policy
 * @returns 
 */
export function getDocList(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      let param = {
        sizePerPageTemp: params.pageLength,
        pageTemp: params.page,
        sourceId: params.sourceId,
        module: params.module,
        submodule: params.submodule,
        searchKey: params.searchKey,
      }
      Http.get(coreServiceApiUrl + '/api/v1/getDocUploaderDocumentList?Params=' + JSON.stringify(param))
        .then((res) => {
          let resonseData = {
            data: [],
            total: 0
          };
          let response = res?.data;
          if (response?.status == 200) {
            resonseData.data = response?.returnObject;
            resonseData.total = response?.returnCount;
          }
          return resolve(resonseData);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

/**
 * To Get Document Temp URL
 * @returns 
 */
export function generateTempUrl(params, from = 'AGENT') {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      let url = '/api/corePolicies/generateTempurl/';
      if (from == 'CORE') {
        url = coreServiceApiUrl + '/api/v1/generateTempurl/'
      }
      url = url + params.id + '/' + params.type + '/' + params.module;
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

// To verify Policy & Agency Status
export function transactionTypeData() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/newTransaction")
        .then((res) => {
          dispatch(action.getNewTransactionBaseData(res.data));
          return resolve(res.data)
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

export function saveNewTransaction(params) {

  const policyCoreApiUrl = process.env.REACT_APP_POLICY_CORE_URL;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(policyCoreApiUrl + `/api/v1/saveTransaction`,params)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

export function editCvg(params) {
  const policyCoreApiUrl = process.env.REACT_APP_POLICY_CORE_URL;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(policyCoreApiUrl + `/api/v1/editCvg`,params)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// get dec page as per the transaction
export function decPage(poTransPK, decPageType) {
  const policyCoreApiUrl = process.env.REACT_APP_POLICY_CORE_URL;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(policyCoreApiUrl + `/api/v1/Flood/Form/` + decPageType + '/' + poTransPK)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
} 

export function updateAgentTaskUserStatus(eventKey, id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(`/api/agentTask/update/${eventKey}/${id}`,[])
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

export function getReasonCode(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`api/corePolicies/getReasonCode`,params)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
  }

  export function getTransSubTypeDDData(params) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(`api/corePolicies/getTransSubTypeDD`,params)
          .then((res) => {
            return resolve(res.data)
          })
          .catch((err) => {
            if (err.response) {
              return resolve(err.response);
            }
          });
      });
    }

   
export function getEndorsementData(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`/api/endorse/getEndorsementData`, params)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

 export function deleteInformation(params) {
    return dispatch => (
      new Promise((resolve, reject) => {
        Http.post("/api/endorse/deleteInformation", params)  
        .then((res) => {
            return resolve(res.data);
          }).catch(function (error) { // catch
            return reject(error);
          });
      })
    )
  }

  export function saveEndorsmentData(params) {
      return dispatch => (
        new Promise((resolve, reject) => {
          Http.post("/api/endorse/saveEndorsmentData", params)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response.data);
              }
            });
        })
      );
  }  

  export function TransactionCancel(params) {
      return dispatch => (
        new Promise((resolve, reject) => {
          Http.post("/api/endorse/deleteTrans", params)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response.data);
              }
            });
        })
      );
  }  

export function getRequiredDocList(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post("/api/endorse/requiredDocList", params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    })
  );
}

export function UploadEndorsementDocument(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post("/api/endorse/uploadRequiredDoc", params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response.data);
          }
        });
    })
  );
} 
  
/**
 * Rate Endorsement quote
 * @param {Object} as params
 * @returns 
 */
export function rateEndorsement(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`api/endorse/rate`, params)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
  
export function getAppDD(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.post(policyApiUrl + "/api/v1/getAppDD", data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function getCorePolicyData(policyNo) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/getCorePolicyData/" + policyNo)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response.data);
          }
        });
    })
  );
}

// update Contact details
export function updateContactDetails(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post(policyApiUrl + "/api/v1/updateContactDetails", data)
        .then((res) => {
          return resolve(res?.data);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}
  
// get all notes for policy
export function getPolicyNotes(policyId) {
  var url = "/api/notes/getPolicyNotes/" + policyId;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(url).then((res) => {
        return resolve(res.data);
      }).catch(function (error) {
        return reject(error);
      });
    });
}

// save note for policy
export function savePolicyNotes(params) {
  var url = "/api/notes/savePolicyNote";
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(url, params).then((res) => {
        return resolve(res.data);
      }).catch(function (error) {
        return reject(error);
      });
    });
}

// print note for policy
export function printPolicyNotes(policyId) {
  var url = "/api/notes/printPolicyNotes/" + policyId;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(url).then((res) => {
        return resolve(res.data);
      }).catch(function (error) {
        return reject(error);
      });
    });
}

/**
 * FEMA - Pivot Api Response Log from core system
 * @param {Object} as params
 * @returns 
 */
export function pivotRespLogFromCore(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      var url = `/api/corePolicies/getPivotApiLog?filter=` + JSON.stringify(params);
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          return reject(err.message);
        })
    })
  );
}

/**
 * Update Building and Content deductible
 * @param {Object} as params
 * @returns 
 */
export function updateDeductible(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`api/corePolicies/updateDeductible`, params)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

/**
 * Update Building and Content Coverage
 * @param {Object} as params
 * @returns 
 */
export function updateCoverage(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`api/corePolicies/updateCoverage`, params)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// get CSV of filterd policies
export function getPolicyListCsv(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      var url = `/api/corePolicies/policyDataForCsv?`;
      Object.entries(params).forEach(v => {
        if (v[1] !== '') {
          url = url + '&' + v[0] + '=' + v[1];
        }
      });
      Http.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

// get policy status Drop down
export function getPolicyStatusDD() {
  var url = "/api/v1/getPolicyStatusDD";
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(policyApiUrl + url).then((res) => {
        return resolve(res.data);
      }).catch(function (error) {
        return reject(error);
      });
    });
}

// get wyo,upn code by transaction pk
export function getAgencyWyoDetails(transactionPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("api/corePolicies/getAgencyWyoDetails/" + transactionPk)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    })
  );
}

// update RCV
export function updateReplacementCost(data) {
  return dispatch => (
      new Promise((resolve, reject) => {
        Http.post(policyApiUrl + "/api/v1/Flood/updateReplacementCost", data)
          .then((res) => {
            return resolve(res?.data);
          }).catch((err) => {
            if (err.response) {
              return resolve(err.response);
            }
          });
      })
  );
}

/**
 * Get Required Document list
 */
export function getCoreRequiredDocuments(poTransPK) {
  return async dispatch => {
    try {
      const response = await Http.get(`${policyApiUrl}/api/v1/Flood/getDocumentList/${poTransPK}`);
      return response?.data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };
}