import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./policy-details.scss";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormControl, FormLabel, GridItem, Input, Select, SimpleGrid, VStack } from "@chakra-ui/react";
import { formateDate } from "../../common/FormatDate";

function FloodPropertyInfo(props) {
  const [propertyInfo, setPropertyInfo] = useState({});
  const [applicationData, setApplicationData] = useState({});
  const [baseData, setBaseData] = useState({});
  const [howAnchored, setHowAnchored] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(props.propertyInfo).length > 0) {
      setPropertyInfo(props.propertyInfo);
    }

    if (Object.keys(props.applicationData).length > 0) {
      setApplicationData(props.applicationData);
    }
    if (Object.keys(props.baseData).length > 0) {
      setBaseData(props.baseData);
    }

    if (props.propertyInfo && Object.keys(props.propertyInfo).length > 0 && props.baseData && Object.keys(props.baseData).length > 0) {
      // set how MH is anchored
      if (props.baseData.FLDHOMEANCHORED && props.propertyInfo.MM) {
        let mHData = props.propertyInfo.MM;
        let howAnchored = [];
        [{ "key": "s_NotAnchored", "code": "NOTANCHORED" },
        { "key": "s_WithManufacture", "code": "MANUFACTURESPECIFICATION" },
        { "key": "s_WithFloodpain", "code": "MANAGMENTSPECIFICATION" },
        { "key": "s_OverTheTop", "code": "TIESTOGROUNDANCHORED" }].map((v) => {
          if (![undefined, null, ''].includes(mHData[v.key]) && mHData[v.key] == 'YES') {
            return howAnchored.push(makeHowAnchored(v.code));
          }
        });

        if (howAnchored.length > 0) {
          setHowAnchored(howAnchored.join(', '));
        }
      }
    }
  }, [props.propertyInfo, props.applicationData, props.baseData]);

  const appcodeToValue = (section, key, value) => {
    if (
      Object.keys(propertyInfo).length > 0 &&
      Object.keys(baseData).length > 0 &&
      baseData[key]
    ) {
      var displayData = baseData[key].filter(
        (x) => x.s_AppCodeName === propertyInfo[section][value]
      );
      return Object.keys(displayData).length > 0 ? displayData[0].s_AppCodeNameForDisplay : '';
    }
    return "";
  };

  const makeHowAnchored = (appCode) => {
    let result = '';
    let displayValue = props.baseData.FLDHOMEANCHORED.filter((x) => x.s_AppCodeName == appCode)
    if (displayValue && displayValue.length > 0) {
      result = displayValue[0].s_AppCodeNameForDisplay;
    }
    return result;
  }

  const NewlyMappedDiscountEligibility = ({ propertyInfo }) => {
    const RA = propertyInfo?.RA || {};

    if (RA.s_IsPropNewlyMapped !== "YES") {
      return null;
    }

    return (
      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t("Newly Mapped Discount Eligibility")}
            </span>
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <VStack spacing={4} align="stretch" px={4}>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
                <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>
                    {t(
                      "Has the property been re-mapped from a non-sfha into a sfha within the last year?"
                    )}
                  </FormLabel>
                  <Input borderRadius={'lg'}
                    value={RA?.s_IsPropNewlyMapped === "YES" ? "Yes" : "No" || ""}
                    isReadOnly
                  />
                </FormControl>
                </GridItem>
                <FormControl>
                  <FormLabel>{t("Prior Community Number")}</FormLabel>
                  <Input borderRadius={'lg'} value={RA?.s_PriorCommunityNumber || ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Prior Panel Number")}</FormLabel>
                  <Input borderRadius={'lg'} value={RA?.s_PriorPanelNumber || ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Prior Map Suffix")}</FormLabel>
                  <Input borderRadius={'lg'} value={RA?.s_PriorMapSuffix || ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Prior Flood Zone")}</FormLabel>
                  <Input borderRadius={'lg'} value={RA?.s_PriorFloodZone || ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Prior Map Date")}</FormLabel>
                  <Input borderRadius={'lg'}
                    value={formateDate(RA?.d_PriorMapDate) || ""}
                    isReadOnly
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <Row>
      {/* <Col xs="12" className="billing-tab-row1">
        <CommonHeader />
      </Col> */}
      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Owner Details')}</span>
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the Policyholder/Member the owner or tenant')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODINSUREDTYPE",
                    "s_isInsuredTenant"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the Policyholder/Member a non-profit entity')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "YESNOONLY",
                    "s_IsInsuredNonProfitEnt"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the Policyholder/Member a small business with less than 100 employees')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "YESNOONLY",
                    "s_IsSmallBusinessEmp"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the policyholder/Member a condominium association')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue("RA", "YESNOONLY", "s_CondoOwnership")}
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Policy')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Waiting Period')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODWAITINGPERIOD",
                    "s_PolicyWaitingPeriod"
                  )}
                  readOnly
                />
              </Col>
              {Object.keys(propertyInfo).length > 0 &&
                ["LOANNOWAIT"].includes(
                  propertyInfo.RA.s_PolicyWaitingPeriod
                ) && (
                  <Col xs="2" sm="2" md="2" lg="2" xl="2">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Loan Closing Date')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="d_FloodLoanClosingDt"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        Object.keys(propertyInfo).length > 0
                          ? propertyInfo.RA.d_FloodLoanClosingDt
                          : ""
                      }
                      readOnly
                    />
                  </Col>
                )}
              {Object.keys(propertyInfo).length > 0 &&
                ["ROLLOVER_RENEWAL"].includes(
                  propertyInfo.RA.s_PolicyWaitingPeriod
                ) && (
                  <Col xs="2" sm="2" md="2" lg="2" xl="2">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Prior Policy Exp')}.:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="s_PriorPolicyExpDt"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        Object.keys(propertyInfo).length > 0
                          ? propertyInfo.RA.s_PriorPolicyExpDt
                          : ""
                      }
                      readOnly
                    />
                  </Col>
                )}

              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Effective Date')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(applicationData).length > 0
                      ? applicationData.d_TransEffectiveFrom
                      : ""
                  }
                  readOnly
                />
              </Col>
              {Object.keys(propertyInfo).length > 0 &&
                ["ROLLOVER_RENEWAL"].includes(
                  propertyInfo.RA.s_PolicyWaitingPeriod
                ) && (
                  <Col xs="2" sm="2" md="2" lg="2" xl="2">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Prior Policy Exp')}.:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="d_PriorNBDate"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        Object.keys(propertyInfo).length > 0
                          ? propertyInfo.RA.d_PriorNBDate
                          : ""
                      }
                      readOnly
                    />
                  </Col>
                )}
            </Row>
          </div>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Community')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Program Type')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODPROGRAMTYPE",
                    "s_FloodProgramType"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Community Number')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_CommunityNumber
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Panel Number')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_PanelNumber
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="1" sm="1" md="1" lg="1" xl="1">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Map Suffix')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_MapSuffix
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="1" sm="1" md="1" lg="1" xl="1">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Flood Zone')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_FloodZone
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('County Name')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_CountyName
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Grandfathering Type')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_GrandfatheringTypeCode
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Initial Firm Date')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.d_InitialFirmDate
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Current Firm Date')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.d_CurrentFirmDate
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Current Base Flood Elevation/ Depth (in feet)')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_BaseFloodElevation
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="5" sm="5" md="5" lg="5" xl="5">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the building located in Coastal Barrier Resources System Area (CBRS) or an Other Protected Area (OPA)')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_CBRSorOPA
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Construction')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the Structure a Mobile home')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "YESNOONLY",
                    "s_ManufactureMobileHome"
                  )}
                  readOnly
                />
              </Col>
              {Object.keys(propertyInfo).length > 0 &&
                propertyInfo.RA.s_ManufactureMobileHome === "YES" && (
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Location of Mobile Home')}:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={appcodeToValue(
                        "RA",
                        "FLOODDTCONSTRSOURCE",
                        "s_DtOfConstructionSource"
                      )}
                      readOnly
                    />
                  </Col>
                )}

              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {Object.keys(propertyInfo).length > 0 &&
                    propertyInfo.RA.s_ManufactureMobileHome === "YES"
                    ? ["LOCATEDIN"].includes(
                      propertyInfo.RA.s_DtOfConstructionSource
                    )
                      ? t("Located In a Mobile Home Park or Subdivision")
                      : t("Located Outside a Mobile Home Park or Subdivision")
                    : t("Construction Date")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.d_DateOfConstruction
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Has the building been substantially improved')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "YESNOONLY",
                    "s_BuildingCourseConstruction"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the building currently under construction')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "YESNOONLY",
                    "s_BuildingCourseConstruction"
                  )}
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Occupancy')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Please Select an Occupancy Type')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODOCCUPANCY20",
                    "s_Occupancy"
                  )}
                  readOnly
                />
              </Col>
              {Object.keys(propertyInfo).length > 0 &&
                [
                  "RESIDENTIALMOBILEHOME20",
                  "NONRESIDENTIALMOBILEHOME20",
                ].includes(propertyInfo.RA.s_Occupancy) &&
                ["ROLLOVER_RENEWAL"].includes(
                  propertyInfo.RA.s_PolicyWaitingPeriod
                ) && (
                  <Col xs="2" sm="2" md="2" lg="2" xl="2">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Is on Permanent foundation')} ?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="s_NoOfUnits"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        (Object.keys(propertyInfo).length > 0 &&
                          propertyInfo.RA.s_IsPermanentFoundation) ||
                        ""
                      }
                      readOnly
                    />
                  </Col>
                )}
              {Object.keys(propertyInfo).length > 0 &&
                ["RESIDENTIALMOBILEHOME20"].includes(
                  propertyInfo.RA.s_Occupancy
                ) && (
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t("Will the applicant or applicant's spouse live in this building more than 50% of the year")}?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={appcodeToValue(
                        "RA",
                        "YESNOONLY",
                        "s_InsuredResides"
                      )}
                      readOnly
                    />
                  </Col>
                )}
              {Object.keys(propertyInfo).length > 0 &&
                [
                  "RESIDENTIALMOBILEHOME20",
                  "NONRESIDENTIALMOBILEHOME20",
                ].includes(propertyInfo.RA.s_Occupancy) && (
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('If the building is a travel trailer, have the wheels and tires been removed')} ?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={appcodeToValue(
                        "RA",
                        "YESNOONLY",
                        "s_IsBldgTravelTailer"
                      )}
                      readOnly
                    />
                  </Col>
                )}
              {Object.keys(propertyInfo).length > 0 &&
                !["SINGLEFAMILY20"].includes(propertyInfo.RA.s_Occupancy) && (
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Total # of units in Building')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        Object.keys(propertyInfo).length > 0
                          ? propertyInfo.RA.s_NoOfUnits
                          : ""
                      }
                      readOnly
                    />
                  </Col>
                )}
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Please select the value that best describes the building')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="s_BuildingUse"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODBUILDINGUSE20",
                    "s_BuildingUse"
                  )}
                  readOnly
                />
              </Col>
              {Object.keys(propertyInfo).length > 0 &&
                [
                  "RESIDENTIALMOBILEHOME20",
                  "NONRESIDENTIALMOBILEHOME20",
                ].includes(propertyInfo.RA.s_Occupancy) && (
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('How is the mobile home or travel trailer anchored')} ?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="s_homeAnchored"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={howAnchored}
                      readOnly
                    />
                  </Col>
                )}
            </Row>
          </div>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t('Building And Foundation')}
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Building Foundation Type')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="s_Foundation"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODFOUNDATION20",
                    "s_Foundation"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the building over water')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="s_BuildingOverWater"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODBULGOVERWATER",
                    "s_BuildingOverWater"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  Total sq footage of{" "}
                  {Object.keys(propertyInfo).length > 0 &&
                    ["RESIDENTIALUNIT20", "NONRESIDENTIALUNIT20"].includes(
                      propertyInfo.RA.s_Occupancy
                    )
                    ? "unit"
                    : "building"}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_TotalSqFootage
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Total No Of Floor')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_NoOfFloors
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('No Of Elevators')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="s_NoOfElevator"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_NoOfElevator
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Building Construction Type')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue(
                    "RA",
                    "FLOODCONSTRUCTIONTYPE20",
                    "s_BldgConstructionType"
                  )}
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('No Of Detached Structures')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="termSelection"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_NoOfDetatchedStruct
                      : ""
                  }
                  readOnly
                />
              </Col>
              {Object.keys(propertyInfo).length > 0 &&
                [
                  "ELEWATEDONCRAWLSPACE20",
                  "ELEVATEDWITHENCLOSURE20",
                  "ELEVATEDWITHENCLOSURENOTPPP20",
                ].includes(propertyInfo.RA.s_Foundation) && (
                  <Col xs="2" sm="2" md="2" lg="2" xl="2">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Size of enclosure in Sq feet')}?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="s_AreaInSqFt"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        Object.keys(propertyInfo).length > 0
                          ? propertyInfo.RA.s_AreaInSqFt
                          : ""
                      }
                      readOnly
                    />
                  </Col>
                )}
            </Row>
          </div>
        </div>
      </Col>
      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t('Machinary and Equipment')}
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="6" sm="6" md="6" lg="6" xl="6">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Does the building contain Machinery and equipment that services the building')} ?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="s_IsBldgContainME"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={appcodeToValue("RA", "YESNOONLY", "s_IsBldgContainME")}
                  readOnly
                />
              </Col>
              {Object.keys(propertyInfo).length > 0 &&
                propertyInfo.RA.s_IsBldgContainME === "YES" && (
                  <Col xs="6" sm="6" md="6" lg="6" xl="6">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Is all machinery elevated to the height of attic, second floor, or higher')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={appcodeToValue(
                        "RA",
                        "YESNOONLY",
                        "s_AllMachinaryElevated"
                      )}
                      readOnly
                    />
                  </Col>
                )}
            </Row>
          </div>
        </div>
      </Col>

      {
        Object.keys(propertyInfo).length > 0 &&
        (propertyInfo.RA.s_FloodConstructionType === "FLDPREFIRM" &&
        propertyInfo.RA.s_InsuredResides === "YES") && (
          <Col xs="12" className="billing-tab-row1">
            <div id="dashboard-table-section">
              <div className="table-main-title-div">
                <span className="table-main-title-name">
                  {t('Prior Policy Information')}
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid rgba(37, 33, 63, 0.3)",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Is this a new purchase (within the last year)')}?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={appcodeToValue(
                        "RA",
                        "YESNOONLY",
                        "s_IsNewPurchase"
                      )}
                      readOnly
                    />
                  </Col>
                  {Object.keys(propertyInfo).length > 0 &&
                    propertyInfo.RA.s_IsNewPurchase === "YES" && (
                      <Col xs="2" sm="2" md="2" lg="2" xl="2">
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px" }}
                        >
                          {t('Prior Policy No')}.#
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="s_NoOfUnits"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={
                            (Object.keys(propertyInfo).length > 0 &&
                              propertyInfo.RA.s_PriorPolicyNo) ||
                            ""
                          }
                          readOnly
                        />
                      </Col>
                    )}
                  {Object.keys(propertyInfo).length > 0 &&
                    propertyInfo.RA.s_IsNewPurchase === "YES" && (
                      <Col xs="3" sm="3" md="3" lg="3" xl="3">
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px" }}
                        >
                          {t('Prior Company NAIC')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="termSelection"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={appcodeToValue(
                            "RA",
                            "YESNOONLY",
                            "s_InsuredResides"
                          )}
                          readOnly
                        />
                      </Col>
                    )}
                </Row>
                <hr />
                <Row>
                  <Col xs="6" sm="6" md="6" lg="6" xl="6">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      <b>{t('Prefirm Eligibility')}</b>
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4" sm="4" md="4" lg="4" xl="4">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Has the applicant had a prior NFIP policy for this structure')}?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        (Object.keys(propertyInfo).length > 0 &&
                          propertyInfo.RA.s_IsPriorFIPPolicy) ||
                        ""
                      }
                      readOnly
                    />
                  </Col>
                  {
                    Object.keys(propertyInfo).length > 0 &&
                    (propertyInfo.RA.s_IsPriorFIPPolicy === 'YES') && (
                      <Col xs="4" sm="4" md="4" lg="4" xl="4">
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px" }}
                        >
                          {t('Did the prior policy lapse while receiving a pre-firm discount?')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="termSelection"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={
                            (Object.keys(propertyInfo).length > 0 &&
                              propertyInfo.RA.s_IsPriorNFIPLapsed) ||
                            ""
                          }
                          readOnly
                        />
                      </Col>
                    )
                  }
                  {
                    Object.keys(propertyInfo).length > 0 &&
                    (propertyInfo.RA.s_IsPriorNFIPLapsed === 'YES') && (
                      <Col xs="4" sm="4" md="4" lg="4" xl="4">
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px" }}
                        >
                          {t('Was the lapse the result of a community suspension?')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="termSelection"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={
                            (Object.keys(propertyInfo).length > 0 &&
                              propertyInfo.RA.s_IsCommunitySuspension) ||
                            ""
                          }
                          readOnly
                        />
                      </Col>
                    )
                  }
                </Row>
              </div>
            </div>
          </Col>
        )
      }

      {
        Object.keys(propertyInfo).length > 0 &&
        !["X", "B", "C", "A99"].includes(propertyInfo.RA.s_FloodZone) && (
          <Col xs="12" className="billing-tab-row1">
            <div id="dashboard-table-section">
              <div className="table-main-title-div">
                <span className="table-main-title-name">
                  {t('Elevation Certificate')}{" "}
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid rgba(37, 33, 63, 0.3)",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('Do you have a valid elevation certificate')}?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={appcodeToValue(
                        "EC",
                        "YESNOONLY",
                        "s_isValidCertificate"
                      )}
                      readOnly
                    />
                  </Col>
                  {Object.keys(propertyInfo).length > 0 &&
                    propertyInfo.EC.s_isValidCertificate === "YES" && (
                      <Col xs="2" sm="2" md="2" lg="2" xl="2">
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px" }}
                        >
                          {t('Certificate Signature date')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="d_CertificateDate"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={
                            (Object.keys(propertyInfo).length > 0 &&
                              propertyInfo.EC.d_CertificateDate) ||
                            ""
                          }
                          readOnly
                        />
                      </Col>
                    )}
                  {Object.keys(propertyInfo).length > 0 &&
                    ["A", "AO"].includes(propertyInfo.RA.s_FloodZone) && (
                      <Col xs="4" sm="4" md="4" lg="4" xl="4">
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px" }}
                        >
                          {t('Please select the section of the elevation certificate that has been completed')}:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="d_CertificateDate"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          value={
                            (Object.keys(propertyInfo).length > 0 &&
                              propertyInfo.EC.s_SectionCompleted) ||
                            ""
                          }
                          readOnly
                        />
                      </Col>
                    )}
                </Row>
                <Row className="mt-4">
                  <Col xs="3" sm="3" md="3" lg="3" xl="3">
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      <b>{'SECTION A - PROPERTY INFORMATION'}</b>
                    </Form.Label>
                    <Form.Label
                      className="dashboard-group-label"
                      style={{ marginRight: "10px" }}
                    >
                      {t('A7. Diagram Number')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="termSelection"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      value={
                        (Object.keys(propertyInfo).length > 0 &&
                          propertyInfo.EC.s_BuildingDiagramNoCode) ||
                        ""
                      }
                      readOnly
                    />
                  </Col>

                  {Object.keys(propertyInfo).length > 0 &&
                    !["A", "AO"].includes(propertyInfo.RA.s_FloodZone) && (
                      <Col xs="8" sm="8" md="8" lg="8" xl="8">
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          <b>{t('SECTION C - BUILDING ELEVATION INFORMATION (SURVEY REQUIRED)')}</b>
                        </Form.Label>
                        <Row>
                          <Col xs="4" sm="4" md="4" lg="4" xl="4">
                            <Form.Label
                              className="dashboard-group-label"
                              style={{ marginRight: "10px" }}
                            >
                              {t('a) Top of Bottom floor (including basement, crawlspace, or enclosure flood)')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="termSelection"
                              className="dashboard-group-input"
                              id="select-ym-option"
                              value={
                                (Object.keys(propertyInfo).length > 0 &&
                                  propertyInfo.EC.n_TopBottomFloor) ||
                                ""
                              }
                              readOnly
                            />
                          </Col>
                          <Col xs="4" sm="4" md="4" lg="4" xl="4">
                            <Form.Label
                              className="dashboard-group-label"
                              style={{ marginRight: "10px" }}
                            >
                              {t('b) Top of the next higher floor')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="termSelection"
                              className="dashboard-group-input"
                              id="select-ym-option"
                              value={
                                (Object.keys(propertyInfo).length > 0 &&
                                  propertyInfo.EC.n_TopHigherFloor) ||
                                ""
                              }
                              readOnly
                            />
                          </Col>
                          <Col xs="4" sm="4" md="4" lg="4" xl="4">
                            <Form.Label
                              className="dashboard-group-label"
                              style={{ marginRight: "10px" }}
                            >
                              {t('c) Lowest adjacent (finished) grade next to building (LAG)')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="termSelection"
                              className="dashboard-group-input"
                              id="select-ym-option"
                              value={
                                (Object.keys(propertyInfo).length > 0 &&
                                  propertyInfo.EC.n_LowestAdjacent) ||
                                ""
                              }
                              readOnly
                            />
                          </Col>
                        </Row>
                      </Col>
                    )
                  }
                  {
                    Object.keys(propertyInfo).length > 0 &&
                    ["A", "AO"].includes(propertyInfo.RA.s_FloodZone) && (
                      <Col>
                        <Form.Label
                          className="dashboard-group-label"
                          style={{ marginRight: "10px" }}
                        >
                          <b>{t('SECTION E - BUILDING ELEVATION INFORMATION (SURVEY NOT REQUIRED) FOR ZONE AO AND ZONE A (WITHOUT BFE)')}</b>
                        </Form.Label>
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <Form.Label
                              className="dashboard-group-label"
                              style={{ marginRight: "10px" }}
                            >
                              {t('E1. Provide elevation information for the following and check the appropriate boxes to show whether the elevation is above or below the highest adjacent grade (HAG) and the Lowest adjacent grade (LAG)')}
                            </Form.Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4" sm="4" md="4" lg="4" xl="4">
                            <Form.Label
                              className="dashboard-group-label"
                              style={{ marginRight: "10px" }}
                            >
                              {t('a) Top of Bottom floor (including basement,crawlspace, or enclosure flood) is')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="termSelection"
                              className="dashboard-group-input"
                              id="select-ym-option"
                              value={
                                (Object.keys(propertyInfo).length > 0 &&
                                  propertyInfo.EC.n_TopBottomFloor) ||
                                ""
                              }
                              readOnly
                            />
                          </Col>
                          <Col xs="4" sm="4" md="4" lg="4" xl="4">
                            <Form.Label
                              className="dashboard-group-label"
                              style={{ marginRight: "10px" }}
                            >
                              {t('b) The next higher floor of the building (E2) is')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="termSelection"
                              className="dashboard-group-input"
                              id="select-ym-option"
                              value={
                                (Object.keys(propertyInfo).length > 0 &&
                                  propertyInfo.EC.n_TopHigherFloor) ||
                                ""
                              }
                              readOnly
                            />
                          </Col>
                        </Row>
                      </Col>
                    )
                  }
                </Row>
              </div>
            </div>
          </Col>
        )}
         <NewlyMappedDiscountEligibility propertyInfo={propertyInfo}/>
        </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    applicationData: state.coreSystem.applicationData,
    propertyInfo: state.coreSystem.propertyInfo,
    baseData: state.coreSystem.baseData,
  };
};
export default connect(mapStateToProps)(FloodPropertyInfo);
