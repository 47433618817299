import React, { useState, useEffect, Component } from "react";
import { Row, Col, Form, Card, Table, Button, Input, Accordion } from "react-bootstrap";
import { getClaimForMasters, getPolicyClaimDetails, checkClaimExist, getReportAClaim } from "../../services/claimService";
import { saveReportAClaim } from "../../services/claimService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from 'moment';
import { EncryptOrDecrypt } from "../../services/commonService";
import { getZipDetails } from "../../services/floodService";
import { useTranslation } from "react-i18next";
import { FaUserCheck } from "react-icons/fa";
import Select from 'react-select';
import Switch from "react-switch";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
// import { customStyles } from "../../components/reactSelectCustomStyle";
import { toast } from 'react-toastify';
import ZipDetailsView from '../common/ZipDetailsView';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { isEmpty } from 'lodash';
import Swal from 'sweetalert2';
import { FaArrowLeft } from "react-icons/fa";
import { TextMask, InputAdapter } from 'react-text-mask-hoc';

const ReportACLaim = (props) => {
	const [tableData, setTableData] = useState([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(false);
	const userDatas = localStorage.getItem('user') && EncryptOrDecrypt(localStorage.getItem('user'), 'D');
	const [claims, setClaims] = React.useState([]);

	const [streetName, setStreetName] = useState('');
	const [googlePlace, setGooglePlace] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [zipCodeSuffix, setZipCodeSuffix] = useState('');
	const [isGPUpdate, setIsGPUpdate] = useState(false);
	const [countryName, setCountyName] = useState('');
	const [stateName, setStateName] = useState('');
	const [cityName, setCityName] = useState('');
	const [multipleCity, setMultipleCity] = useState(null);
	const [show, setShow] = useState(false);
	const [allDropDown, setAllDropDown] = useState();
	const [date1, setDate1] = useState();
	const [policyNo, setPolicyNo] = useState();
	const [policyClaimData, setPolicyClaimData] = useState();
	const [personAdrArr, setPersonAdrArr] = useState();
	const [claimExist, setClaimExist] = useState(false);
	const [claimExistView, setClaimExistView] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [jsonData, setJsonData] = useState();
	const [reasonExist, setReasonExist] = useState(false);
	const [ReportedClaimDetails, setReportedClaimDetails] = useState({});
	const [tenancyNaicPrefix, setTenancyNaicPrefix] = useState("");

	const initialState = [{
		reference_id: '',
		reportedFrom: 'AGENT',
		propertyLocation: '',
		isTemporaryAddr: '',
		contactNo: '',
		contactNo2: '',
		email: '',
		acceptSms: '',
		claimType: '',
		typeOfLoss: '',
		dateofLoss: '',
		serviceRep: '',
		catastropheLoss: '',
		eventName: '',
		claimReportedById: 5,
		personTempAddr: {},
		firstNoticeOfLoss: {
			lossDate: '',
			lossReason: '',
			TypeofLoss: '',
			insuredResides: '',
			waterLevelFeet: '',
			waterLevelInch: '',
			waterLevelNoIdea: '',
			waterRemainedDays: '',
			waterRemainedHours: '',
			waterRemainedNotIdea: '',
			allowedOther: '',
			allowedBack: '',
			isCleanUpStart: '',
			repairStart: '',
			repairs: '',
			contractorName: '',
			isResidence: '',
			isAdjuster: '',
			isMortgagee: ''
		},


	}];


	useEffect(() => {
		toast.configure();
		setLoading(true);
		let policyNo = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
		setPolicyNo(policyNo);
		if (window.location.href.includes('view-a-claim')) {
			viewAClaimFunc(policyNo);
		} else {
			setJsonData(initialState);
			reportAClaimFunc(policyNo);
		}

	}, []);

	useEffect(() => {
		if (claimExistView == true) {
			dispatch(getClaimForMasters(policyNo)).then((res) => {
				setAllDropDown(res.data);
			});		
			dispatch(getPolicyClaimDetails(policyNo)).then((res) => {
				setTenancyNaicPrefix(res.data.tenancy.toUpperCase().charAt(0)+res.data.naic);
				setPolicyClaimData(res.data);

				let poRiskmasterId_Pk = "";
				if (!isEmpty(res.data.policy_risk_master_info)) {
					poRiskmasterId_Pk = res.data.policy_risk_master_info.n_PORiskMaster_PK;
				}

				let poRiskAddresses = "";
				var newData = [...jsonData];

				if (!isEmpty(jsonData[0].propertyLocation)) {
					poRiskAddresses = jsonData[0].propertyLocation;
				} else {
					if (!isEmpty(res.data.policy_risk_master_info.addresses)) {
						poRiskAddresses = res.data.policy_risk_master_info.addresses;

					}
				}

				if (!isEmpty(jsonData[0].personTempAddr)) {
					setZipCode(jsonData[0].personTempAddr.s_PostalCode ? jsonData[0].personTempAddr.s_PostalCode : null);
					setZipCodeSuffix(jsonData[0].personTempAddr.s_PostalCode ? jsonData[0].personTempAddr.s_PostalCodeSuffix : null);
					setCityName(jsonData[0].personTempAddr.s_CityName ? jsonData[0].personTempAddr.s_CityName : null);
					setCountyName(jsonData[0].personTempAddr.s_CountryName ? jsonData[0].personTempAddr.s_CountryName : null);
					setStateName(jsonData[0].personTempAddr.s_StateName ? jsonData[0].personTempAddr.s_StateName : null);
				}

				setJsonData(newData);

				let addressSelectData = [];
				if (poRiskmasterId_Pk != "") {
					if (jsonData.length) {
						jsonData[0].propertyLocation.map((ele, index) => {
							let tempObject = {};
							tempObject.label = ((ele.s_AddressLine1) ? ele.s_AddressLine1 : "") + ((ele.cityName) ? ", " + ele.cityName.s_CityName : "") + ((ele.countyName) ? ", " + ele.countyName.s_CountyName : "") + ((ele.stateName) ? ", " + ele.stateName.s_StateName : "") + ((ele.s_PostalCode) ? ", " + ele.s_PostalCode : "");

							tempObject.value = poRiskmasterId_Pk;
							addressSelectData.push(tempObject);
						});
					}
				}

				setPersonAdrArr(addressSelectData);
				setLoading(false);
			});
		}


	}, [claimExistView]);


	useEffect(() => {
		if (claimExist == true) {
			dispatch(getClaimForMasters(policyNo)).then((res) => {
				setAllDropDown(res.data);
			});
			dispatch(getPolicyClaimDetails(policyNo)).then((res) => {
				setTenancyNaicPrefix(res.data.tenancy.toUpperCase().charAt(0)+res.data.naic)
				setPolicyClaimData(res.data);

				let poRiskmasterId_Pk = "";
				if (!isEmpty(res.data.policy_risk_master_info)) {
					poRiskmasterId_Pk = res.data.policy_risk_master_info.n_PORiskMaster_PK;
				}

				let poRiskAddresses = "";
				var newData = [...jsonData];

				if (!isEmpty(jsonData[0].propertyLocation)) {
					poRiskAddresses = jsonData[0].propertyLocation;
				} else {
					if (!isEmpty(res.data.policy_risk_master_info.addresses)) {
						newData[0].propertyLocation = res.data.policy_risk_master_info.addresses;
						poRiskAddresses = res.data.policy_risk_master_info.addresses;

					}
				}

				if (res.data.insuredPerson_info && !isEmpty(res.data.insuredPerson_info.phone_info)) {
					newData[0].contactNo = res.data.insuredPerson_info.phone_info[0].s_PhoneNumber;
				}

				if (!isEmpty(res.data.insuredPerson_info.email_info)) {
					newData[0].email = res.data.insuredPerson_info.email_info[0].s_EmailAddress;
				}

				setJsonData(newData);

				let addressSelectData = [];
				if (poRiskmasterId_Pk != "") {
					//res.data.PORiskMaster_PK = poRiskmasterId_Pk;
					if (poRiskAddresses.length) {
						poRiskAddresses.map((ele, index) => {
							let tempObject = {};
							tempObject.label = ((ele.s_AddressLine1) ? ele.s_AddressLine1 : "") + ((ele.cityName) ? ", " + ele.cityName.s_CityName : "") + ((ele.countyName) ? ", " + ele.countyName.s_CountyName : "") + ((ele.stateName) ? ", " + ele.stateName.s_StateName : "") + ((ele.s_PostalCode) ? ", " + ele.s_PostalCode : "");

							tempObject.value = poRiskmasterId_Pk;
							addressSelectData.push(tempObject);
						});
					}
				}

				setPersonAdrArr(addressSelectData);
				setLoading(false);
			});
		}


	}, [claimExist]);

	const reportAClaimFunc = (policyNo) => {

		dispatch(checkClaimExist(policyNo)).then((res) => {
			if (res.data == 0) {
				setClaimExist(true);
			} else {
				setClaimExist(false);
				Swal.fire({
					icon: 'warning',
					title: t('Please Wait'),
					text: t('One of the reported claims is already pending for this Policy'),
					confirmButtonColor: '#66e3d8',
				})

				navigate("/core/policy/" + policyNo);

			}
		});
	}

	const viewAClaimFunc = (policyNo) => {

		dispatch(getReportAClaim(policyNo)).then((res) => {
			if (res.data != null) {
				var newData = [];
				setReportedClaimDetails(res.data);
				newData.push(res.data.metadata);
				if (res.data.status == "REJECTED") {
					setReasonExist(true);
				}
				setJsonData(newData);
				setClaimExistView(true);
				setIsDisabled(true);
			} else {
				setClaimExist(false);
				Swal.fire({
					icon: 'warning',
					title: t('Please Wait'),
					text: t('Something Went Wrong'),
					confirmButtonColor: '#66e3d8',
				})

				navigate("/core/policy/" + policyNo);

			}
		});
	}
	// Inserting style only for address field
	useEffect(() => {
		document.head.insertAdjacentHTML("beforeend", `<style>#react-select-3-input { opacity: 2 !important; width: 250px !important;}</style>`)
	}, []);


	const handleSubmit = () => {
		if (jsonData[0].dateofLoss == '') {
			toast.error("Please select Date of loss");
			return;
		} else if (jsonData[0].contactNo == '') {
			toast.error("Please select Contact Number");
			return;
		} else if (personAdrArr == '') {
			toast.error("Please select Property Location");
			return;
		} 
		else if (jsonData[0].personTempAddr == '') {
			toast.error("Please select Temporary Address");
			return;
		} else if (zipCode == '' && jsonData[0].isTemporaryAddr !== '') {
			toast.error("Please select Zip Code");
			return;
		} else if (countryName == '' && jsonData[0].isTemporaryAddr !== '') {
			toast.error("Please select Country Name");
			return;
		} else if (stateName == '' && jsonData[0].isTemporaryAddr !== '') {
			toast.error("Please select State Name");
			return;
		} else if (cityName == '' && jsonData[0].isTemporaryAddr !== '') {
			toast.error("Please select City Name");
			return;
		}

		const newjsondata = jsonData[0];

		let params = {
			policyNo: policyNo,
			metadata: newjsondata
		};
		var apiError = false;

		Swal.fire({
			title: 'Please Wait',
			html: '<b>Saving Data...</b>',
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading()
			}

		}).then(function () {
			if (apiError == false) {

				navigate("/core/policy/" + policyNo);
			}
		});

		dispatch(saveReportAClaim(params)).then((res) => {
			if (res.status == 200) {
				Swal.hideLoading();
				Swal.update({
					icon: 'success',
					html: '<b>Saved Successfully</b>',
					title: '',
					confirmButtonColor: '#66e3d8',
				})
			} else {
				apiError = true;
				Swal.hideLoading();
				Swal.update({
					icon: 'error',
					html: '<b>'+res?.data?.errors?.text+'</b>',
					title: '',
					confirmButtonColor: '#66e3d8',
				})
			}

		});
	}

	const handleDropDownChange = (selectedOption, parent, name, ddCode) => {

		var parentObjectName = parent;
		var newData = [...jsonData];

		if (parentObjectName == "mainNode") {
			newData[0][name] = selectedOption[ddCode];
		} else {
			const parentObjectArr = parentObjectName.split(".");
			if (parentObjectArr.length > 0) {
				if (parentObjectArr.length == 1) {
					newData[0][parentObjectArr[0]][name] = selectedOption[ddCode];
				}
			}
		}

		if (name == 'typeOfLoss') {
			newData[0].firstNoticeOfLoss.TypeofLoss = newData[0].typeOfLoss;
		} else if (name == 'claimType') {
			newData[0].firstNoticeOfLoss.lossReason = newData[0].claimType;
		}


		setJsonData(newData);
	}

	const handleChange = (e) => {
		const { name, attributes, value } = e.target;

		var parentObjectName = attributes.parentobject.value;
		var newData = [...jsonData];

		if (parentObjectName == "mainNode") {
			newData[0][name] = value;
		} else {
			const parentObjectArr = parentObjectName.split(".");
			if (parentObjectArr.length > 0) {
				if (parentObjectArr.length == 1) {
					newData[0][parentObjectArr[0]][name] = value;
				} else if (parentObjectArr.length == 2) {
					newData[0][parentObjectArr[0]][parentObjectArr[1]][name] = value;
				}
			}
		}

		if (name == 'waterRemainedDays' || name == 'waterRemainedHours') {
			if (value != '') {
				newData[0].firstNoticeOfLoss.waterRemainedNotIdea = '';
			}
		} else if (name == 'waterLevelFeet' || name == 'waterLevelInch') {
			if (value != '') {
				newData[0].firstNoticeOfLoss.waterLevelNoIdea = '';
			}
		}
		setJsonData(newData);
	}

	const handleSwitchChange = (checkedValue, event, name) => {
		if (checkedValue == true) {
			checkedValue = 'YES';
		} else {
			checkedValue = 'NO';
		}

		var parentObjectName = name;
		var newData = [...jsonData];

		const parentObjectArr = parentObjectName.split(".");
		if (parentObjectArr.length > 0) {
			if (parentObjectArr.length == 1) {
				newData[0][parentObjectArr[0]] = checkedValue;

			} else if (parentObjectArr.length == 2) {
				newData[0][parentObjectArr[0]][parentObjectArr[1]] = checkedValue;
			}
		} else {
			newData[0][parentObjectName] = checkedValue;
		}

		setJsonData(newData);
	}

	const handleDateChange = (date, parent, name) => {

		var parentObjectName = parent;
		var newData = [...jsonData];

		if (parentObjectName == "mainNode") {
			newData[0][name] = Moment(date).format('YYYY/MM/DD');
		} else {
			const parentObjectArr = parentObjectName.split(".");
			if (parentObjectArr.length > 0) {
				if (parentObjectArr.length == 1) {
					newData[0][parentObjectArr[0]][name] = Moment(date).format('YYYY/MM/DD');
				} else if (parentObjectArr.length == 2) {
					newData[0][parentObjectArr[0]][parentObjectArr[1]][name] = Moment(date).format('YYYY/MM/DD');
				}
			}
		}

		if (name == 'dateofLoss') {
			newData[0].firstNoticeOfLoss.lossDate = newData[0].dateofLoss;
		}
		setJsonData(newData);
	}

	const handleQRadioChange = (e) => {
		const { name, value, title, attributes, checked, id } = e.target;

		var parentObjectName = attributes.parentobject.value;
		var newData = [...jsonData];

		if (parentObjectName == "mainNode") {
			newData[0][name] = value;
		} else {
			const parentObjectArr = parentObjectName.split(".");
			if (parentObjectArr.length > 0) {
				if (parentObjectArr.length == 1) {
					newData[0][parentObjectArr[0]][name] = value;
				} else if (parentObjectArr.length == 2) {
					newData[0][parentObjectArr[0]][parentObjectArr[1]][name] = value;
				}
			}
		}
		if (name == 'waterRemainedNotIdea') {
			if (value == 'YES') {
				newData[0].firstNoticeOfLoss.waterRemainedDays = '';
				newData[0].firstNoticeOfLoss.waterRemainedHours = '';
			}
		} else if (name == 'waterLevelNoIdea') {
			if (value == 'YES') {
				newData[0].firstNoticeOfLoss.waterLevelFeet = '';
				newData[0].firstNoticeOfLoss.waterLevelInch = '';
			}
		}

		setJsonData(newData);


	}

	const setDate = (date) => {
		return new Date(date);
	}

	const setMortAddr = (cell, row) => {
		return (
			row.s_AddressLine1 +
			"," +
			row.s_CityName +
			"," +
			row.s_CountyName +
			"," +
			row.s_StateCode +
			"," +
			row.s_PostalCode
		);
	}

	const columns = [
		{
			dataField: "s_PoMortgageeTypeCode",
			text: t("TYPE"),
		},
		{
			dataField: "s_FullLegalName",
			text: t("MORTGAGEES NAME"),
		},
		{
			dataField: "product_type",
			formatter: (value, row) => (
				setMortAddr(value, row)
			),
			text: t("MORTGAGEES ADDRESS"),
		},
		{
			dataField: "s_LoanNumber",
			text: t("LOAN#"),
		},
	];

	// trigger to set selected address street name
	const handleGooglAutoComplete = (val, status) => {
		if (val) {
			if (status.action == "input-change") {
				setStreetName(val);
			} else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
				setStreetName(streetName.toUpperCase());
			}
		}
	}

	// get selected zip data
	const setZipDetailsData = (zipData) => {
		var addressPK = {
			'n_CityId_FK': zipData.n_CityId_PK,
			's_ZipCounty': zipData.s_CountyName.toUpperCase(),
			'n_CountyId_FK': zipData.n_CountyId_FK,
			's_StateCode': zipData.s_StateCode.toUpperCase(),
			'n_StateId_PK': zipData.n_StateId_PK,
			's_CityName': zipData.s_CityName.toUpperCase(),
			'n_CityId_PK': zipData.n_CityId_PK,
			'n_CountryId_FK': zipData.n_CountryId_FK,
			'n_Zipcodes_PK': zipData.n_Zipcodes_PK,
			's_CountyName': zipData.s_CountyName.toUpperCase(),
			's_StreetName': streetName,

		};
		setCountyName(zipData.s_CountyName.toUpperCase());
		setStateName(zipData.s_StateCode.toUpperCase());
		setCityName(zipData.s_CityName.toUpperCase());
		handlePropertyAddressChange('', addressPK);
		setMultipleCity(null);
		setShow(false);
	}

	/**
		 * Handle Google Place Autocomplete
		 * @param {element values} e 
		 */
	const handleGooglePlace = (e) => {

		setGooglePlace(e); // setting element value to google place autocomplete
		setStreetName(e.value.structured_formatting.main_text); // setting only address line 1 as streen name
		setIsGPUpdate(true); // to trigger dependent function
		let addr = {
			s_StreetName: e.value.structured_formatting.main_text
		};
		handlePropertyAddressChange('', addr);
		getGeoCode(e.value.description); // to get geo-details based on address
		//isDataChanged(true); // to visible "Rate" Button
	}


	// get geocode from address
	const getGeoCode = (description) => {
		geocodeByAddress(description)
			.then(addr => {
				var propertyAddress = {};
				addr && addr[0].address_components.forEach(e => {
					if (e.types.includes('postal_code')) {
						setZipCode(e.short_name);
						propertyAddress = {
							...propertyAddress,
							's_PostalCode': e.short_name
						};
					} else if (e.types.includes('street_number')) {
						propertyAddress = {
							...propertyAddress,
							's_HouseNo': e.short_name
						};
					} else if (e.types.includes('locality', 'political')) {
						setCityName(e.short_name);
						propertyAddress = {
							...propertyAddress,
							's_CityName': e.short_name
						};
					} else if (e.types.includes('country', 'political')) {
						propertyAddress = {
							...propertyAddress,
							's_CountryName': e.short_name
						};
					} else if (e.types.includes('administrative_area_level_2', 'political')) {
						setCountyName(e.short_name);
						propertyAddress = {
							...propertyAddress,
							's_CountyName': e.short_name
						};

					} else if (e.types.includes('administrative_area_level_1', 'political')) {
						setStateName(e.short_name);
						propertyAddress = {
							...propertyAddress,
							's_StateName': e.short_name
						};
					} else if (e.types.includes('postal_code_suffix')) {
						setZipCodeSuffix(e.short_name);
						propertyAddress = {
							...propertyAddress,
							's_PostalCodeSuffix': e.short_name
						};
					}

					if (Object.keys(propertyAddress).length > 0) {
						handlePropertyAddressChange('', propertyAddress);
					}
				});
				if (addr && addr[0]) {
					getLatLng(addr[0]).then(({ lat, lng }) => {
						var propertyAddress = {
							's_Latitude': lat,
							's_Longitude': lng
						};
						handlePropertyAddressChange('', propertyAddress);
					});
				}
			});
	}

	// open Zip Modal
	const showMultipleCityModal = (zipData) => {
		setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
		setShow(true);
	}

	// to update value in reducer
	const handlePropertyAddressChange = (key, value) => {
		var quoteDataVar = jsonData;
		if (key.length === 0) {
			for (const [key, val] of Object.entries(value)) {
				quoteDataVar[0]['personTempAddr'][key] = val;
			}
		} else {
			if (key === 's_StreetName') {
				quoteDataVar[0]['personTempAddr']['s_AddressLine1'] = value;
			}
			quoteDataVar[0]['personTempAddr'][key] = value;
		}

		setJsonData(quoteDataVar);
	}

	// get zip details from address
	const getDataFromZip = (zipCodeVal) => {
		if (streetName === '') {
			toast.error('Please Enter Street Name!');
			setZipCode('')
			return false;
		}
		if (zipCodeVal !== '') {
			handlePropertyAddressChange('s_StreetName', streetName);
			dispatch(getZipDetails('10', zipCodeVal, 'MAILING'))
				.then((res) => {
					if (res.success === true) {
						var data = res.data;
						if (data.status === 'Y') {
							if (data.count === 1) {
								setZipDetailsData(data.zipData[0]);
							} else {
								showMultipleCityModal(data.zipData);
							}
						} else {
							toast.error(res.errorMsg);
						}
					}
				});
		} else {
			toast.error('Zip Code should not be empty!');
		}
	}
	const backTopPolicy = () => {
		navigate("/core/policy/" + policyNo);
	}

	return (
		<>
			<LoadingOverlay>
				<Loader loading={loading} />

				<div className="dashboard-page-container">

					<Row>
						<Col xs="12" className="text-left">
							<Button id="common-btn-sm" variant="primary" className="btn btn-sm" onClick={backTopPolicy} style={{display: "flex"}}>
								<FaArrowLeft />
								&nbsp;&nbsp;{t("Back to Policy")}
							</Button>{" "}
							{/*//<Button variant="primary" style={{ backgroundColor : '#64d6cd'}} onClick={backTopPolicy}>Back to Policy</Button>*/}
						</Col>
					</Row>
					{reasonExist &&
						<Row>
							<Col>
								<div className="dashboard-table-div text-center " id="dashboard-table-section " style={{ paddingTop: '5px', paddingBottom: '5px', margin: '0px' }}>
									<div className="text-center" style={{ backgroundColor: '#66e3d8', align: 'center' }}>
										<span className="table-main-title-name text-danger" style={{ fontWeight: '500', fontSize: '22px', align: 'center' }}><b>{t("Reason")}</b> </span>
									</div>
									<Card>
										<Row>
											<Col xs="12">
												<div>
													{jsonData && jsonData[0].reason && jsonData[0].reason}
												</div>
											</Col>
										</Row>
									</Card>
								</div>
							</Col>
						</Row>
					}

					<Row >
						<Col xs="12">
							<div className="dashboard-table-div" id="dashboard-table-section">
								<div className="table-main-title-div">
									<span className="table-main-title-name" style={{display: "flex"}}>
										<FaUserCheck size={30} />&nbsp;&nbsp;
										{t("Info")} &nbsp;&nbsp;&nbsp; {policyNo} &nbsp;&nbsp;&nbsp;
										{t("Policy Type")}:<span >  Flood Program </span>
									</span>
									{ReportedClaimDetails && ReportedClaimDetails.claim_no != null ? <span className="table-main-title-name">{t("Claim No")} : {ReportedClaimDetails && tenancyNaicPrefix+ReportedClaimDetails.claim_no}</span> : null}
								</div>

								<Card>
									<Row>
										<Col xs="12" sm="12" md="12" lg="12" xl="12">
											<Row style={{ paddingTop: "1%" }}>
												<Col>
													<label htmlFor=""><b>{t("Property Location")}: *{isDisabled}</b></label>
												</Col>
												<Col style={{ maxWidth: "25%" }}>
													<Select
														//   className="custom-select-autocomplete-width"
														getOptionValue={(option) => option.value}
														getOptionLabel={(option) => option.label}
														value={
															policyClaimData && personAdrArr && personAdrArr.filter(option =>
																option.value == policyClaimData.policy_risk_master_info.n_PORiskMaster_PK
															)
														}
														options={personAdrArr && personAdrArr}
														isDisabled={isDisabled}

													//  onChange={(valueDropDown) => handleDropDownChange(valueDropDown,'mainNode','claimType', 'ClaimTypeId_PK')}
													/>
												</Col>
												<Col>
													<label htmlFor=""><b>{t("Do you want to add Temporary Location")}?: </b></label>
												</Col>
												<Col>
													<label>
														<Switch
															checked={jsonData && jsonData[0].isTemporaryAddr == 'YES' ? true : false}
															onChange={(checkedValue, event, name) => {
																handleSwitchChange(checkedValue, event, name)
															}}
															onColor="#66E3D8"
															onHandleColor="#66E3D8"
															handleDiameter={30}
															uncheckedIcon={false}
															checkedIcon={false}
															boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
															activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
															height={20}
															width={48}
															className="react-switch"
															id="isTemporaryAddr"
															disabled={isDisabled}

														/>
													</label>
												</Col>
											</Row>
											<br />
											<Row>
												<Col>
													<label htmlFor=""><b>{t("Contact No")}. *</b></label>
												</Col>
												<Col>
													<TextMask
														mask={[
															"(",
															/[1-9]/,
															/\d/,
															/\d/,
															")",
															" ",
															/\d/,
															/\d/,
															/\d/,
															"-",
															/\d/,
															/\d/,
															/\d/,
															/\d/,
														]}
														Component={InputAdapter}
														className="form-control dashboard-group-input"
														style={{ flex: 1 }}
														type="text"
														id="contactNo"
														name="contactNo"
														placeholder="Phone Number"
														autoComplete="off"
														onChange={handleChange}
														parentobject="mainNode"
														value={jsonData && jsonData[0].contactNo}
														disabled={isDisabled}

													/>
												</Col>
												<Col>
													<label htmlFor=""><b>{t("Second Contact No")}.: </b></label>
												</Col>
												<Col>
													<TextMask
														mask={[
															"(",
															/[1-9]/,
															/\d/,
															/\d/,
															")",
															" ",
															/\d/,
															/\d/,
															/\d/,
															"-",
															/\d/,
															/\d/,
															/\d/,
															/\d/,
														]}
														Component={InputAdapter}
														className="form-control dashboard-group-input"
														style={{ flex: 1 }}
														type="text"
														id="contactNo2"
														name="contactNo2"
														placeholder="Secondary Phone Number"
														autoComplete="off"
														onChange={handleChange}
														parentobject="mainNode"
														value={jsonData && jsonData[0].contactNo2}
														disabled={isDisabled}
													/>
												</Col>
											</Row>
											<br />
											<Row>
												<Col>
													<label htmlFor=""><b>{t("Email")}: *</b></label>
												</Col>
												<Col>
													<input type="text" name="email" id="email" className="form-control dashboard-group-input" parentobject="mainNode" onChange={handleChange} value={jsonData && jsonData[0].email} disabled={isDisabled}></input>
												</Col>
												<Col>
													<label htmlFor=""><b>{t("Accept Text")}: </b></label>
												</Col>
												<Col>
													<label>
														<Switch
															checked={jsonData && jsonData[0].acceptSms == 'YES' ? true : false}
															onChange={(checkedValue, event, name) => {
																handleSwitchChange(checkedValue, event, name)
															}}
															onColor="#66E3D8"
															onHandleColor="#66E3D8"
															handleDiameter={30}
															uncheckedIcon={false}
															checkedIcon={false}
															boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
															activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
															height={20}
															width={48}
															className="react-switch"
															id="acceptSms"
															disabled={isDisabled}
														/>
													</label>
												</Col>
											</Row>
											<br />
											<Row>
												<Col>
													<label htmlFor=""><b>{t("Date of Loss")} *</b></label>
												</Col>
												<Col>
													<DatePicker
														className="form-control maxWidth300"
														id="dateofLoss"
														name="adjusterFeePaidDate"
														placeholderText="Please Select Date"
														parentobject="mainNode"
														selected={jsonData && jsonData[0].dateofLoss ? setDate(jsonData[0].dateofLoss) : ''}
														onChange={(date) => {
															setDate1(date)
															handleDateChange(date, 'mainNode', 'dateofLoss');
														}}
														disabled={isDisabled}
														showDisabledMonthNavigation
														//maxDate={new Date() < new Date(policyClaimData?.effectiveDate?.d_TransEffectiveTo)?new Date():new Date(policyClaimData?.effectiveDate?.d_TransEffectiveTo)}
													/>
												</Col>
												<Col></Col>
												<Col></Col>
											</Row>
											<br />
										</Col>
									</Row>
								</Card>
								{/* temporary Address */}
								{(jsonData && jsonData[0].isTemporaryAddr == 'YES') &&
									<Card style={{ marginTop: '5px' }}>
										<Row style={{ paddingTop: "1%" }}>
											<Col xs="12" sm="12" md="6" lg="3" xl="3">
												<Form.Group
													className="search-quote-form-group"
													controlId="formBasicEmail"
												>
													<Form.Label className="dashboard-group-label">
														<b>{t("Temporary Address")} *</b>
													</Form.Label>
													{
														isDisabled && jsonData[0].isTemporaryAddr == "YES" ? (
															<Form.Control
																className="dashboard-group-input-flood"
																type="text"
																placeholder={t("Property Address")}
																title={t('Property Address')}
																value={jsonData && jsonData[0].personTempAddr && jsonData[0].personTempAddr.s_StreetName}
																disabled={isDisabled}
															/>
														) : (
															<GooglePlacesAutocomplete
																apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
																selectProps={{
																	// styles: customStyles,
																	inputValue: streetName,
																	value: googlePlace,
																	onInputChange: handleGooglAutoComplete,
																	onChange: handleGooglePlace,
																	name: "googlePlacesStreetName",
																	id: "googlePlacesStreetName",
																	placeholder: "Enter a location",

																}}
															/>
														)
													}

												</Form.Group>
											</Col>
											<Col xs="12" sm="12" md="6" lg="3" xl="3">
												<Form.Group
													className="search-quote-form-group"
													controlId="formBasicEmail"
												>
													<Form.Label className="dashboard-group-label">
														{t('Zip')} *
													</Form.Label>
													<Form.Control
														type="text"
														placeholder={t("Zip")}
														name="s_PostalCode"
														title={t('Zip')}
														parentobject="personTempAddr"
														value={zipCode}
														onChange={(e) => {
															handleChange(e);
															setZipCode(e.currentTarget.value);
														}}
														onBlur={(e) => getDataFromZip(e.currentTarget.value)}
														required
														autoComplete="off"
														disabled={isDisabled}

													/>
												</Form.Group>
											</Col>
											<Col xs="12" sm="12" md="6" lg="1" xl="1">
												<Form.Group
													className="search-quote-form-group"
													controlId="formBasicEmail"
												>
													<Form.Label className="dashboard-group-label">
														{t('Zip Suffix')} *
													</Form.Label>
													<Form.Control
														type="text"
														placeholder={t("Zip Suffix")}
														name="s_PostalCodeSuffix"
														title={t('Zip Suffix')}
														parentobject="personTempAddr"
														value={zipCodeSuffix}
														disabled
														onChange={(e) => {
															handleChange(e);
															setZipCodeSuffix(e.currentTarget.value);
														}}
														required
														autoComplete="off"

													/>
												</Form.Group>
											</Col>
											<Col xs="12" sm="12" md="6" lg="2" xl="2">
												<Form.Group
													className="search-quote-form-group"
													controlId="formBasicEmail"
												>
													<Form.Label className="dashboard-group-label">
														{t("County")} *
													</Form.Label>
													<Form.Control
														className="dashboard-group-input-flood"
														disabled
														type="text"
														placeholder={t("County")}
														name="s_CountyName"
														title={t('County')}
														parentobject="personTempAddr"
														value={countryName}
														readOnly
													/>
												</Form.Group>
											</Col>

											<Col xs="12" sm="12" md="6" lg="1" xl="1">
												<Form.Group
													className="search-quote-form-group"
													controlId="formBasicEmail"
												>
													<Form.Label className="dashboard-group-label">
														{t('State')} *
													</Form.Label>
													<Form.Control
														disabled
														className="dashboard-group-input-flood"
														type="text"
														placeholder={t("State")}
														name="s_StateName"
														title={t('State')}
														parentobject="personTempAddr"
														value={stateName}
														readOnly
													/>
												</Form.Group>
											</Col>
											<Col xs="12" sm="12" md="6" lg="2" xl="2">
												<Form.Group
													className="search-quote-form-group"
													controlId="formBasicEmail"
												>
													<Form.Label className="dashboard-group-label">
														{t("City")} *
													</Form.Label>
													<Form.Control
														className="dashboard-group-input-flood"
														disabled
														type="text"
														placeholder={t("City")}
														name="s_CityName"
														title={t('City')}
														parentobject="personTempAddr"
														value={cityName}
														readOnly
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: '5px' }}><br /> &nbsp;
										</Row>
									</Card>
								}
							</div>
						</Col>
					</Row>
				</div>
				<Row>
					<Col xs="12">
						<Accordion className="custom-quote-accordion">
							<Accordion.Item eventKey="1" >
								<Accordion.Header className="search-quote-card-header">
									<span>{t('Additional Details (Optional)')}</span>
								</Accordion.Header>
								<Accordion.Body className="search-quote-card-body">
									<Card style={{ marginTop: '5px' }}>
										<Row>
											<Col xs="12" sm="12" md="12" lg="12" xl="12">
												<Row style={{ paddingTop: "1%" }}>
													<Col>
														<label htmlFor=""><b>{t("Claim Type")}</b></label>
													</Col>
													<Col style={{ maxWidth: "25%" }}>
														<Select
															//   className="custom-select-autocomplete"
															getOptionValue={(option) => option.ClaimTypeId_PK}
															getOptionLabel={(option) => option.Description}
															value={
																jsonData && allDropDown && allDropDown.claim_type_master.filter(option =>
																	option.ClaimTypeId_PK == jsonData[0].claimType
																)
															}
															options={allDropDown && allDropDown.claim_type_master}
															onChange={(valueDropDown) => handleDropDownChange(valueDropDown, 'mainNode', 'claimType', 'ClaimTypeId_PK')}
															isDisabled={isDisabled}
														/>
													</Col>
													<Col>
														<label htmlFor=""><b>{t("Type of Loss")}</b></label>
													</Col>
													<Col>
														<Select
															//   className="custom-select-autocomplete"
															getOptionValue={(option) => option.s_AppCodeName}
															getOptionLabel={(option) => option.s_AppCodeNameForDisplay}
															value={
																jsonData && allDropDown && allDropDown.type_of_loss_master.filter(option =>
																	option.s_AppCodeName == jsonData[0].typeOfLoss
																)
															}
															options={allDropDown && allDropDown.type_of_loss_master}
															onChange={(valueDropDown) => handleDropDownChange(valueDropDown, 'mainNode', 'typeOfLoss', 's_AppCodeName')}
															isDisabled={isDisabled}
														/>
													</Col>
												</Row>
												<br />
												<Row>
													<Col>
														<label htmlFor=""><b>{t("Service Rep")}</b></label>
													</Col>
													<Col>
														<Select
															//   className="custom-select-autocomplete"
															getOptionValue={(option) => option.Admin_ID}
															getOptionLabel={(option) => option.s_ScreenName}
															value={
																jsonData && allDropDown && allDropDown.service_rep_master.filter(option =>
																	option.Admin_ID == jsonData[0].serviceRep
																)
															}
															options={allDropDown && allDropDown.service_rep_master}
															onChange={(valueDropDown) => handleDropDownChange(valueDropDown, 'mainNode', 'serviceRep', 'Admin_ID')}
															isDisabled={isDisabled}
														/>
													</Col>
													<Col>
														<label htmlFor=""><b>{t("Catastrophe Loss")}</b></label>
													</Col>
													<Col>
														<label>
															<Switch
																checked={jsonData && jsonData[0].catastropheLoss == 'YES' ? true : false}
																onChange={(checkedValue, event, name) => {
																	handleSwitchChange(checkedValue, event, name)
																}}
																onColor="#66E3D8"
																onHandleColor="#66E3D8"
																handleDiameter={30}
																uncheckedIcon={false}
																checkedIcon={false}
																boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
																activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
																height={20}
																width={48}
																className="react-switch"
																id="catastropheLoss"
																disabled={isDisabled}
															/>
														</label>
													</Col>
												</Row>
												<br />
												<Row>
													<Col>
														<label htmlFor=""><b>{t("Event Name")}</b></label>
													</Col>
													<Col>
														<Select
															//   className="custom-select-autocomplete"
															getOptionValue={(option) => option.s_ClaimEventCode}
															getOptionLabel={(option) => option.s_ClaimEventName}
															value={
																jsonData && allDropDown && allDropDown.events_master.filter(option =>
																	option.s_ClaimEventCode == jsonData[0].eventName
																)
															}
															options={allDropDown && allDropDown.events_master}
															onChange={(valueDropDown) => handleDropDownChange(valueDropDown, 'mainNode', 'eventName', 's_ClaimEventCode')}
															isDisabled={isDisabled}
														/>
													</Col>
													<Col></Col>
													<Col></Col>
												</Row>
											</Col>
										</Row>
									</Card>
									<br></br>
									<Card>
										<Row style={{ paddingTop: '5px' }}>
											<Col xs={3} sm={3}>
												<b>{t("When Did the loss occur")}?</b>:
											</Col>
											<Col xs={3} sm={3}>
												<DatePicker
													className="form-control"
													id="lossDate"
													name="lossDate"
													placeholderText="Please Select Date"
													parentobject="firstNoticeOfLoss"
													selected={jsonData && jsonData[0].firstNoticeOfLoss.lossDate ? setDate(jsonData[0].firstNoticeOfLoss.lossDate) : ''}
													onChange={(date) => {
														setDate1(date)
														handleDateChange(date, 'firstNoticeOfLoss', 'lossDate');
													}}
													disabled

												/>
											</Col>
											<Col xs={3} sm={3}>
												<b>{t("How did the loss Occur")}?</b>
											</Col>
											<Col xs={3} sm={3}>
												<Select
													isDisabled
													className="custom-select-autocomplete"
													getOptionValue={(option) => option.ClaimTypeId_PK}
													getOptionLabel={(option) => option.Description}
													value={
														jsonData && allDropDown && allDropDown.claim_type_master.filter(option =>
															option.ClaimTypeId_PK == jsonData[0].firstNoticeOfLoss.lossReason
														)
													}
													options={allDropDown && allDropDown.claim_type_master}
													onChange={(valueDropDown) => handleDropDownChange(valueDropDown, 'firstNoticeOfLoss', 'lossReason', 'ClaimTypeId_PK')}

												/>
											</Col>
										</Row>
										<Row style={{ paddingTop: '5px' }}>

											{/*<Col xs={6} sm={6}></Col>*/}
										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={3} sm={3}>
												<b>{t("Which areas are damaged")}?</b>
											</Col>
											<Col xs={3} sm={3}>
												<Select
													isDisabled
													className="custom-select-autocomplete"
													getOptionValue={(option) => option.s_AppCodeName}
													getOptionLabel={(option) => option.s_AppCodeNameForDisplay}
													value={
														jsonData && allDropDown && allDropDown.type_of_loss_master.filter(option =>
															option.s_AppCodeName == jsonData[0].firstNoticeOfLoss.TypeofLoss
														)
													}
													options={allDropDown && allDropDown.type_of_loss_master}
													onChange={(valueDropDown) => handleDropDownChange(valueDropDown, 'mainNode', 'TypeofLoss', 's_AppCodeName')}
												/>
											</Col>
											<Col xs={3} sm={3}>
												<b>{t("Does the applicant or applicant's spouse live in this building more than 80% of the year")}?</b> *
											</Col>
											<Col xs={3} sm={3}>
												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												>
													<Form.Check
														type="radio"
														label={t("Yes")}
														className="check-question-radio"
														name="insuredResides"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.insuredResides === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}

													/>
													<Form.Check
														type="radio"
														label={t("No")}
														name="insuredResides"
														value={'NO'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.insuredResides === "NO" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
												</div>
											</Col>

										</Row>

										<Row style={{ paddingTop: '5px' }}>

											<Col xs={3} sm={3}>
												<b>{t("How much water is in the building")}?</b> 
											</Col>
											<Col xs={2} sm={2}>
												<input type="text" name="waterLevelFeet" id="waterLevelFeet" className="form-control" style={{ textAlign: "left", width: '150px' }} parentobject="firstNoticeOfLoss" onChange={handleChange} value={jsonData && jsonData[0].firstNoticeOfLoss.waterLevelFeet} disabled={isDisabled}></input>{t("ft")}
											</Col>
											<Col xs={2} sm={2}>
												<input type="text" name="waterLevelInch" id="waterLevelInch" className="form-control" style={{ textAlign: "left", width: '150px' }} parentobject="firstNoticeOfLoss" onChange={handleChange} value={jsonData && jsonData[0].firstNoticeOfLoss.waterLevelInch} disabled={isDisabled}></input>{t("in")}
											</Col>
											<Col xs={2} sm={2}>
												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												>
													<Form.Check
														type="radio"
														label={t("I do not know")}
														name="waterLevelNoIdea"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.waterLevelNoIdea === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
												</div>
											</Col>
										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={3} sm={3}>
												<b>{t("How long has the water remained inside the building")}?</b> 
											</Col>
											<Col xs={2} sm={2}>
												<input type="text" name="waterRemainedDays" id="waterRemainedDays" className="form-control" style={{ textAlign: "left", width: '150px' }} parentobject="firstNoticeOfLoss" onChange={handleChange} value={jsonData && jsonData[0].firstNoticeOfLoss.waterRemainedDays} disabled={isDisabled}></input>{t("Days")}
											</Col>
											<Col xs={2} sm={2}>
												<input type="text" name="waterRemainedHours" id="waterRemainedHours" className="form-control" style={{ textAlign: "left", width: '150px' }} parentobject="firstNoticeOfLoss" onChange={handleChange} value={jsonData && jsonData[0].firstNoticeOfLoss.waterRemainedHours} disabled={isDisabled}></input>{t("Hours")}
											</Col>
											<Col xs={2} sm={2}>

												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												><Form.Check
														type="radio"
														label={t("I do not know")}
														name="waterRemainedNotIdea"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.waterRemainedNotIdea === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
												</div>
											</Col>
										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={3} sm={3}>
												<b>{t("Have you been allowed back into the property")}?</b> 
											</Col>

											<Col xs={5} sm={5}>
												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												>
													<Form.Check
														type="radio"
														label={t("Yes")}
														className="check-question-radio"
														name="allowedBack"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.allowedBack === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}

													/>
													<Form.Check
														type="radio"
														label={t("No")}
														name="allowedBack"
														value={'NO'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.allowedBack === "NO" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>

												</div>
											</Col>
											<Col xs={4} sm={4}>
											</Col>
										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={1} sm={1}>

											</Col>
											<Col xs={10} sm={10}>
												*<b>{t("Please keep samples of damaged items that are being removed (i.e. Flooring, Carpeting, Base boards, etc.). Take photos before throwing anything away and take photos of appliance serial number before removing. Start clean up as soon as possible")}</b> .
											</Col>
											<Col xs={1} sm={1}>

											</Col>
										</Row>
										{jsonData && jsonData[0].firstNoticeOfLoss.allowedBack == 'YES' &&
											<Row style={{ paddingTop: '5px' }}>
												<Col xs={3} sm={3}>
													<b>{t("Has clean up begun")}?</b>
												</Col>

												<Col xs={5} sm={5}>
													<div
														style={{ display: "flex", justifyContent: "flex-start" }}
														className="custom-radio-check0"
													>
														<Form.Check
															type="radio"
															label={t("Yes")}
															className="check-question-radio"
															name="isCleanUpStart"
															value={'YES'}
															checked={jsonData && jsonData[0].firstNoticeOfLoss.isCleanUpStart === "YES" ? true : false}
															parentobject="firstNoticeOfLoss"
															onChange={handleQRadioChange}
															style={{ marginRight: "10%" }}
															disabled={isDisabled}

														/>
														<Form.Check
															type="radio"
															label={t("No")}
															name="isCleanUpStart"
															value={'NO'}
															checked={jsonData && jsonData[0].firstNoticeOfLoss.isCleanUpStart === "NO" ? true : false}
															parentobject="firstNoticeOfLoss"
															onChange={handleQRadioChange}
															style={{ marginRight: "10%" }}
															disabled={isDisabled}
														/>

													</div>
												</Col>
												<Col xs={4} sm={4}>
												</Col>
											</Row>
										}
										{jsonData && jsonData[0].firstNoticeOfLoss.allowedBack == 'YES' &&

											<Row style={{ paddingTop: '5px' }}>
												<Col xs={3} sm={3}>
													<b>{t("Have repairs begun")}?</b>
												</Col>

												<Col xs={5} sm={5}>
													<div
														style={{ display: "flex", justifyContent: "flex-start" }}
														className="custom-radio-check0"
													>
														<Form.Check
															type="radio"
															label={t("Yes")}
															className="check-question-radio"
															name="repairStart"
															value={'YES'}
															checked={jsonData && jsonData[0].firstNoticeOfLoss.repairStart === "YES" ? true : false}
															parentobject="firstNoticeOfLoss"
															onChange={handleQRadioChange}
															style={{ marginRight: "10%" }}
															disabled={isDisabled}

														/>
														<Form.Check
															type="radio"
															label={t("No")}
															name="repairStart"
															value={'NO'}
															checked={jsonData && jsonData[0].firstNoticeOfLoss.repairStart === "NO" ? true : false}
															parentobject="firstNoticeOfLoss"
															onChange={handleQRadioChange}
															style={{ marginRight: "10%" }}
															disabled={isDisabled}
														/>

													</div>
												</Col>
												<Col xs={4} sm={4}>
												</Col>
											</Row>
										}
										{jsonData && jsonData[0].firstNoticeOfLoss.repairStart == 'YES' &&
											<Row style={{ paddingTop: '5px' }}>
												<Col xs={3} sm={3}>
													<b>{t("What repairs are being made")}?</b>
												</Col>

												<Col xs={9} sm={9}>
													<input type="text" name="repairs" id="repairs" className="form-control" style={{ textAlign: "left" }} parentobject="firstNoticeOfLoss" onChange={handleChange} value={jsonData && jsonData[0].firstNoticeOfLoss.repairs} disabled={isDisabled}></input>
												</Col>

											</Row>
										}
										{jsonData && jsonData[0].firstNoticeOfLoss.repairStart == 'YES' &&
											<Row style={{ paddingTop: '5px' }}>
												<Col xs={3} sm={3}>
													<b>{t("What is the name of the contractor doing the repairs")}?</b>
												</Col>

												<Col xs={9} sm={9}>
													<input type="text" name="contractorName" id="contractorName" className="form-control" style={{ textAlign: "left" }} parentobject="firstNoticeOfLoss" onChange={handleChange} value={jsonData && jsonData[0].firstNoticeOfLoss.contractorName} disabled={isDisabled}></input>
												</Col>

											</Row>
										}

										<Row style={{ paddingTop: '10px' }}>
											<Col xs={6} sm={6}>
												<b>{t("Is the Civil Authority allowing anyone other than property owners into the property")}?</b> 
											</Col>

											<Col xs={6} sm={6}>
												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												>
													<Form.Check
														type="radio"
														label={t("Yes")}
														className="check-question-radio"
														name="allowedOther"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.allowedOther === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}

													/>
													<Form.Check
														type="radio"
														label={t("No")}
														name="allowedOther"
														value={'NO'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.allowedOther === "NO" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
													<Form.Check
														type="radio"
														label={t("I do not know")}
														name="allowedOther"
														value={'NOIDEA'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.allowedOther === "NOIDEA" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
													{/*<Form.Check
		                        type="radio"
		                        label="Yes"
		                        style={{ marginRight: "10%" }}
		                      />
		                      <Form.Check type="radio" label="No" style={{ marginRight: "10%" }}/>
							  <Form.Check type="radio" label="I do not know"  style={{ marginRight: "10%" }}/>*/}
												</div>
											</Col>

										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={6} sm={6}>
												<b>{t("Is this a residence")}?</b> 
											</Col>

											<Col xs={6} sm={6}>
												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												>
													<Form.Check
														type="radio"
														label={t("Yes")}
														className="check-question-radio"
														name="isResidence"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.isResidence === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}

													/>
													<Form.Check
														type="radio"
														label={t("No")}
														name="isResidence"
														value={'NO'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.isResidence === "NO" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
												</div>
											</Col>

										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={6} sm={6}>
												<b>{t("Is there a public adjuster involved")}?</b> 	
											</Col>

											<Col xs={6} sm={6}>
												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												>
													<Form.Check
														type="radio"
														label={t("Yes")}
														className="check-question-radio"
														name="isAdjuster"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.isAdjuster === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}

													/>
													<Form.Check
														type="radio"
														label={t("No")}
														name="isAdjuster"
														value={'NO'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.isAdjuster === "NO" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
												</div>
											</Col>

										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={6} sm={6}>
												<b>{t("Is the following Mortgagee information correct and up-to-date")}?</b> 
											</Col>

											<Col xs={6} sm={6}>
												<div
													style={{ display: "flex", justifyContent: "flex-start" }}
													className="custom-radio-check0"
												>
													<Form.Check
														type="radio"
														label={t("Yes")}
														className="check-question-radio"
														name="isMortgagee"
														value={'YES'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.isMortgagee === "YES" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}

													/>
													<Form.Check
														type="radio"
														label={t("No")}
														name="isMortgagee"
														value={'NO'}
														checked={jsonData && jsonData[0].firstNoticeOfLoss.isMortgagee === "NO" ? true : false}
														parentobject="firstNoticeOfLoss"
														onChange={handleQRadioChange}
														style={{ marginRight: "10%" }}
														disabled={isDisabled}
													/>
												</div>
											</Col>

										</Row>

										<Row style={{ paddingTop: '5px' }}>
											<Col xs={12} sm={12}>
												<div className="dashboard-table-div" id="dashboard-table-section" >
													<div className="table-main-title-div" style={{ backgroundColor: '#66e3d8', height: '35px', padding: '5px' }}>
														<span className="table-main-title-name">{t("Mortgagees Information")}</span>
													</div>
													<Row style={{ padding: '1px' }}>
														<Col xs="12">
															<BootstrapTable
																wrapperClasses="dashboard-table-main"
																bootstrap4
																keyField="s_PoMortgageeTypeCode"
																data={claims}
																columns={columns}
																hover
																noDataIndication={'There is no data to display'}
															/>
														</Col>
													</Row>

												</div>
											</Col>
										</Row>

									</Card>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
				<br />
				<br />
				<br />
				<div className="text-center">
					{/*<Button variant="danger" style={{marginLeft : "39%"}}> Cancel</Button>*/}
					{!isDisabled ? <Button variant="primary" style={{ backgroundColor: '#64d6cd' }} onClick={handleSubmit}> {t('Report A Claim')}</Button> : <p>{t("Status")} : <b>{ReportedClaimDetails && ReportedClaimDetails.status}</b></p>}
				</div>
			</LoadingOverlay>
		</>

	);
};

function mapStateToProps(state) {
	return {
		getUserPolicyList: state.common.getUserPolicyList ? state.common.getUserPolicyList : {},
	};
}
export default connect(mapStateToProps)(ReportACLaim);