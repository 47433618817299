import React, { useState, useEffect, useCallback } from "react";
import "./license.scss";
import { FaInbox, FaEye, FaPlus, FaFileUpload, FaTimes } from "react-icons/fa";
import { Row, Col, Button, Form, Modal, Table, Card, CardBody, CardHeader, CardText, ModalBody, ModalHeader,Spinner} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { loadCeLicenseDocumentDataTableServices, updateStatusDocument, getViewDocument,deleteCeLicenseData } from "../../services/ceDocumentService";
import Moment from 'moment';
import {EncryptOrDecrypt } from "../../services/commonService";
import { toast } from 'react-toastify';
import { stateList } from "../../services/enrollService";
import { useDispatch } from "react-redux";
import  EditCELicense  from "./EditCELicense";
import  ViewCELicense  from "./ViewCELicense";
import Swal from 'sweetalert2';
import  CELicenseList  from "./CELicenseList";
import { useTranslation } from "react-i18next";

const CELincense = () => {
	const dispatch = useDispatch();
    const {t} = useTranslation();
    const [issueDate, setIssueDate] = React.useState(new Date());
    const [expireDate, setExpireDate] = React.useState('9999-01-01');
    const [courseCompletionDate, setCourseCompletionDate] = React.useState();
    const [courseExpirationDate, setCourseExpirationDate] = React.useState('');
    const [fileSelected, setFiles] = React.useState([]);
    const [tableData, setTableData] = useState([]);
    const [listData, setListData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [courseHours, setCourseHours] = useState('');
    const [courseNumber, setCourseNumber] = useState('');
    const [courseName, setCourseName] = useState('');
    const [trainingProviderNumber, setTrainingProviderNumber] = useState('');
    const [trainingProviderName, setTrainingProviderName] = useState('');
    const [individualLicenseNumber, setIndividualLicenseNumber] = useState('');
    const [residentialState, setResidentialState] = useState(null);
    const [modalType, setModalType] = useState();
    const [rowId, setRowId] = useState(0);
    const [rowStatus, setRowStatus] = useState('');
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [reason, setReason] = useState('');
    const [reasonModal, setReasonModal] = useState(false);
    const [viewReason, setViewReason] = useState('');
    const [modalMsg, setModalMsg] = useState();

    const [moduleName, setModuleName] = useState('Producer');
    const [subModule, setSubModule] = useState('CE_LICENSE');
    const [subModulePk, setSubModulePk] = useState(170);
    const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    const [buttonLoading, setButtonLoading] = useState(false);
	const [states,setStates] = useState([])
	const [attest,setAttest] = useState(false);
	const [isEditModal,setIsEditModal] = useState(null);
	const [isEditRowData,setIsEditRowData] = useState([]);
    useEffect(() => {
        loadTableData();
    }, []);
	
	useEffect(() => {
        (async () => {
            const stateData = await dispatch(stateList())
            setStates(stateData.data)
           
        })();
    }, [])
	
	useEffect(() => {
        setResidentialState(userDatas?.residentState || null);
    }, []);
	

    const handleSave = async (e) => {
        e.preventDefault();
        const validateStatus = await validate();
            if(!validateStatus){
                return false;
            }
            setButtonLoading(true);
            const formData = new FormData();
            let uploadDate = Moment(issueDate).format("YYYY-MM-DD");
            var receivedDate = Moment(new Date()).format("YYYY-MM-DD");
			
			fileSelected.map((file) => {
				formData.append('file[]', file, file.name);
			});

            formData.append("moduleSelected", 4);
            
			formData.append("Username", userDatas.Username);
			formData.append("ProducerName", userDatas.s_ScreenName);
			formData.append("LicenseNo", individualLicenseNumber);
			formData.append("TrainingProvider", trainingProviderName);
			formData.append("TrainingProviderNo", trainingProviderNumber);
			formData.append("CourseName", courseName);
			formData.append("CourseNumber", courseNumber);
			formData.append("CourseHours", courseHours);
			formData.append("CourseCompletionDate",  Moment(courseCompletionDate).format("YYYY-MM-DD"));
			formData.append("CourseExpirationDate", (courseExpirationDate != '') ? Moment(courseExpirationDate).format("YYYY-MM-DD") : '9999-01-01');
			formData.append("ResidencyState", userDatas.residentState);
			
            formData.append("doctypeSelected", '');
            formData.append("description", courseName);
            formData.append("uploadDate", uploadDate);
            formData.append("sourceId", '');
            formData.append("moduleName", moduleName);
            formData.append("fileName", courseName);
            formData.append("authorization", 'Internal');
            formData.append("caseType", '');
            formData.append("sourcePk", userDatas.persInfoAgencyFK);
            formData.append("docType", '');
            formData.append("status", '');
            formData.append("policyId", '');
            formData.append("subModule", subModule);
            formData.append("subModulePk", subModulePk);
            formData.append("issue_date", Moment(courseCompletionDate).format("YYYY-MM-DD"));
            formData.append("expiry_date", (expireDate != '9999-01-01') ? Moment(expireDate).format("YYYY-MM-DD") : '9999-01-01');
			
			let SaveFileData =  await updateStatusDocument(formData);
            if(SaveFileData?.status == 200){
                toast.success(SaveFileData.data.data.message);
				setTimeout(() => {
					setButtonLoading(false);
					window.location.reload();
				}, 1500);
				
            }else{
				toast.error('This Details Cannot be submitted please Check again');
				setButtonLoading(false);
			}
    }

    const validate = async () => {
        var status = 'N';
        var msg = ''

        if (fileSelected.length == 0) {
			status = 'Y';
            msg = 'Please Add File'
        }
		if (attest == false) {
			status = 'Y';
			msg = 'Please attest the CE License'
		}
		if (typeof courseCompletionDate == 'undefined' || courseCompletionDate == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Completion Date'
        }
		if (courseHours == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Hours'
        }
		if (courseNumber == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Number'
        }
		if (courseName == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Name'
        }
		if (trainingProviderNumber == '') {
            status = 'Y';
            msg = 'Please Enter Training Provider Number'
        }
		if (trainingProviderName == '') {
            status = 'Y';
            msg = 'Please Enter Training Provider Name'
        }
		if (individualLicenseNumber == '') {
            status = 'Y';
            msg = 'Please Enter Individual License Number'
        }
		if (residentialState == '' || residentialState == null) {
			status = 'Y';
			msg = 'Please Enter Residential State'
		}
        if(status == 'Y'){
            toast.error(msg);
            return false;
        } else{
            return true;
        }
    }
	
	const calculateExpireDate = (date) => {
		
		{states.map((state) => {
			if(state.s_StateCode == 'DE'){
				if(residentialState == state.n_StateId_PK){
					setCourseExpirationDate(new Date(Moment(date).add(2, 'years')));
					setExpireDate(new Date(Moment(date).add(2, 'years')));
				}	
			}else if(state.s_StateCode == 'NC'){
				if(residentialState == state.n_StateId_PK){
					setCourseExpirationDate(new Date(Moment(date).add(4, 'years')));
					setExpireDate(new Date(Moment(date).add(2, 'years')));
				}
			}
		})}
		
		setCourseCompletionDate(date);
		
		
	}


    const loadTableData = async () => {
        const datas = await loadCeLicenseDocumentDataTableServices({ pageTemp: 1, sizePerPageTemp: 50 });
        setListData(datas.returnObject);
    }

    const handleClose = () => setReasonModal(false);

    const handleShow = (row) => {
    setReasonModal(true);
    setViewReason(row.reason);
  }

    const onDrop = useCallback(event => {
        setFiles(event)
    }, [])

    const { acceptedFiles, isDragActive, getRootProps, getInputProps } = useDropzone({ onDrop,multiple: true,maxFiles:2});
    var files = acceptedFiles.map((file, i) => (
        <li key={file.path}>
            {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => remove(i)} />
        </li>
    ));

    const remove = file => {
        const newFiles = [...files];
        newFiles.splice(file, 1);
        acceptedFiles.splice(file, 1);
        setFiles(newFiles);
    };

    

    const statusField = (cell, row) => {
        if (row.docStatus == "APPROVED") {
            return (
                <div>
                    <h1 style={{ fontSize: "1.2rem", color: "green" }} >Completed</h1>
                </div>
            )
        }
        if (row.docStatus == "REJECTED") {
            return (
                <div>
                    <h1 style={{ fontSize: "1.2rem", color: "red", cursor: "pointer" }} onClick={() => handleShow(row)}>
                        <div>
                            <a href='#' style={{ color: "red" }}>Rejected</a>
                        </div>
                    </h1>
                </div>
            )
        }
        if (row.docStatus == "PENDING") {
            return (
                <div>
                    <h1 style={{ fontSize: "1.2rem", color: "orange" }}>Approval Pending</h1>
                </div>
            )
        }
    }

    const viewDocument = async (e,row) => {
		e.preventDefault();
        let data = await getViewDocument(row.DocInfo_PK, 'NEW_SYSTEM', 'Producer');
        let docUrl = data.url;
        if (docUrl != '') {
            window.open(docUrl);
        }
    }

    const actionButton = (cell, row, enumObject, rowIndex) => {
		return (
			<div>
				<Button id="common-btn" name="actionNameData"  style={{ padding: "6px 10px" }} onClick={(e) => editModal(e,row,'VIEW')} ><a href='#' target="_blank" style={{ color: "white" }}><i className='fa fa-eye' /></a></Button>
				&nbsp;
				{/*<Button id="common-btn" name="actionNameData"  style={{ padding: "6px 10px" }} onClick={(e) => editModal(e,row)} ><a href='#' target="_blank" style={{ color: "white" }}><i className='fa fa-edit' /></a></Button>
				&nbsp;
				<Button id="common-btn" name="actionNameData"  style={{ padding: "6px 10px" }} onClick={(e) => deleteModal(e,row)} ><a href='#' target="_blank" style={{ color: "white" }}><i className='fa fa-trash' /></a></Button>*/}
			</div>
		)
		
        
    }
	
	const toggle = () => {
        setIsEditModal(null);
    }

    const editModal = (e,row,type) => {
		e.preventDefault();
		
		if(type == 'EDIT'){
			setIsEditModal(<EditCELicense toggle={toggle} rowData = {row}/>);
		}
		if(type == 'VIEW'){
			setIsEditModal(<ViewCELicense toggle={toggle} rowData = {row}/>);
		}
    }
    
	const deleteModal = (e,row) => {
		e.preventDefault();
        Swal.fire({
            title: 'Confirmation',
            text: "Are sure you want to delete Ce License!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
          }).then(async(result) => {
            if (result.isConfirmed) {
				const params ={
					rowId:row.id
				}
				let deleteFileData =  await deleteCeLicenseData(params);
            	if(deleteFileData?.data?.success == true){
					toast.success(deleteFileData?.data?.data?.message);
				}else{
					toast.error('Error deleting Celicense');
				}
				loadTableData();
            }
        })
    }

	
	const handleCloseEdit = () => setIsEditModal(false);

    const columns = [
        {
            dataField: "courseName",
            text: "Course Name",
        },
        {
            dataField: "uploadedDate",
            text: "Upload Date",
        },
        {
            dataField: "expiryDate",
            text: "Expiry Date",
        },
        {
            dataField: "s_ScreenName",
            text: "User",
        },
        {
            dataField: "docStatus",
            text: "Status",
            formatter: (value, row) => statusField(value, row),
        },

        {
            text: "Actions",
            formatter: (value, row) => actionButton(value, row),
        },
    ];

    return(
        <div className="inbox-page-section">
            <h4 className="activity-log-title">{t('CE-License')}</h4>
            <Row>
                <Col xs="12">
                    <div className="card-header-main">
                        <div className="card-header-main-title">
                            {t('Continuing Education Details')}
                        </div>
                    </div>
                    <div className="card-body-main">
                        <Row className="align-items-baseline">
                            <Row>
                                <Col xs='6'>
                                   <div style={{color:'red'}} >
                                        {t('Please verify the name shown in the Individual Producer Name field below is your name. If it is not your name please logout and login with your individual username/credentials.')}
                                   </div>
                                </Col>
                            </Row>
                            <Col xs="6" style={{ marginTop: "0px" }}>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Company')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input"
                                            type="text"
                                            value={userDatas?.companyDetails?.company_name || ''}
											disabled
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Individual Producer Name')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input-small"
                                            type="text"
											value={userDatas?.s_ScreenName || ''}
											disabled
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Residential State')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
									  	<Form.Select
								        	className="table-show-entries-select"
								        	onChange={(e) => setResidentialState(e.target.value)}
											value={residentialState}
											disabled
								      	>
											<option >{t('Please Select State')}</option>
									        {states.map((state) => {
												
									          return  <option value={state.n_StateId_PK}>
											   {state.s_StateDisplayName} ({state.s_StateName}) 
											  </option>
									        })}
								      	</Form.Select>
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Individual License Number')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input"
                                            type="text"
                                            onChange={(e) => setIndividualLicenseNumber(e.target.value)}
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Training Provider Name')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input"
                                            type="text"
                                            onChange={(e) => setTrainingProviderName(e.target.value)}
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Training Provider Number')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input"
                                            type="text"
                                            onChange={(e) => setTrainingProviderNumber(e.target.value)}
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Flood Course Name')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input"
                                            type="text"
                                            onChange={(e) => setCourseName(e.target.value)}
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Flood Course Number')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input"
                                            type="text"
											required
                                            onChange={(e) => setCourseNumber(e.target.value)}
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Flood Course Hours')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<Form.Control
                                            className="dashboard-group-input"
                                            type="number"
                                            onChange={(e) => setCourseHours(e.target.value)}
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('Flood Course Completion Date')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<DatePicker
                                            id="dashboard-datepicker"
                                            className="datepicker-full-width"
                                            name="issue date"
                                            onChange={(date) => calculateExpireDate(date)}
                                            selected={courseCompletionDate}
											onKeyDown={(e) => {
												e.preventDefault();
											 }}
											 
                                        />
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
										<Form.Label className="small">
										{t('State Flood CE Expiration Date')}:
                                        </Form.Label>
									</Col>
									<Col xs="7">
										<DatePicker
                                            id="dashboard-datepicker"
                                            className="datepicker-full-width dashboard-datepicker-main-bg"
                                            name="issue date"
                                            //onChange={(date) => setCourseExpirationDate(date)}
                                            selected={courseExpirationDate}
											disabled={true}
											readOnly={true}
                                        />
									</Col>
								</Row>
								<Row>
									<Col xs="12">
											&nbsp;
									</Col>
								</Row>
								<Row style={{'padding':'1px'}}>
									
									<Col xs="3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >                                        
                                            <Form.Check
                                                type="checkbox"
                                                label=""
                                                className="table-checkbox"
                                                id="custom-checker"
                                                onChange={(e) => setAttest(e.currentTarget.checked)}
                                            />
                                            <p style={{ fontWeight: 'bold', fontSize: 12,marginTop:"5px"}}>
                                                {t('Check here')} <br/>
                                                {t('to attest')}
                                            </p>                                    
									</Col>
									<Col xs="9">
										<Form.Label className="small">
											<small>{t('I attest that I have complied with the minimum flood training requirements set by FEMA and/or the states in which I’m licensed to write flood, through an education provider approved by the state(s) in which I’m licensed to write this insurance.  I also attest that I can provide the required information and that I am the individual producer writing this flood policy.')}</small>
                                        </Form.Label>
									</Col>
								</Row>
                                {/*<Table borderless className="columns-table-main">
                                    <tr>
                                        <th>&nbsp;&nbsp;&nbsp;&nbsp;Document Name</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Control
                                                className="dashboard-group-input"
                                                type="text"
                                                placeholder="Document Name"
                                                onChange={(e) => setDocName(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>&nbsp;&nbsp;&nbsp;&nbsp;Issue Date</th>
                                    </tr>
                                    <tr>
                                        <td className="schedule-date-row">
                                            <DatePicker
                                                id="dashboard-datepicker"
                                                className="datepicker-full-width"
                                                name="issue date"
                                                onChange={(date) => setIssueDate(date)}
                                                selected={issueDate}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>&nbsp;&nbsp;&nbsp;&nbsp;Expire Date</th>
                                    </tr>
                                    <tr>
                                        <td className="schedule-date-row">
                                            <DatePicker
                                                id="dashboard-datepicker"
                                                className="datepicker-full-width"
                                                name="issue date"
                                                onChange={(date) => setExpireDate(date)}
                                                selected={expireDate}
                                            />
                                        </td>
                                    </tr>
                                </Table>*/}
                            </Col>
                            <Col xs="6" style={{ marginTop: "25px" }}>
								<Row>
									<Col xs="12" >
										<span><b>{t('Upload Flood Training Certificate(s)')}</b></span>
		                                <div id="drop-document-type-div"
		                                    {...getRootProps({ className: "dropzone" })}
		                                >
		                                    <input type="file"
		                                        autoComplete="off"
		                                        className="inp-file"
		                                        // onChange={uploadFile}
		                                        {...getInputProps()}
		                                    />
		                                    <FaFileUpload size={34} color={"#64D6CD"} />
		                                    <p>{t('Drag and Drop File Here')}</p>
		                                    <Button id="common-btn" variant="primary">
		                                        <FaPlus size={15} />
		                                        {t('Add File')}
		                                    </Button>{" "}
                                            <br/>
                                            <small>{t('If uploading multiple documents, please select all of the documents needed in a single drag and drop or a single upload.')}</small>
		                                </div>
		                                <aside className="reply-document-name">
		                                    <ul>{files}</ul>
		                                </aside>
									</Col>
								</Row>
								<Row>
									<Col xs="12">
                                        <p><span style={{ fontWeight: "bold" }}>{t('PLEASE NOTE')}: </span>{t('Once you have submitted your CE information, you will be able to quote immediately. Your submitted CE information will be reviewed and if additional information is needed, one of our representatives will contact you.')}</p>
                                        <p ><span style={{ fontWeight: "bold" }}>{t('Flood CE Tips')}:</span><br />
                                            1.{t('Course must be a 3-hour Flood training course that meets FEMA/FIRA requirements')} ({t('Click')} <a href="https://nfipservices.floodsmart.gov/training/agent-requirements" style={{ color: 'blue' }} target="blank">{t('here')} </a>{t('for more information')}).<br /><br />
                                            2.{t('Name on document must match the producer profile name (do not submit someone else’s documentation).')}<br /><br />
                                            3.{t('Only an official certificate of completion or State Dept of Insurance CE transcript are acceptable (no licenses. course descriptions, test scores, etc)')}<br /><br />
                                            4.{t('CE Transcripts must show all pertinent information including producer name, license number, course details, including CE hours (screenshots generally do not work).')}<br /><br />
                                            5.{t('If your continuing education class/course had more than one certificate, please upload all certificates.')}
                                        </p>
									</Col>
								</Row>
                                
                            </Col>
                            <Col xs="12" style={{ marginTop: "25px" }}>
                                <Table>
                                    <tr>
                                        <th style={{ textAlign: "right" }}><Button id="common-btn" disabled={buttonLoading} variant="primary" onClick={(e) => handleSave(e)}>
                                        {buttonLoading ? (<Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border" />) : null}&nbsp;
                                    Submit
                                        </Button>{" "}</th>
                                    </tr>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                <div>
                    <div className="card-header-main">
                        <div className="card-header-main-title">
                            List License
                        </div>
                    </div>
                    <CELicenseList />
                </div>
            </Row>
            
			{isEditModal}
        </div>
    )
}

export default CELincense;