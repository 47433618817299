import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import arrowLeft from "../../../assets/arrow-left.svg";
import arrowSquareLeft from "../../../assets/arrow-square-left.svg";
import arrowRight from "../../../assets/arrow-right.svg";
import Discounts from "./components/discounts/Discounts";
import Coverage, {
  STAR_COSTTOREBUILD_MIN,
} from "./components/coverages/Coverage";
import Confirmation from "./components/confirmation/Confirmation";
import Agreements from "./components/agreements/Agreements";
import Payments from "./components/payments/Payments";
import Thanks from "./components/Thanks";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import Policy from "./components/policy/Policy";
import Underwriting from "./components/under-writing/UnderWriting";
import { useStarDataContext } from "./StarDataContext";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAutoLocationData } from "./apis/postGetAutoPropertyData";
import ChakraLoader from "./utils/ChakraLoader";
import useUpdateConstructionDetails from "./database/AutoGenMappings";
import { toast } from "react-toastify";
import { Currency } from "../../common/Currency";
import { AdditionalInfoToDisplay } from "./components/ViewAdditionalInfo";
import { useNavigate } from "react-router-dom";
import { getAutoZipData } from "./apis/getAutoZipData";
import { CreateStarQuotePool } from "./database/CreateStarQuotePool";
import { updateQuote } from "./apis/updateStarQuote";
import { HomeOwnersTerretory } from "./database/HomeOwnersTerretory";
import { CgSync } from "react-icons/cg";
import ErrorLogModal from "./utils/ErrorLog";
import ErrorModal from "../search-quote/components/utils/ErrorModal";

export const StepsArrangements = () => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [hasCompletedAllSteps, setCompletedAllSteps] = useState(false);
  const navigate = useNavigate();
  const store = useSelector((state) => state);
  const [callApi, setCallApi] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [errorData1, setErrorData1] = useState([]);
  const [isErrorModalOpen1, setIsErrorModalOpe1] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);

  const steps = [
    { label: t("Policy"), component: <Policy /> },
    { label: t("underwriting"), component: <Underwriting /> },
    { label: t("discounts"), component: <Discounts /> },
    { label: t("coverages"), component: <Coverage /> },
    { label: t("confirmation"), component: <Confirmation /> },
    { label: t("agreements"), component: <Agreements /> },
    { label: t("payment"), component: <Payments /> },
  ];

  useEffect(() => {
    if (activeStep === steps.length) {
      setCompletedAllSteps(true);
    } else {
      setCompletedAllSteps(false);
    }
  }, [activeStep, steps.length]);

  const {
    validationError,
    setValidationError,
    location,
    setLocation,
    setAutoGenData,
    underWriting,
    coverages,
    loading,
    starDataObject,
    confirmation,
    agreements,
    boundData,
    setStarDataObject,
    setBoundData,
    setAgreements,
    roofProperties,
    primaryMailingAddress,
    discounts,
    hasMortagee,
    saveMortgagee,
    setStateCodeVal,
    setCurrentZipData,
    lossHistoryData,
    setVendors,
    vendors,
    policy,
    selectedRisk,
    uniqueID,
    currentZipData,
    setEditQuoteRes,
    editQuoteRes,
    setDiscounts,
    setCoverages,
    feesData,
    errorMessages,
  } = useStarDataContext();

  const handleNextStep = () => {
    window.scrollTo({ top: 10, left: 0, behavior: "smooth" });
    setTimeout(() => window.scrollTo({ top: 10, left: 0 }), 100);
    switch (activeStep) {
      case 0:
        validateStep0();
        break;
      case 1:
        validateStep1();
        break;
      case 2:
        validateStep2();
        break;
      case 3:
        validateStep3();
        break;
      case 4:
        validateStep4();
        break;
      case 5:
        validateStep5();
        break;
      case 6:
        triggerUpdateAndCallAPI();
        break;
      default:
        break;
    }
  };

  function isValidNumericValue(value) {
    return value !== null && value !== "" && !isNaN(value) && value !== 0;
  }

  function isValidYear(value) {
    return (
      value !== null &&
      value !== "" &&
      !isNaN(value) &&
      value !== 0 &&
      value <= new Date().getFullYear() &&
      value.toString().length === 4
    );
  }

  function isValidPhoneNumber(phoneNumber) {
    return phoneNumber && phoneNumber !== "" && phoneNumber !== "+1 ";
  }

  function isValidEmail(email) {
    if (typeof email !== "string") {
      return "";
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  const validateStep0 = () => {
    if (!validationError?.propertyType) {
      toast.warn("Please Choose a Property Type", { toastId: "propertyErr1" });
    } else {
      nextStep();
    }
  };
  const hasUWErrors = () =>
    errorMessages && Object.keys(errorMessages).length > 0;
  const validateStep1 = () => {
    const isStep1Valid =
      checkConstructionDetails() &&
      isMailingAddressFilled() &&
      newPurchaseAddress() &&
      checkCustomerInfo() &&
      validateAdditionalInsured() &&
      !checkLossHistoryError() &&
      !checkSolarPanelError();
    if (Object.keys(errorMessages).length > 0) {
      toast.error("Please validate underwriting questions", {
        toastId: "underwritingErr1",
      });
      return;
    }
    if (!isStep1Valid) {
      setValidationError({ ...validationError, underWritingPage: true });
      if (checkLossHistoryError()) {
        setValidationError({ ...validationError, lossHistory: true });
      }
      toast.error("Please fill necessary details", {
        toastId: "underwritingErr1",
      });
    } else {
      clearErrors(["underWritingPage", "lossHistory"]);
      nextStep();
    }
  };

  const validateStep2 = () => {
    const isStep2Valid = checkDiscountsValidity();
    if (!isStep2Valid) {
      setValidationError({ ...validationError, discounts: true });
      toast.error("Please fill necessary details!", {
        toastId: "discountsErr1",
      });
    } else {
      clearErrors("discounts");
      nextStep();
    }
  };
  const isEmptyOrUndefinedOrNull = (value) =>
    value === null || value === undefined || value === "";

  const checkDiscountsValidity = () => {
    if (discounts?.hasHomeInsuranceInForce) {
      return (
        !isEmptyOrUndefinedOrNull(
          discounts?.priorPolicy?.propertyInfo?.s_PriorPolicyExpDt
        ) &&
        !isEmptyOrUndefinedOrNull(
          discounts?.priorPolicy?.propertyInfo?.s_PriorPolicyNo
        ) &&
        !isEmptyOrUndefinedOrNull(
          discounts?.priorPolicy?.propertyInfo?.s_PriorCompanyNAIC
        )
      );
    }
    return true;
  };

  const validateStep3 = () => {
    const isStep3Valid = checkCoveragesValidity();
    if (!isStep3Valid) {
      setValidationError({ ...validationError, coverages: true });
      toast.error("Please fill necessary details!", {
        toastId: "coveragesErr1",
      });
    } else {
      clearErrors("coverages");
      nextStep();
    }
  };

  const validateStep4 = () => {
    const isStep4Valid =
      checkConfirmationDetails() &&
      checkConstructionDetails() &&
      validateAdditionalInsured() &&
      validateMortageesAddition() &&
      additionalInterestsValidation() &&
      checkCustomerInfo();
    if (!isStep4Valid) {
      setValidationError({
        ...validationError,
        confirmation: true,
        fraudStatement: true,
        subscriberAgreement: true,
        termsAndConditions: true,
        additionalInterests: true,
        additionalInsured: true,
        underWritingPage: true,
      });
      toast.error("Please fill necessary details!", {
        toastId: "confirmationErr1",
      });
    } else {
      clearErrors([
        "confirmation",
        "additionalInterests",
        "additionalInsured",
        "underWritingPage",
      ]);
      nextStep();
    }
  };

  const validateStep5 = () => {
    const isStep5Valid = checkAgreementsAcknowledgement();
    if (!isStep5Valid) {
      setValidationError({ ...validationError, agreements: true });
      toast.info(
        "Please review and acknowledge the important disclosures before proceeding.",
        { toastId: "agreementsErr1", autoClose: 4500 }
      );
    } else {
      clearErrors("agreements");
      nextStep();
    }
  };

  const clearErrors = (fields) => {
    setValidationError((prev) => ({ ...prev, [fields]: false }));
  };

  const validateMortageesAddition = () => {
    if (hasMortagee) {
      return !isEmptyOrUndefinedOrNull(saveMortgagee[0]?.bankName);
    }
    return true;
  };

  const checkConstructionDetails = () => {
    return (
      isValidNumericValue(roofProperties?.roofAge) &&
      roofProperties?.roofAge <= 200 &&
      !isEmptyOrUndefinedOrNull(roofProperties?.roofMaterialUsed) &&
      !isEmptyOrUndefinedOrNull(roofProperties?.roofUpdated) &&
      (roofProperties?.roofUpdated === "true" ||
      roofProperties?.roofUpdated === true
        ? isValidNumericValue(Number(roofProperties?.roofUpdatedYear)) &&
          isValidYear(Number(roofProperties?.roofUpdatedYear))
        : true) &&
      isValidNumericValue(Number(underWriting?.propertyDetails?.yearBuilt)) &&
      isValidYear(Number(underWriting?.propertyDetails?.yearBuilt)) &&
      !isEmptyOrUndefinedOrNull(underWriting?.propertyDetails.usage) &&
      !isEmptyOrUndefinedOrNull(
        underWriting?.propertyDetails.constructionType
      ) &&
      !isEmptyOrUndefinedOrNull(underWriting?.propertyDetails.foundationType) &&
      !isEmptyOrUndefinedOrNull(underWriting?.propertyDetails.claddingType) &&
      isValidNumericValue(
        Number(underWriting?.propertyDetails.squareFootage)
      ) &&
      !isEmptyOrUndefinedOrNull(underWriting?.propertyDetails.PPC) &&
      !isEmptyOrUndefinedOrNull(
        underWriting?.propertyDetails.vacantIndicator
      ) &&
      !isEmptyOrUndefinedOrNull(
        underWriting?.propertyDetails.underConstruction
      ) &&
      isValidNumericValue(
        Number(underWriting?.propertyDetails.dwellingStories)
      ) &&
      !hasUWErrors()
    );
  };

  const additionalInterestsValidation = () => {
    if (confirmation.additionalInterestsFlag) {
      return (
        !isEmptyOrUndefinedOrNull(
          confirmation?.additionalInterests[0]?.firstName
        ) &&
        !isEmptyOrUndefinedOrNull(
          confirmation?.additionalInterests[0]?.lastName
        )
      );
    }
    return true;
  };

  function isMailingAddressFilled() {
    if (!primaryMailingAddress.sameAsPropertyAddress) {
      return (
        !isEmptyOrUndefinedOrNull(primaryMailingAddress.street_address) &&
        !isEmptyOrUndefinedOrNull(primaryMailingAddress.city) &&
        !isEmptyOrUndefinedOrNull(primaryMailingAddress.zipcode) &&
        !isEmptyOrUndefinedOrNull(primaryMailingAddress.state)
      );
    }
    return true;
  }

  function newPurchaseAddress() {
    if (underWriting?.propertyDetails?.isThisNewPurchase) {
      return (
        !isEmptyOrUndefinedOrNull(
          underWriting?.propertyDetails?.newPurchaseAddr.street_address
        ) &&
        !isEmptyOrUndefinedOrNull(
          underWriting?.propertyDetails?.newPurchaseAddr.city
        ) &&
        !isEmptyOrUndefinedOrNull(
          underWriting?.propertyDetails?.newPurchaseAddr.zipcode
        ) &&
        !isEmptyOrUndefinedOrNull(
          underWriting?.propertyDetails?.newPurchaseAddr.state
        )
      );
    }
    return true;
  }

  const isIndividual =
    underWriting?.propertyDetails?.customerType === "individual";
  const hasFirstAndLastName = !!(
    underWriting?.propertyDetails?.firstName &&
    underWriting?.propertyDetails?.lastName
  );
  const hasBuisnessName = !!underWriting?.propertyDetails?.buissnessName;
  const nameValidation = isIndividual ? hasFirstAndLastName : hasBuisnessName;

  const checkCustomerInfo = () => {
    return (
      nameValidation &&
      underWriting?.propertyDetails?.dateOfBirth &&
      underWriting?.propertyDetails?.customerType &&
      underWriting?.creditScore &&
      underWriting?.checkboxes?.readFcraStatement !== false &&
      isValidPhoneNumber(underWriting?.propertyDetails?.phoneNumber) &&
      isValidEmail(underWriting?.propertyDetails?.email)
    );
  };

  const checkLossHistoryError = () => {
    return underWriting?.lossHistoryQuestion && lossHistoryData?.length === 0;
  };

  const checkSolarPanelError = () => {
    return (
      underWriting?.underWritingQuestions?.hasSolarPanels &&
      (!underWriting?.underWritingQuestions?.solarPanelLocation ||
        underWriting?.underWritingQuestions?.solarPanelLocation === "")
    );
  };

  const checkCoveragesValidity = () => {
    return (
      isValidNumericValue(
        Number(coverages?.basicCoverages?.costToRebuildHome)
      ) &&
      coverages?.basicCoverages?.costToRebuildHome >= STAR_COSTTOREBUILD_MIN &&
      coverages?.basicCoverages?.personalLiability &&
      coverages?.basicCoverages?.medicalPaymentsToOthers &&
      coverages?.deductibles?.aop &&
      coverages?.deductibles?.scs &&
      coverages?.deductibles?.hurricaneDeductible
    );
  };

  const checkConfirmationDetails = () => {
    return (
      confirmation?.checkboxes?.readSubscriberAgreement &&
      confirmation?.checkboxes?.readTermsAndConditions &&
      confirmation?.checkboxes?.readFraudStatement
    );
  };

  const validateAdditionalInsured = () => {
    if (!confirmation.additionalInsuredFlag) {
      return true;
    }

    if (
      confirmation.additionalInsuredFlag &&
      confirmation.additionalInsured.length === 0
    ) {
      return false;
    }

    const isValid = confirmation.additionalInsured.every((item, index) => {
      if (index === 0 && item.sameAsMailingAddress) {
        // Check for dob in the first array element
        return (
          !isEmptyOrUndefinedOrNull(item.firstName) &&
          !isEmptyOrUndefinedOrNull(item.lastName) &&
          !isEmptyOrUndefinedOrNull(item.dateOfBirth)
        );
      } else if (item.sameAsMailingAddress) {
        return (
          !isEmptyOrUndefinedOrNull(item.firstName) &&
          !isEmptyOrUndefinedOrNull(item.lastName)
        );
      }
      return (
        !isEmptyOrUndefinedOrNull(item.firstName) &&
        !isEmptyOrUndefinedOrNull(item.lastName) &&
        !isEmptyOrUndefinedOrNull(item.mailingAddress.street_address) &&
        !isEmptyOrUndefinedOrNull(item.mailingAddress.city) &&
        !isEmptyOrUndefinedOrNull(item.mailingAddress.zipcode) &&
        !isEmptyOrUndefinedOrNull(item.mailingAddress.state)
      );
    });

    return isValid;
  };

  const checkAgreementsAcknowledgement = () => {
    return (
      agreements?.acknowledge1 &&
      agreements?.acknowledge2 &&
      agreements?.acknowledge3 &&
      agreements?.acknowledge4 &&
      agreements?.acknowledge5 &&
      agreements?.acknowledge6 &&
      agreements?.acknowledge7 &&
      agreements?.acknowledge8
    );
  };

  function getValidStateCode(state, stateCode) {
    function isValidStateCode(value) {
      return typeof value === "string" && /^[A-Z]{2}$/.test(value);
    }
    if (state !== null && isValidStateCode(state)) {
      return state.trim().toLowerCase();
    }

    if (stateCode !== null && isValidStateCode(stateCode)) {
      return stateCode.trim().toLowerCase();
    }
    return "";
  }

  useEffect(() => {
    if (store?.flood?.propertyDataAutocompleteStored) {
      setLocation(store.flood.propertyDataAutocompleteStored);
    } else {
      setLocation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.flood?.propertyDataAutocompleteStored]);

  const autoFloodMapGen = async () => {
    if (
      !location ||
      typeof location !== "object" ||
      Object.keys(location).length === 0 ||
      !location.autocomplete
    ) {
      return;
    }
    try {
      setIsLoading(true);
      const data = await getAutoLocationData({
        requestBody: {
          street_number: location.street_number,
          street_name: location.street_name,
          city: location.city,
          CountyName: location.CountyName,
          PostalCode: location.PostalCode,
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
          value: location.value,
          autocomplete: location.autocomplete,
          state: location.state,
          s_PostalCodeSuffix: location.s_PostalCodeSuffix,
        },
      });
      setAutoGenData(data?.data || {});
    } catch (error) {
      console.error("Error fetching auto location data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function checkNorthIssued(countyName) {
    if (typeof countyName !== "string") {
      return false;
    }
    let formattedCountyName = countyName.trim().replace(/ County$/, "");
    const countyObj = HomeOwnersTerretory.County.find((county) => {
      const key = Object.keys(county)[0];
      return key === formattedCountyName;
    });
    if (countyObj && countyObj?.northIssed) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const getZipIds = async () => {
      if (!location || !location.PostalCode) {
        return;
      }
      const result = await getAutoZipData(location?.PostalCode);

      if (result) {
        setCurrentZipData(result);
      }
    };

    autoFloodMapGen();
    if (
      typeof location?.state === "string" ||
      typeof location?.stateCode === "string"
    ) {
      setDiscounts({
        ...discounts,
        isApplicantLocatedNorhI10: checkNorthIssued(location?.CountyName),
      });
      if (checkNorthIssued(location?.CountyName)) {
        setCoverages({
          ...coverages,
          deductibles: {
            ...coverages?.deductibles,
            // aop: "DED05P", // used to be 5% only for risky countys prior ODS-5687
            hurricaneDeductible: "HURRA05P",
            isCountyRisky: true,
            // why county is risky?
            // Harrison, Jackson, Hancock are marked risky.
          },
        });
      }
      setStateCodeVal(getValidStateCode(location?.state, location?.stateCode));
      getZipIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const triggerUpdateAndCallAPI = async () => {
    try {
      setVendors({
        credit_score: true,
        geocoding: true,
        replacement_cost: true,
        property_characteristics: true,
        cat_losses: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCallApi(true);
    }
  };

  useEffect(() => {
    if (callApi && vendors.credit_score && vendors.geocoding) {
      updateQuotation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi, vendors]);

  const renderErrorContent = (errorData) => {
    if (!errorData) return null;

    const renderErrorItem = (item, index) => {
      if (!item || (typeof item === "string" && !item.trim())) return null;

      return (
        <Box key={index} mb={4}>
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>{item.errorField || "Error"}:</AlertTitle>
              <AlertDescription>
                {item.message || item.toString()}
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      );
    };

    const hasError =
      errorData.error &&
      ((Array.isArray(errorData.error) && errorData.error.length > 0) ||
        (typeof errorData.error === "string" && errorData.error.trim()) ||
        (typeof errorData.error === "object" &&
          !Array.isArray(errorData.error)));

    const hasDetailedError =
      errorData.detailed_error &&
      ((Array.isArray(errorData.detailed_error) &&
        errorData.detailed_error.length > 0) ||
        (typeof errorData.detailed_error === "string" &&
          errorData.detailed_error.trim()) ||
        (typeof errorData.detailed_error === "object" &&
          !Array.isArray(errorData.detailed_error)));

    return (
      <>
        {/* Handle errorData.error */}
        {Array.isArray(errorData.error) &&
          errorData.error.map((e, index) => renderErrorItem(e, index))}
        {typeof errorData.error === "string" &&
          errorData.error.trim() &&
          renderErrorItem({ message: errorData.error })}
        {typeof errorData.error === "object" &&
          !Array.isArray(errorData.error) &&
          renderErrorItem(errorData.error)}

        {/* Handle errorData.detailed_error */}
        {Array.isArray(errorData.detailed_error) &&
          errorData.detailed_error.map((e, index) => renderErrorItem(e, index))}
        {typeof errorData.detailed_error === "string" &&
          errorData.detailed_error.trim() &&
          renderErrorItem({ message: errorData.detailed_error })}
        {typeof errorData.detailed_error === "object" &&
          !Array.isArray(errorData.detailed_error) &&
          renderErrorItem(errorData.detailed_error)}

        {/* If no recognized error format is found */}
        {!hasError && !hasDetailedError && (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Unknown Error</AlertTitle>
              <AlertDescription>An unexpected error occurred.</AlertDescription>
            </Box>
          </Alert>
        )}
      </>
    );
  };

  const updateQuotation = async () => {
    if (
      vendors?.credit_score === false ||
      !starDataObject?.save_quote_response?.data?.policyNo
    )
      return;
    try {
      setIsLoading(true);
      const quoteId = starDataObject?.save_quote_response?.data?.policyNo;
      const res = await updateQuote({
        product: "star",
        quoteId: quoteId,
        requestBody: {
          action: "SaveAndBind",
          fp_payload: {
            ...CreateStarQuotePool(
              policy,
              underWriting,
              discounts,
              coverages,
              location,
              confirmation,
              selectedRisk,
              uniqueID,
              roofProperties,
              lossHistoryData,
              saveMortgagee,
              primaryMailingAddress,
              currentZipData,
              vendors
            ),
          },
        },
      });
      if (res && res.fp_payload) {
        setErrorData(res?.fp_payload);
        setErrorData1(res);
        setIsErrorModalOpen(true);
      }
      if (res?.status === false && res?.error) {
        setErrorDetails(res);
        setIsErrorModalOpe1(true);
      }
      if (res?.message) {
        toast.success(
          res.message === "Edit Quote Successful"
            ? "Updated Successfully"
            : res.message
        );
        if (res?.status) {
          nextStep();
          setEditQuoteRes(res);
        }
      }
    } catch (error) {
      console.error("Error updating quote:", error);
      toast.error("Error updating quote");
    } finally {
      setIsLoading(false);
    }
  };
  useUpdateConstructionDetails();

  return (
    <>
      {isErrorModalOpen1 && (
        <ErrorModal
          isOpen={isErrorModalOpen1}
          onClose={() => setIsErrorModalOpe1(false)}
          alertType="error"
          errorData={errorDetails}
        />
      )}
      <ErrorLogModal
        type="Update/Edit"
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        errorData={errorData}
      />
      {errorData1?.detailed_error && (
        <Modal
          isCentered
          scrollBehavior="inside"
          isOpen={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          size={"5xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Developer Error Logs</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{renderErrorContent(errorData1)}</ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={() => setIsErrorModalOpen(false)}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <ChakraLoader isLoading={loading || isLoading}>
        <Box pb={"1.5rem"}>
          <Card borderRadius={"3xl"} boxShadow={"xl"}>
            <Flex fontSize={{ base: "12px", md: "16px", lg: "20px" }} mt={4}>
              <Flex alignItems={"center"} gap={2}>
                <Box pb={"1.0rem"} pl={7}>
                  <Image src={arrowSquareLeft} width={22} height={22} />
                </Box>
                <Text fontWeight="var(--chakra-fontWeights-medium)">
                  {t("quote_id")}
                </Text>
                <Text
                  color="var(--chakra-colors-primary-500)"
                  fontWeight="var(--chakra-fontWeights-medium)"
                >
                  {starDataObject?.save_quote_response?.data?.policyNo ||
                    t("TMPXXXXXXXXXXXX")}
                </Text>
                {starDataObject?.save_quote_response?.data?.policyNo && (
                  <Flex alignItems={"center"} ml={0} mt={"-16px"}>
                    <Tooltip
                      p={2}
                      bg={"white"}
                      color={"primary.500"}
                      label="Create a New Quote"
                      hasArrow
                    >
                      <Box
                        onClick={() => {
                          setStarDataObject({});
                          setBoundData({});
                          window.location.href = `/quote/starins/start`;
                        }}
                        _hover={{ cursor: "pointer" }}
                      >
                        <CgSync size={20} color={"blue"} />
                      </Box>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>

              <Spacer />
              <Flex flexDir={"column"} gap={0} pt={2} pr={2}>
                <Text
                  my={0}
                  textAlign={"end"}
                  fontWeight="var(--chakra-fontWeights-medium)"
                  color={"var(--chakra-colors-primary-500)"}
                >
                  {underWriting?.propertyDetails?.customerType === "individual"
                    ? ` ${underWriting?.propertyDetails?.firstName || ""}
                      ${underWriting?.propertyDetails?.lastName || ""}`
                    : underWriting?.propertyDetails?.buissnessName || ""}
                </Text>
                <Text my={0} fontWeight="var(--chakra-fontWeights-medium)">
                  {location?.autocomplete || location?.street_number || ""}
                </Text>
              </Flex>
              {starDataObject?.data?.quote_premiums?.original_model
                ?.total_premium ||
              starDataObject?.data?.quote_premiums?.original_model
                ?.final_premium ? (
                <Tooltip
                  p={4}
                  fontSize={"md"}
                  hasArrow
                  label={t(
                    "Prices are estimates and are subject to change until our underwriting verification of the data used to rate your policy is completed, which may include, but is not limited to verification of your claims history, your insurance score, a property inspection, and other underwriting criteria, as applicable. Changes made to coverage or deductible selections will affect pricing. Please note a Wind Mitigation credit amount may vary, pending receipt of any state-mandated inspection form."
                  )}
                  aria-label={t("Estimated Annual Quote Tooltip")}
                  placement="bottom"
                >
                  <Box pl={2} pt={1} pb={2} pr={5} mb={4}>
                    <Flex
                      px={4}
                      py={1}
                      justifyContent={"center"}
                      alignItems={"center"}
                      color={"white"}
                      flexDir={"column"}
                      bg={"var(--chakra-colors-primary-500)"}
                      borderRadius={"xl"}
                    >
                      <Text my={"0.15rem"} fontSize={"xl"} textAlign={"center"}>
                        <Currency
                          amount={
                            feesData?.finalPremium !== 0 &&
                            feesData?.finalPremium !== null &&
                            feesData?.finalPremium !== undefined
                              ? feesData?.finalPremium || 0
                              : starDataObject?.data?.quote_premiums
                                  ?.original_model?.total_premium ||
                                starDataObject?.data?.quote_premiums
                                  ?.original_model?.final_premium ||
                                0
                          }
                        />
                      </Text>
                      <Text my={"0.1rem"} fontSize={"xs"} textAlign={"center"}>
                        {t("ESTIMATED ANNUAL QUOTE")}
                      </Text>
                    </Flex>
                  </Box>
                </Tooltip>
              ) : (
                <Box mb={4} mr={5}></Box>
              )}
            </Flex>
            <Flex flexDir="column" width="100%">
              <Box p="5">
                {hasCompletedAllSteps ? (
                  <>
                    <Thanks />
                    {boundData?.transferData?.Policy_No ? (
                      <>
                        <Box>
                          <Heading fontSize="xl" textAlign={"center"}>
                            Thank You!
                          </Heading>
                        </Box>

                        <Flex
                          flexDir={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          p={3}
                          gap={3}
                        >
                          <Button
                            size="lg"
                            onClick={() => {
                              reset();
                              setStarDataObject({});
                              setBoundData({});
                              setVendors({
                                credit_score: false,
                                geocoding: false,
                                replacement_cost: false,
                                property_characteristics: false,
                                cat_losses: false,
                              });
                              setAgreements({
                                acknowledge1: false,
                                acknowledge2: false,
                                acknowledge3: false,
                                acknowledge4: false,
                                acknowledge5: false,
                                acknowledge6: false,
                                acknowledge7: false,
                                acknowledge8: false,
                              });
                            }}
                            borderWidth="2px"
                            borderColor="var(--chakra-colors-primary-500)"
                            color="var(--chakra-colors-black)"
                            borderRadius="xl"
                            padding="20px"
                            variant="outline"
                          >
                            {t("Reset")}
                          </Button>
                          <AdditionalInfoToDisplay
                            starDataObject={starDataObject}
                            boundData={boundData}
                            editQuoteRes={editQuoteRes}
                          />

                          <Button
                            onClick={() => {
                              navigate(
                                `/quote/editApplication/1/${starDataObject?.save_quote_response?.data?.poTransPK}`
                              );
                            }}
                            borderRadius="xl"
                          >
                            Continue to {t("Quote")}
                          </Button>
                        </Flex>
                      </>
                    ) : (
                      <>
                        <Flex justifyContent={"center"}>
                          <Button
                            size="lg"
                            onClick={prevStep}
                            borderWidth="2px"
                            borderColor="var(--chakra-colors-primary-500)"
                            color="var(--chakra-colors-black)"
                            borderRadius="xl"
                            padding="20px"
                            variant="outline"
                          >
                            {t("Back")}
                          </Button>
                        </Flex>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Flex flexDir={"column"}>
                      <Steps
                        pl={2}
                        w={"100%"}
                        responsive={true}
                        alignItems="center"
                        variant={"circles"}
                        colorScheme="blue"
                        activeStep={activeStep}
                        className="arrow-steps clearfix"
                        sx={{
                          fontSize: ["14px", "16px", "18px"],
                          "& .arrow-steps": {
                            display: "flex",
                          },
                        }}
                      >
                        {steps.map(({ label, component }, index) => (
                          <Step key={index} label={label}>
                            <Box p={2.5} pt={8}>
                              {component}
                            </Box>
                          </Step>
                        ))}
                      </Steps>
                    </Flex>

                    <Flex width="100%" justify="center" gap={4} pt={6} pb={8}>
                      <>
                        <Button
                          isDisabled={activeStep === 0}
                          onClick={prevStep}
                          size="lg"
                          variant="ghost"
                          borderWidth="2px"
                          borderColor="var(--chakra-colors-neutral-500)"
                          color="var(--chakra-colors-black)"
                          borderRadius="12px"
                          padding="20px"
                        >
                          <Image
                            src={arrowLeft}
                            width={22}
                            height={22}
                            alt=""
                            style={{ marginRight: "2px" }}
                          />
                          {t("Back")}
                        </Button>
                        {starDataObject?.save_quote_response?.data?.policyNo &&
                          activeStep === 6 && (
                            <Button
                              size="lg"
                              bg={"primary.500"}
                              color="white"
                              borderWidth={"2px"}
                              padding="20px"
                              mb="120px"
                              onClick={() => {
                                navigate(
                                  `/quote/editApplication/1/${starDataObject?.save_quote_response?.data?.poTransPK}`
                                );
                              }}
                              borderRadius="xl"
                            >
                              Continue to {t("Quote")}
                              <Image
                                src={arrowRight}
                                width={22}
                                height={22}
                                alt=""
                                style={{ marginLeft: "2px" }}
                              />
                            </Button>
                          )}
                        {activeStep < 6 ? (
                          <Button
                            size="lg"
                            onClick={handleNextStep}
                            borderWidth="2px"
                            borderColor="var(--chakra-colors-primary-500)"
                            borderRadius="12px"
                            padding="20px"
                            isDisabled={
                              activeStep === 6 &&
                              !starDataObject?.save_quote_response?.data
                                ?.policyNo
                            }
                          >
                            {t("Next")}

                            <Image
                              src={arrowRight}
                              width={22}
                              height={22}
                              alt=""
                              style={{ marginLeft: "2px" }}
                            />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </>
                    </Flex>
                  </>
                )}
              </Box>
            </Flex>
          </Card>
        </Box>
      </ChakraLoader>
    </>
  );
};
