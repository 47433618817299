import React, { useCallback, useEffect, useState } from "react";
import { Row,  Col, Form } from "react-bootstrap";
import { FaFileUpload, FaPlus, FaTimes } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { getDocTypes } from "../../../../services/coreSystemService";
import { getActivityFileSave } from "../../../../services/activityService";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import Moment from 'moment';
import { Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, Image, Input, Select, Spacer, Text, VStack } from '@chakra-ui/react'
import { FaRegSave } from "react-icons/fa";

const AddAttachment = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { policyNumber, policyPK, docTrackTransPK = 0, docTypeFK = 0, setShow = () => { } } = props;
    const module = "Policy";
    const subModuleName = "ATTACHMENTS_ADD_POLICY";
    const [documentTypesDD, setDocumentTypesDD] = useState([]);
    const [fileName, setFileName] = useState("");
    const [docType, setDocType] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(true);

    useEffect(() => {
        getDocTypeDD();
    }, [])

    // get drop down
    const getDocTypeDD = async () => {
        setLoading(true);
        await dispatch(getDocTypes(module, subModuleName)).then((response) => {
            if (Array.isArray(response) && response.length > 0) {
                setDocumentTypesDD(response);

                // if docTypeFK is passed select that type from dropdown
                if (docTypeFK) {
                    setDocType(docTypeFK);
                }
            }
        }).catch(() => {
            toast.error(t('Something Went Wrong!'));
        })
        setLoading(false);
    }

    // set file name
    useEffect(() => {
        if (selectedFiles.name) {
            var name = selectedFiles.name;
            setFileName(name.substr(0, selectedFiles.name.lastIndexOf('.')) || "file name");
        }
        setIsFileSelected(true)
    }, [selectedFiles])

    // on drop file
    const onDrop = useCallback(event => {
        setSelectedFiles(event[0]);
    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

    // display file name 
    var files = acceptedFiles.map((file, i) => (
        <li key={file.path}>
            {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => removeFile(i)} />
        </li>
    ));

    // to delete file
    const removeFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(file, 1);
        acceptedFiles.splice(file, 1);
        setSelectedFiles(newFiles);
    };

    // validation
    const validate = () => {
        var isValid = true;
        if ([undefined].includes(selectedFiles.name)) {
            toast.error(t('Please Select File'))
            isValid = false;
            setIsFileSelected(false);
        }
        if ([null, '', undefined].includes(docType)) {
            isValid = false;
        }
        if ([null, '', undefined].includes(fileName)) {
            isValid = false;
        }
        setValidated(!isValid);
        return isValid;
    };

    // submit form
    const handleSubmit = async () => {
        const isValid = validate();
        if (isValid) {
            const formData = new FormData();
            formData.append("file", selectedFiles, selectedFiles.name);
            formData.append('fileName', fileName);
            formData.append("moduleSelected", 1);
            formData.append('moduleName', module);
            formData.append('subModule', subModuleName);
            formData.append('authorization', 'Internal');
            formData.append('doctypeSelected', docType);
            formData.append("description", fileName);
            formData.append('sourcePk', policyPK);
            formData.append('activityLogPk', 0);
            formData.append('subModulePk', 251);
            formData.append("uploadDate", Moment().format('YYYY-MM-DD'));
            formData.append("receivedDate", Moment().format('YYYY-MM-DD'));

            // for update status in document track trans table (Required document list) 
            if (docTrackTransPK) {
                formData.append("documentTrackTransPK", docTrackTransPK);
            }

            setLoading(true);
            await dispatch(getActivityFileSave(formData)).then((res) => {
                toast.success(t(res.data.message));
                resetState();
                setShow(false);
            }).catch(() => {
                toast.error(t('Something Went Wrong!'));
            })
            setLoading(false);
        }
    };

    const resetState = () => {
        setSelectedFiles([]);
        setDocType('');
        setFileName('');
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);
    };

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
                <Form validated={validated}>
            <Box  border={"1px solid var(--chakra-colors-neutral-300)"} borderRadius={"8px"}>
                <Flex>
                    <Box w={"49%"} p='2'>
                        <Grid templateColumns={"repeat(1, 1fr)"} gap={3} p={2}>
                            <GridItem>
                                <FormControl>
                                    <FormLabel>
                                        {t('Module')}
                                    </FormLabel>
                                    <Input 
                                        type="text"
                                        placeholder="Module"
                                        value={module}
                                        isReadOnly
                                        bg={"var(--chakra-colors-neutral-200)"}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl>
                                    <FormLabel>
                                        {t('Policy Number')}
                                    </FormLabel>
                                    <Input 
                                        type="text"
                                        placeholder="Policy Number"
                                        value={policyNumber || ''}
                                        isReadOnly
                                        bg={"var(--chakra-colors-neutral-200)"}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>
                                        {t('Document Type')}
                                    </FormLabel>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        name="docuemntType"
                                        id="docuemntType"
                                        onChange={(e) => setDocType(e.currentTarget.value)}
                                        value={docType}
                                        required
                                        disabled={loading}
                                    >
                                        <option value={''}>{t('Select Document Type')}</option>
                                            {documentTypesDD.length > 0 && documentTypesDD.map((docType, i) => (
                                            <option key={i} value={docType.n_DocType_PK}>{docType.s_DocTypeNameForDisplay}</option>
                                        ))}
                                    </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                {t("Please Enter File Name")}
                            </Form.Control.Feedback>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>
                                        {t('File Name')}
                                    </FormLabel>
                                    <Input 
                                        className="dashboard-group-input-flood"
                                        placeholder={t('File Name')}
                                        type="text"
                                        onChange={(e) => setFileName(e.target.value)}
                                        value={fileName}
                                        isDisabled={loading}
                                    />
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </Box>
                    <Spacer />
                    <Box w={"49%"} p='2'>
                        <FormLabel ml={7}>{t('Attachment')}</FormLabel>
                        <Box
                            // id="drop-document-type-div"
                            {...getRootProps({ className: "dropzone" })}
                        >
                            <Input {...getInputProps()} />
                            <Image src="/assets/images/uploadImageFrame.svg" alt="frame" />
                            <aside className="reply-document-name">
                                <ul>{files}</ul>
                            </aside>
                            {!isFileSelected && (
                                <aside className="reply-document-name" style={{ color: 'red' }}>
                                    <ul>{t('Please Select File')}</ul>
                                </aside>
                            )}
                        </Box>
                    </Box>
                </Flex>
                <Flex justifyContent={"flex-end"} p={2} >
                    <Button
                        leftIcon={<FaRegSave />}
                        size={"sm"}
                        onClick={() => handleSubmit()}
                        idDisabled={loading}
                    >
                        {t('Submit')}
                    </Button>
                </Flex>
            </Box>
            </Form>
            {/* <Row>
                <Col xs="12" sm="12" md="12" lg="5" xl="5" style={{ marginTop: "25px" }}>
                    <Form validated={validated} >
                        <Form.Label className="confirm-address-label">
                            {t('Module')}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input"
                            type="text"
                            placeholder="Communication"
                            value={module}
                            disabled
                        />

                        <Form.Label className="confirm-address-label mt-2">
                            {t('Policy NO')}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input"
                            type="text"
                            placeholder="Communication"
                            value={policyNumber || ''}
                            disabled
                        />

                        <Form.Group>
                            <Form.Label className="confirm-address-label mt-2">
                                {t('Document Type')}
                            </Form.Label>
                            <Form.Select
                                className="dashboard-group-input-flood"
                                name="docuemntType"
                                id="docuemntType"
                                onChange={(e) => setDocType(e.currentTarget.value)}
                                value={docType}
                                required
                                disabled={loading}
                            >
                                <option value={''}>{t('Select Document Type')}</option>
                                {documentTypesDD.length > 0 && documentTypesDD.map((docType, i) => (
                                    <option key={i} value={docType.n_DocType_PK}>{docType.s_DocTypeNameForDisplay}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {t("Please Select Document Type")}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="confirm-address-label mt-2">
                                {t('File Name')}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                placeholder={t('File Name')}
                                type="text"
                                onChange={(e) => setFileName(e.target.value)}
                                value={fileName}
                                required
                                disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("Please Enter File Name")}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs="12" sm="12" md="12" lg="7" xl="7" style={{ marginTop: "25px" }}>
                    <span className="dashboard-group-label">{t('Attachment')}</span>
                    <div
                        id="drop-document-type-div"
                        {...getRootProps({ className: "dropzone" })}
                    >
                        <input {...getInputProps()} />
                        <FaFileUpload size={34} color={"#64D6CD"} />
                        <p>{t('Drag and Drop File Here')}</p>
                        <Button id="common-btn" variant="primary">
                            <FaPlus size={15} />
                            {t('Add File')}
                        </Button>{" "}
                    </div>

                    <aside className="reply-document-name">
                        <ul>{files}</ul>
                    </aside>
                    {!isFileSelected && (
                        <aside className="reply-document-name" style={{ color: 'red' }}>
                            <ul>{t('Please Select File')}</ul>
                        </aside>
                    )}
                </Col>
                <div className="card-header-main-flood">
                    <ChakraButton
                        leftIcon={<FaRegSave />}
                        onClick={() => handleSubmit()}
                        idDisabled={loading}
                    >
                        {t('Submit')}
                    </ChakraButton>
                </div>
            </Row> */}
        </LoadingOverlay>
    )
}

export default AddAttachment;