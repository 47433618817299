import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Grid,
  keyframes,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ApiLogs from "../utils/Logs.jsx";
import { toast } from "react-toastify";
import { bindStarQuote } from "../../../create-star-quote/apis/bindStarQuote.jsx";
import { CreateStarApplicationPool } from "../utils/CreateStarApplicationPool.jsx";
import { updateStarQuote } from "../utils/UpdateStarData.jsx";
import ErrorModal from "../utils/ErrorModal.jsx";
import { useNavigate } from "react-router-dom";
import { FaSave, FaPrint, FaHistory, FaListAlt, FaCheck } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { getPrintQuoteDoc } from "../api/getPrintQuoteDoc.jsx";
import PaymentModal from "./PaymentModal.jsx";
import {
  getEditEmptyQuoteData,
  getQuoteData,
} from "../../../../../services/quoteService.js";
import { getPaymentWindow } from "../api/ProccedPayment.jsx";
// import { EncryptOrDecrypt } from "../../../../../services/commonService";
import { isAllowedToBindQuoteInStarInsurance } from "../../../../../services/moduleVisibilityService.js";
import { requestBindQuoteApi } from "../api/requestBindQuoteApi.js";
import { rateSheet } from "../api/rateSheetApi.jsx";
import { RateHistoryModal } from "./RateHistoryGrid.jsx";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const blink = keyframes`
0% { opacity: 1; }
50% { opacity: 0.1; }
100% { opacity: 1; }
`;

let requiredDoc = 0;

const QuoteButtons = ({
  quoteData,
  rateSubmit,
  loading,
  setLoading,
  baseData,
  isBind,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [requestBindConfirmationModal, setRequestBindConfirmationModal] =
    useState(false);
  const [openRateHistoryModal, setOpenRateHistoryModal] = useState(false);
  const dispatch = useDispatch();
  const [agentSubModulesAccess, setAgentSubModulesAccess] = useState(
    localStorage.getItem("agentSubModules")
      ? JSON.parse(localStorage.getItem("agentSubModules"))
      : []
  );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getMortgageeType = (personType) => {
    switch (personType) {
      case "PRIMARY":
        return "First mortgagee";
      case "SECOND":
        return "Second mortgagee";
      case "THIRD":
        return "Third mortgagee";
      default:
        return null;
    }
  };

  const extractMortgagees = (data) => {
    if (!Array.isArray(data) || (Array.isArray(data) && data.length < 1)) {
      return [];
    }
    const mortgagees = [];
    data.forEach((item) => {
      if (
        !["ADDITIONALINTEREST", "ADDITIONALINSURED"].includes(item.s_PersonType)
      ) {
        mortgagees.push({
          n_PolicyAddInterest_PK: item.n_PolicyAddInterest_PK || null,
          personType: item.s_PersonType || "",
          mortgageeType: getMortgageeType(item.s_PersonType) || "",
          loanNo: item.s_LoanNo || "",
          person: {
            entityType:
              typeof item.person.s_EntityType === "string"
                ? item.person.s_EntityType.toUpperCase()
                : item.person.s_EntityType || "",
            firstName: item.person.s_FirstName || "",
            middleName: item.person.s_MiddleName || "",
            lastName: item.person.s_LastOrganizationName || "",
            isMailingDiff: item.person.s_IsMailingDiff || "",
            isTempMailing: item.person.s_IsTempMailing || "",
            n_PersonInfoId_PK: item.person.n_PersonInfoId_PK || null,
            isInsuredTenant: "",
          },
          address: {
            addressLine1: item.address.s_AddressLine1 || "",
            addressLine2: item.address.s_AddressLine2 || "",
            county: item.address.s_CountyName || "",
            city: item.address.s_CityName || "",
            cityId: item.address.n_CityId_FK || 0,
            stateId: item.address.n_StateId_FK || 0,
            countryId: item.address.n_CountryId_FK || 0,
            stateCode: item.address.s_StateName || "",
            country: "US",
            countyId: item.address.n_CountyId_FK || 1,
            latitude: parseFloat(item.address.s_Latitude) || null,
            longitude: parseFloat(item.address.s_Longitude) || null,
            zip: item.address.s_PostalCode || "",
          },
        });
      }
    });

    return mortgagees;
  };

  const rateSheetCall = async () => {
    const poTransPk = quoteData?.transactionInfo?.n_potransaction_PK;
    if (!poTransPk) {
      toast.error("Invalid Quote");
      return;
    }
    try {
      setLoading(true);
      const res = await rateSheet(poTransPk);
      if (res && (res.status === "Y" || res.status == true)) {
        toast.success(res.msg || "Rate Sheet Generated", { autoClose: 1000 });
        if (res.url) {
          setTimeout(() => {
            window.open(res.url, "_blank");
          }, 1250);
        } else {
          toast.info("Document is being generated please try again");
        }
      } else {
        toast.error(res.msg || "Rate Sheet Generation Failed");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateQuote = async () => {
    try {
      setLoading(true);
      const response = await updateStarQuote({
        requestBody: {
          ...quoteData,
          action: "SaveAndBind",
          fp_payload: CreateStarApplicationPool({
            quoteData,
            baseData,
            vendors: true,
          }),
        },
        quoteId: quoteData?.policyInfo?.Policy_No_Quote,
        product: "star",
      });

      if (response?.status === true) {
        toast.success("Quote Updated Successfully", { autoClose: 1000 });
        await bindQuote();
      } else if (response?.status === false && response?.error) {
        setErrorDetails(response);
        setIsErrorModalOpen(true);
      } else if (response?.status === false && response?.errors) {
        setErrorDetails(response);
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        t(
          "An error occurred while updating the quote. Please try again later."
        ),
        { position: "top-center" }
      );
    } finally {
      setLoading(false);
    }
  };

  const bindQuote = async () => {
    const mortgagees = extractMortgagees(quoteData?.mortgageeData);

    setLoading(true);
    try {
      const quoteNo = quoteData?.policyInfo?.Policy_No_Quote || "";
      const uniqueID = quoteData?.propertyInfo?.metadata?.fpSubmissionId || "";
      if (!quoteNo) return;
      const reqBody = {
        submission_details: { id: uniqueID },
        mortgagee:
          Array.isArray(mortgagees) && mortgagees.length > 0
            ? {
                name:
                  mortgagees[0]?.person?.lastName ||
                  mortgagees[0]?.person?.firstName ||
                  "",
                loan_id: mortgagees[0]?.loanNo || "N/A",
                current_address: {
                  street_address: mortgagees[0]?.address?.addressLine1 ?? "",
                  city: mortgagees[0]?.address?.city ?? "",
                  zipcode: mortgagees[0]?.address?.zip ?? "",
                  state: mortgagees[0]?.address?.stateCode ?? "",
                },
              }
            : {},
      };
      const res = await bindStarQuote("star", quoteNo, reqBody);
      if (
        res?.core_service_response?.transferData?.Msg ||
        res?.core_service_response?.data?.Msg
      ) {
        const successMessage =
          res.core_service_response.transferData.Msg ||
          res.core_service_response.data.Msg === "Policy Rated Successfully"
            ? "Application Bound Successfully"
            : res.core_service_response.data.Msg;
        toast.success(successMessage, {
          position: "top-center",
          autoClose: 1000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(t("Failed to bind the quote. Please try again later."), {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(
        t("An error occurred while binding the quote. Please try again later."),
        { position: "top-center" }
      );
    } finally {
      setLoading(false);
    }
  };

  const printQuote = async (printType) => {
    try {
      setLoading(true);
      const poTransPk = quoteData?.transactionInfo?.n_potransaction_PK;
      if (!poTransPk) {
        toast.error("Transaction PK not found");
        return;
      }
      const callPrintQuote = async () => {
        const updateResult = await getPrintQuoteDoc(poTransPk, printType);

        if (
          (updateResult?.status === "Y" || updateResult?.status !== "N") &&
          (updateResult.url || updateResult.path)
        ) {
          if (updateResult.url) {
            toast.success("Quotation for print generated", { autoClose: 1500 });
            window.open(updateResult.url, "_blank");
          } else {
            toast.info("Document is being generated please try again");
          }
        } else if (updateResult?.msg && updateResult?.status === "N") {
          toast.warn(updateResult.msg);
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1500));
          await callPrintQuote();
        }
      };
      await callPrintQuote();
    } catch (error) {
      console.error("Error in printQuote:", error);
      toast.error("An error occurred while generating the print quote");
    } finally {
      setLoading(false);
    }
  };

  const requestBind = async () => {
    setLoading(true);
    try {
      const policyNumber = quoteData?.policyInfo?.Policy_No_Quote;
      if (!policyNumber) {
        throw new Error("Policy number is missing");
      }
      await requestBindQuoteApi(policyNumber);
      toast.success("Quote Bind Requested", {
        position: "top-center",
        toastId: "quoteBind Request",
      });
      window.location.reload();
    } catch (error) {
      console.error("Failed to bind quote:", error);
      toast.error("Failed to bind quote. Please try again.", {
        position: "top-center",
        toastId: "quoteBindError",
      });
    } finally {
      setLoading(false);
    }
  };
  const buttons = [
    {
      text: t("API Logs"),
      key: "API_LOGS",
      isVisible: isAllowedToBindQuoteInStarInsurance(agentSubModulesAccess),
      icon: <FaHistory color="white" />,
      onClick: toggleModal,
    },
    {
      text: t("Rate Sheet"),
      icon: <FaListAlt color="white" />,
      onClick: () => rateSheetCall(),
      key: "RATE_SHEET",
      isVisible:
        isAllowedToBindQuoteInStarInsurance(agentSubModulesAccess) && !isBind,
      loading: loading,
    },
    {
      text: t("Rate History"),
      key: "RATE_HISTORY",
      isVisible:
        isAllowedToBindQuoteInStarInsurance(agentSubModulesAccess) && !isBind,
      icon:
        requiredDoc > 0 ? (
          <>
            <Flex alignItems={"center"} gap={2}>
              <Badge
                py={0.5}
                ml={"-10px"}
                rounded="full"
                fontSize="15px"
                colorScheme="whiteAlpha"
                size={"sm"}
                animation={`${blink} 3s infinite`}
              >
                <Text as={"span"} fontSize="15px">
                  {requiredDoc}
                </Text>
              </Badge>
              <IoDocumentTextOutline size={20} />
            </Flex>
          </>
        ) : (
          <IoDocumentTextOutline size={20} />
        ),

      onClick: () => setOpenRateHistoryModal(true),
    },
    // {
    //   text: "Print App",
    //   icon: <FaPrint color="white" />,
    //   borderColor: "yellow.200",
    //   // onClick: () => printQuote("PRINTAPP"),
    // },
    {
      text: t("Submit for UW Review"),
      key: "REQUEST_BIND",
      icon: <FaSave color="white" />,
      isVisible:
        !isBind &&
        !quoteData?.policyInfo?.d_BoundDate &&
        !isAllowedToBindQuoteInStarInsurance(agentSubModulesAccess),
      // borderColor: "green.500",
      onClick: () => setRequestBindConfirmationModal(true),
      loading: loading,
    },
    {
      text: t("Submitted For UW Review"),
      bg: quoteData?.policyInfo?.d_BoundDate ? "primary" : "red",
      key: "Submitted",
      icon: <AiOutlineLoading3Quarters color="white" />,
      isVisible:
        quoteData?.policyInfo?.d_BoundDate &&
        !isAllowedToBindQuoteInStarInsurance(agentSubModulesAccess) &&
        !isBind,
      // borderColor: "green.500",
      loading: loading,
    },
    {
      text: t("Print Quote"),
      key: "PRINT_QUOTE",
      isVisible: !isBind,
      icon: <FaPrint color="white" />,
      // borderColor: "primary.500",
      // onClick: () => printQuote("PRINTQUOTE"),
    },
    {
      text: quoteData?.policyInfo?.d_BoundDate
        ? t("Accept Bind Request")
        : t("Submit for UW Review"),
      key: "BIND_APPLICATION",
      isVisible:
        !isBind && isAllowedToBindQuoteInStarInsurance(agentSubModulesAccess),
      icon: quoteData?.policyInfo?.d_BoundDate ? (
        <FaCheck color="white" />
      ) : (
        <FaPrint color="white" />
      ),
      onClick: updateQuote,
      loading: loading,
    },
    {
      text: t("Save & Rate"),
      key: "SAVE_RATE",
      isVisible: !isBind,
      icon: <FaSave color="black" />,
      borderColor: "primary.500",
      onClick: rateSubmit,
      loading: loading,
      bg: "orange.200",
      color: "black",
    },
    {
      text: t("Print App"),
      isVisible: isBind,
      icon: <FaPrint color="white" />,
      // borderColor: "yellow.200",
      onClick: () => printQuote("PRINTAPP"),
      loading: !quoteData?.transactionInfo?.n_potransaction_PK,
    },
    {
      text: t("Continue to Policy"),
      isVisible: isBind,
      icon: <FaPrint color="white" />,
      onClick: () =>
        navigate(`/core/policy/${quoteData?.policyInfo?.Policy_No}`),
      bg: "orange.200",
      color: "black",
    },
  ];

  // const filteredButtons = isBind
  //   ? [
  //       {
  //         text: "API Logs",
  //         icon: <FaHistory color="white" />,
  //         // borderColor: "primary.500",
  //         onClick: toggleModal,
  //       },
  //       {
  //         text: "Print App",
  //         icon: <FaPrint color="white" />,
  //         // borderColor: "yellow.200",
  //         onClick: () => printQuote("PRINTAPP"),
  //         loading: !quoteData?.transactionInfo?.n_potransaction_PK,
  //       },
  //       {
  //         text: "Continue to Policy",
  //         icon: <FaPrint color="white" />,
  //         onClick: () =>
  //           navigate(`/core/policy/${quoteData?.policyInfo?.Policy_No}`),
  //         bg: "orange.200",
  //         color: "black",
  //       },
  //     ]
  //   : buttons;

  const getQuoteforEdit = async (transID) => {
    await dispatch(getQuoteData(transID)).then((res) => {
      // if (
      //   res.policyInfo.d_BinderDate !== "" &&
      //   res.policyInfo.d_BinderDate !== null
      // ) {
      //   setIsBind(true);
      // }
      dispatch(getEditEmptyQuoteData(res));
    });
  };
  const proceedPayment = async ({ type, policyNo }) => {
    function getHostUrl() {
      return window.location.protocol + `//` + window.location.host;
    }
    const hostUrl = getHostUrl();
    const paymentSuccessUrl = hostUrl + `/core/policy/` + policyNo;
    const paymentCancelUrl = paymentSuccessUrl;
    const reqBody = {
      transaction_amount: Math.round(quoteData?.premiumData?.FinalPremium),
      url_success: paymentSuccessUrl + "?cancelled=false",
      url_cancel: paymentCancelUrl + "?cancelled=true",
      payment_type: type,
      policyNo: policyNo,
      source: "AgentSide_UW_NBquote",
    };
    if (policyNo) {
      try {
        setLoading(true);
        const res = await getPaymentWindow(reqBody);
        if (res?.data?.payment_page_url) {
          window.open(res.data.payment_page_url, "_blank");
          // window.location.href = res.data.payment_page_url;
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
      } catch (error) {
        toast.error("An error occurred while generating the payment link");
      } finally {
        setLoading(false);
      }
    }
  };

  const bindQuotePay = async (type) => {
    const mortgagees = extractMortgagees(quoteData?.mortgageeData);

    setLoading(true);
    try {
      const quoteNo = quoteData?.policyInfo?.Policy_No_Quote || "";
      const uniqueID = quoteData?.propertyInfo?.metadata?.fpSubmissionId || "";
      if (!quoteNo) return;
      const reqBody = {
        submission_details: { id: uniqueID },
        mortgagee:
          Array.isArray(mortgagees) && mortgagees.length > 0
            ? {
                name:
                  mortgagees[0]?.person?.lastName ||
                  mortgagees[0]?.person?.firstName ||
                  "",
                loan_id: mortgagees[0]?.loanNo || "N/A",
                current_address: {
                  street_address: mortgagees[0]?.address?.addressLine1 ?? "",
                  city: mortgagees[0]?.address?.city ?? "",
                  zipcode: mortgagees[0]?.address?.zip ?? "",
                  state: mortgagees[0]?.address?.stateCode ?? "",
                },
              }
            : {},
      };
      const res = await bindStarQuote("star", quoteNo, reqBody);
      if (
        res?.core_service_response?.transferData?.Msg ||
        res?.core_service_response?.data?.Msg
      ) {
        const successMessage =
          res.core_service_response.transferData.Msg ||
          res.core_service_response.data.Msg === "Policy Rated Successfully"
            ? "Application Bound Successfully"
            : res.core_service_response.data.Msg;
        toast.success(successMessage, {
          position: "top-center",
          autoClose: 1000,
        });
        await getQuoteforEdit(quoteData?.transactionInfo?.n_potransaction_PK);
        if (type === "check") {
          setOpenCheckModal(true);
        } else if (type === "PLASTIC_CARD") {
          proceedPayment({
            type,
            policyNo: res?.core_service_response?.transferData?.Policy_No,
          });
        } else if (type === "ACH") {
          proceedPayment({
            type,
            policyNo: res?.core_service_response?.transferData?.Policy_No,
          });
        }
        // setBoundData(res.core_service_response);
      } else {
        toast.error(t("Failed to bind the quote. Please try again later."), {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(
        t("An error occurred while binding the quote. Please try again later."),
        { position: "top-center" }
      );
    } finally {
      setLoading(false);
    }
  };
  const handlePaymentMethod = async (paymentMethod) => {
    if (paymentMethod === "check") {
      if (isBind) {
        setOpenCheckModal(true);
        return;
      }
      try {
        setLoading(true);
        const response = await updateStarQuote({
          requestBody: {
            ...quoteData,
            action: "SaveAndBind",
            fp_payload: CreateStarApplicationPool({ quoteData, baseData }),
          },
          quoteId: quoteData?.policyInfo?.Policy_No_Quote,
          product: "star",
        });
        if (response?.status === true) {
          await bindQuotePay("check");
          await getQuoteforEdit(quoteData?.transactionInfo?.n_potransaction_PK);
        } else if (response?.status === false && response?.error) {
          setErrorDetails(response);
          setIsErrorModalOpen(true);
        } else if (response?.status === false && response?.errors) {
          setErrorDetails(response);
          setIsErrorModalOpen(true);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    } else if (paymentMethod === "PLASTIC_CARD") {
      if (isBind) {
        proceedPayment({
          type: "PLASTIC_CARD",
          policyNo: quoteData?.policyInfo?.Policy_No,
        });
        return;
      }
      try {
        setLoading(true);
        const response = await updateStarQuote({
          requestBody: {
            ...quoteData,
            action: "SaveAndBind",
            fp_payload: CreateStarApplicationPool({ quoteData, baseData }),
          },
          quoteId: quoteData?.policyInfo?.Policy_No_Quote,
          product: "star",
        });
        if (response?.status === true) {
          await bindQuotePay("PLASTIC_CARD");
        } else if (response?.status === false && response?.error) {
          setErrorDetails(response);
          setIsErrorModalOpen(true);
        } else if (response?.status === false && response?.errors) {
          setErrorDetails(response);
          setIsErrorModalOpen(true);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    } else if (paymentMethod === "ACH") {
      if (isBind) {
        proceedPayment({
          type: "ACH",
          policyNo: quoteData?.policyInfo?.Policy_No,
        });
        return;
      }
      try {
        setLoading(true);
        const response = await updateStarQuote({
          requestBody: {
            ...quoteData,
            action: "SaveAndBind",
            fp_payload: CreateStarApplicationPool({ quoteData, baseData }),
          },
          quoteId: quoteData?.policyInfo?.Policy_No_Quote,
          product: "star",
        });
        if (response?.status === true) {
          await bindQuotePay("ACH");
        } else if (response?.status === false && response?.error) {
          setErrorDetails(response);
          setIsErrorModalOpen(true);
        } else if (response?.status === false && response?.errors) {
          setErrorDetails(response);
          setIsErrorModalOpen(true);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderBindButtons = () => {
    if (!isBind) return null;

    const allowedToBind = isAllowedToBindQuoteInStarInsurance(
      agentSubModulesAccess
    );

    return <Box gridColumn={allowedToBind ? "span 4" : "span 5"} />;
  };

  return (
    <>
      {isErrorModalOpen && (
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          alertType="error"
          errorData={errorDetails}
        />
      )}
      <Box mb={5}>
        <Grid
          alignItems={"center"}
          w={"100%"}
          templateColumns={
            isBind ? "repeat(7, 1fr)" : "repeat(auto-fit, minmax(180px, 1fr))"
          }
          p={1}
          gap={3.5}
          my={1}
        >
          {renderBindButtons()}
          {buttons.map((buttonProps, index) => (
            <>
              <Button
                w={"auto"}
                key={index}
                leftIcon={buttonProps.icon}
                color={buttonProps.color}
                variant={"solid"}
                border={"1px solid"}
                borderColor={"gray.300"}
                bgColor={buttonProps.bg}
                _hover={buttonProps.bg}
                // // borderColor={buttonProps.borderColor}
                fontWeight={500}
                fontSize={"0.9rem"}
                borderRadius={"8px"}
                hidden={!buttonProps.isVisible}
                opacity={!buttonProps.onClick ? 0.5 : 1}
                onClick={buttonProps.onClick}
                isDisabled={!buttonProps.onClick}
                isLoading={buttonProps.loading}
              >
                {t(buttonProps.text)}
              </Button>
            </>
          ))}
        </Grid>
      </Box>

      <Modal size={"6xl"} isOpen={isModalOpen} onClose={toggleModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"1px solid"} borderColor={"gray.300"}>
            Star Vantage Logs
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex my={4} mb={6} w={"100%"}>
              <ApiLogs
                transactionPolicyNo={
                  quoteData.transactionInfo.n_potransaction_PK
                }
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size={"xl"}
        isOpen={requestBindConfirmationModal}
        onClose={() => setRequestBindConfirmationModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"1px solid"} borderColor={"gray.300"}>
            {t("Confirm Bind Quote")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={0}>
              {t("Are you sure you want to request to bind the quote?")}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setRequestBindConfirmationModal(false)}>
              {t("No")}
            </Button>
            <Button
              ml={2}
              onClick={() => {
                requestBind();
                setRequestBindConfirmationModal(false);
              }}
            >
              {t("Yes")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        size={"6xl"}
        isOpen={openRateHistoryModal}
        onClose={() => setOpenRateHistoryModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"1px solid"} borderColor={"gray.300"}>
            {t("Rate History")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex my={4} mb={6} w={"100%"}>
              <RateHistoryModal
                poTransPk={quoteData?.transactionInfo?.n_potransaction_PK}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <PaymentModal
        quoteData={quoteData}
        isBind={isBind}
        isOpen={openCheckModal}
        onClose={() => {
          setOpenCheckModal(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  quoteData: state.common.emptyQuoteData || {},
  getBaseData: state.common.getBaseData || {},
});

export default connect(mapStateToProps)(QuoteButtons);
