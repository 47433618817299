import { useEffect } from "react";
import { useStarDataContext } from "../StarDataContext";

const StarInsTypeKeysMain = {
  FRAME20: "Frame",
  MASONRY20: "Mansory",
  OTHER20: "Frame",
  BRICK20: "Mansory",
  // LOG20: "Log",
  // STUCCO20: "Stucco",
  BRICK: "Mansory",
  FRAME: "Frame",
  // LOG: "Log",
  MASONRY: "Mansory",
  OTHER: "Frame",
  // STUCCO: "Stucco",
  // ASBESTOS: "Asbestos",
  // EIFS: "EIFS",
};

const starRoofTypesMain = {
  TILE: "Clay Tile",
  CONCRETE: "",
  // CONCRETE: "Concrete",
  // COMPOSITION: "Comp / Shingle",
  // "COMP / SHINGLE": "Comp / Shingle",
  // "METAL ROOF": "Metal",
  "METAL ROOF": "Metal Tile",
  METAL: "Metal Tile",
  // MEMBRANE: "Membrane",
  // "POLYURETHANE FOAM": "Polyurethane foam",
  SLATE: "Slate",
  // "TAR AND GRAVEL": "Tar and gravel",
  // "BUILT UP MOPPED": "Built up mopped",
  // SOD: "Sod",
  // "WOOD SHAKE/SHELPLE": "Wood shake/shingle",
  "WOOD SHAKE/SHELPLE": "Wood Shingles",
  // "ANY ROLLED ROOF": "Any rolled roof",
  // "ANY ROLLED ROOF": "Any rolled roof",
  // ASBESTOS: "Asbestos",
  "OTHER PLASTIC": "Other",
  "PLASTIC MEMBRANE": "Other",
  // "FOAM INSULATION": "Polyurethane foam",
  // "GRAVEL ROOF": "Tar and gravel",
  // "MOPPED ROOF": "Built up mopped",
  // "SHAKE ROOF": "Wood shake/shingle",
  // "ROLLED ROOFING": "Any rolled roof",
  // "FIBERGLASS ASBESTOS": "Asbestos",
  "SYNTHETIC PLASTIC": "Other",
  "": "",
};

const StarInsTypeKeys = {
  FRAME20: "Frame",
  MASONRY20: "Masonry",
  OTHER20: "Frame",
  BRICK20: "Masonry",
  LOG20: "",
  STUCCO20: "Frame",
  BRICK: "Masonry",
  FRAME: "Frame",
  LOG: "",
  MASONRY: "Masonry",
  OTHER: "",
  STUCCO: "Frame",
  ASBESTOS: "",
  EIFS: "",
};

const StarCladdingTypeKeys = {
  UNKNOWN: "Unknown",
  CONCRETE: "concrete",
  WOOD: "siding-wood",
  BRICK: "brick",
  ADOBE: "adobe",
  EIFS: "stucco-synthetic eifs",
  STONE: "stone",
  STUCCO: "stucco-authentic",
  "BRICK-VENEER": "brick-veneer",
  "STUCCO-AUTHENTIC": "stucco-authentic",
  "STUCCO-SYNTHETIC EIFS": "stucco-synthetic eifs",
  DRIVET: "drivet",
  "SIDING-CEMENT FIBER(HARDI-PLANK)": "siding-cement fiber(hardi-plank)",
  "SIDING-ALUMINUM": "siding-Aluminum",
  STEEL: "Steel",
  HARDBOARD: "Hardboard",
  "SIDING-VINYL": "siding-vinyl",
  "SIDING-WOOD": "siding-wood",
  CLAPBOARD: "clapboard",
  SHINGLE: "shingle",
  "CONCRETE BLOCK": "concrete block",
  WINDOW: "window",
  "GLASS-STEEL WALL SYSTEM": "glass-steel wall system",
  "SOLID LOG": "solid log",
  // Google's Data, Need to R&D with ~50 Addresses
  //@Author @TheEinshine
  //Quality Change TODO
  MYSTERY: "unknown",
  "SOLID STONE": "concrete",
  "TIMBER SIDING": "siding-wood",
  "BRICK FACADE": "brick",
  "DESERT MUD": "adobe",
  "SYNTHETIC STUCCO": "stucco-synthetic eifs",
  "NATURAL ROCK": "stone",
  "AUTHENTIC PLASTER": "stucco-authentic",
  "BRICK VENEER": "brick-veneer",
  "GENUINE STUCCO": "stucco-authentic",
  "SYNTHETIC STUCCO EIFS": "stucco-synthetic eifs",
  "DRIVET CLADDING": "drivet",
  "CEMENT FIBER SIDING": "siding-cement fiber(hardi-plank)",
  "ALUMINUM SIDING": "siding-Aluminum",
  "STEEL SHELL": "Steel",
  "HARDBOARD CLADDING": "Hardboard",
  "VINYL SIDING": "siding-vinyl",
  "WOODEN SIDING": "siding-wood",
  "CLAPBOARD SIDING": "clapboard",
  "SHINGLE CLADDING": "shingle",
  "CONCRETE BLOCK WALL": "concrete block",
  "WINDOW WALL": "window",
  "GLASS STEEL FACADE": "glass-steel wall system",
  "SOLID LOG_CABIN": "solid log",
  "ALUMINUM OR VINYL SIDING": "siding-Aluminum",
  "FIBER CEMENT SIDING": "siding-cement fiber(hardi-plank)",
  METAL: "Steel",
  "GYPSUM BOARD": "Hardboard",
};

const starUsageKeys = {
  PRIMARY: "Primary",
  SEASONAL: "Seasonal",
  TENANT: "Tenanted",
  RENT: "Rental",
  UNDERCONST: "Vacant / Unoccupied",
  VACATION: "Vacant / Unoccupied",
  SECONDARY: "Secondary",
};
// Do not supress and fill unethical Data, let it be empty if not available in DB
const starFoundationTypeKeys = {
  null: "slab on grade",
  SLAB: "slab on grade",
  PILING: "pillars / stilts over land with finished enclosure",
  PIER: "",
  SHEAR_WALL: "",
  SOLID_WALL: "subgrade",
  FOUNDATION_WALL: "",
  SLAB_FILL: "",
  BLOCK: "basement",
  RAISED: "",
  PIERS: "pillars / stilts over land with finished enclosure",
  FOOTING: "slab on grade",
  WOOD: "subgrade",
};
const starRoofTypes = {
  CONCRETE: "Other",
  COMPOSITION: "3-tab Shingles",
  "COMP / SHINGLE": "Architectural Shingles",
  "METAL ROOF": "Metal Tile",
  MEMBRANE: "Other",
  "POLYURETHANE FOAM": "Other",
  SLATE: "Slate",
  "TAR AND GRAVEL": "Other",
  "BUILT UP MOPPED": "Other",
  SOD: "Other",
  "WOOD SHAKE/SHELPLE": "Wood Shingles",
  "ANY ROLLED ROOF": "Other",
  ASBESTOS: "Other",
  OTHER_PLASTIC: "Other",
  PLASTIC_MEMBRANE: "Other",
  "FOAM INSULATION": "Other",
  "GRAVEL ROOF": "Other",
  "MOPPED ROOF": "Other",
  "SHAKE ROOF": "Wood Shingles",
  "ROLLED ROOFING": "Other",
  "FIBERGLASS ASBESTOS": "Other",
  SYNTHETIC_PLASTIC: "Other",
};

const waterTypeMapping = {
  null: "No",
  PRIVATE: "No",
  YES: "Entirely",
  ENTIRE: "Entirely",
  NO: "No",
  NONE: "No",
  PARTIAL: "Partially",
  PART: "Partially",
};

const useUpdateConstructionDetails = () => {
  const {
    autoGenData,
    setUnderWriting,
    setConfirmation,
    setRoofProperties,
    setCoverages,
    setHasMortagee,
    setMortgagees,
  } = useStarDataContext();

  function findMatchingKey(inputString, mappingObject) {
    if (!inputString && typeof inputString !== "string") {
      return "";
    }
    const lowerCaseInput = inputString?.toLowerCase();
    for (const key in mappingObject) {
      if (lowerCaseInput.includes(key?.toLowerCase())) {
        return mappingObject[key];
      }
    }
    return "";
  }

  function splitAndTrim(inputString) {
    if (typeof inputString !== "string") {
      return [];
    }
    return inputString.split(/[;]/).map((part) => part.trim());
  }

  function identifyType(name) {
    if (!name || typeof name !== "string") return "individual";
    const cleanedName = name.replace(/;/g, " ").toLowerCase();
    if (cleanedName.includes("trust")) {
      return "individual";
    } else if (
      cleanedName.includes("llc") ||
      cleanedName.includes("limited liability company")
    ) {
      return "llc";
    } else if (
      cleanedName.includes("corp") ||
      cleanedName.includes("corporation") ||
      cleanedName.includes("inc")
    ) {
      return "corporation";
    } else if (
      cleanedName.includes("organization") ||
      cleanedName.includes("org")
    ) {
      return "organization";
    } else {
      return "individual";
    }
  }

  useEffect(() => {
    const fetchAndUpdateUnderWriting = async () => {
      if (!autoGenData || Object.keys(autoGenData || {}).length === 0) {
        return;
      }

      try {
        const { EastedData, PropDetails } = autoGenData;
        const { data = {}, structure = {} } = EastedData || {};
        const { deeds = [], owner = {} } = data;
        let buyersFirstName =
          Array.isArray(deeds) && deeds.length > 0 && deeds[0].buyer_first_name
            ? deeds[0].buyer_first_name
            : "";
        let buyersLastName =
          Array.isArray(deeds) && deeds.length > 0 && deeds[0].buyer_first_name
            ? deeds[0].buyer_last_name
            : "";
        let secondaryBuyersFirstName =
          Array.isArray(deeds) &&
          deeds.length > 0 &&
          deeds[0].buyer2_first_name;
        let secondaryBuyersLastName =
          Array.isArray(deeds) && deeds.length > 0 && deeds[0].buyer2_last_name;
        const ownersFullString = splitAndTrim(owner.name);
        // Re Enable this to get dynamic RCV (Easted) (Disabled by ODS-5507)
        
        // setMortgageeDetails(deeds, setMortgagees, setHasMortagee);
        // if (PropDetails?.RCV && typeof PropDetails.RCV === "number") {
        //   const formattedCost = parseFloat(PropDetails.RCV.toFixed(2));
        //   setCoverages((prevCoverages) => ({
        //     ...prevCoverages,
        //     basicCoverages: {
        //       ...prevCoverages.basicCoverages,
        //       costToRebuildHome: formattedCost,
        //     },
        //   }));
        // }      
        const allInsuredsObject = () => {
          let primaryInsuredFirstName = "";
          let primaryInsuredLastName = "";
          let secondaryInsuredFirstName = "";
          let secondaryInsuredLastName = "";

          if (ownersFullString.length > 1) {
            primaryInsuredFirstName = separateNameFromFullName(
              ownersFullString[0]
            ).firstName;
            primaryInsuredLastName = separateNameFromFullName(
              ownersFullString[0]
            ).lastName;
            secondaryInsuredFirstName = separateNameFromFullName(
              ownersFullString[1]
            ).firstName;
            secondaryInsuredLastName = separateNameFromFullName(
              ownersFullString[1]
            ).lastName;
          } else if (ownersFullString.length === 1) {
            primaryInsuredFirstName = separateNameFromFullName(
              ownersFullString[0]
            ).firstName;
            primaryInsuredLastName = separateNameFromFullName(
              ownersFullString[0]
            ).lastName;
          } else {
            primaryInsuredFirstName = "";
            primaryInsuredLastName = "";
            secondaryInsuredFirstName = "";
            secondaryInsuredLastName = "";
          }
          return {
            primaryInsuredFirstName,
            primaryInsuredLastName,
            secondaryInsuredFirstName,
            secondaryInsuredLastName,
          };
        };

        const roofAge = PropDetails?.ACT_YR_BLT
          ? new Date().getFullYear() - PropDetails.ACT_YR_BLT
          : null;
        const roofMaterialUsed =
          starRoofTypes[structure.roof_material_type] ||
          starRoofTypes[autoGenData.RoofType] ||
          "";

        setConfirmation((prevConfirmation) => ({
          ...prevConfirmation,
          additionalInsured: [
            {
              ...prevConfirmation.additionalInsured[0],
              firstName:
                secondaryBuyersFirstName ||
                allInsuredsObject().secondaryInsuredFirstName,
              lastName:
                secondaryBuyersLastName ||
                allInsuredsObject().secondaryInsuredLastName,
            },
          ],
          additionalInsuredFlag:
            secondaryBuyersFirstName || secondaryBuyersLastName ? true : false,
        }));

        setRoofProperties((prevRoofProperties) => ({
          ...prevRoofProperties,
          roofAge,
          roofMaterialUsed,
        }));

        setUnderWriting((prevUnderWriting) => ({
          ...prevUnderWriting,
          propertyDetails: {
            ...prevUnderWriting?.propertyDetails,
            firstName:
              buyersFirstName || allInsuredsObject().primaryInsuredFirstName,
            lastName:
              buyersLastName || allInsuredsObject().primaryInsuredLastName,
            numberOfFloors: getNumberOfStories(
              autoGenData?.PropDetails?.NO_STORIES
            ),
            customerType: identifyType(ownersFullString),
            buissnessName: Array.isArray(ownersFullString)
              ? ownersFullString[0]
              : "" || "",
            yearBuilt: autoGenData?.PropDetails?.ACT_YR_BLT
              ? Number(autoGenData?.PropDetails?.ACT_YR_BLT)
              : "",
            squareFootage: autoGenData?.PropDetails?.TOT_LVG_AR || "",
            constructionType:
              StarInsTypeKeys[autoGenData?.ConstructionType] ||
              StarInsTypeKeys[
                autoGenData?.EastedData?.data?.structure?.construction_type
              ] ||
              "",
            usage: starUsageKeys[autoGenData?.Usage] || "",
            foundationType:
              starFoundationTypeKeys[
                autoGenData?.EastedData?.data?.structure?.foundation_type
              ] || "",
            buildingWaterType:
              waterTypeMapping[
                autoGenData?.EastedData?.data?.structure?.water_type
              ] || "",
            dwellingStories: getNumberOfStories(
              autoGenData?.PropDetails?.NO_STORIES
            ),
            claddingType:
              findMatchingKey(
                autoGenData?.EastedData?.data?.structure?.exterior_wall_type,
                StarCladdingTypeKeys
              ) ||
              findMatchingKey(
                autoGenData?.EastedData?.data?.structure?.interior_wall_type,
                StarCladdingTypeKeys
              ) ||
              "",
          },
        }));
      } catch (error) {
        console.error("Error retrieving applicant details:", error);
      }
    };

    fetchAndUpdateUnderWriting();
  }, [autoGenData]);

  function separateNameFromFullName(fullName) {
    if (typeof fullName !== "string") {
      return { firstName: "", lastName: "" };
    }
    const wordsIncluded = fullName.trim().split(/[\s,]+/).length;
    const splittedName = fullName.trim().split(/[\s,]+/);
    let firstName = "";
    let lastName = "";
    if (wordsIncluded === 1) {
      firstName = splittedName[0];
    } else if (wordsIncluded === 2) {
      firstName = splittedName[0];
      lastName = splittedName[1];
    } else if (wordsIncluded === 3) {
      firstName = splittedName[0] + " " + splittedName[1];
      lastName = splittedName[2];
    } else if (wordsIncluded === 4) {
      firstName = splittedName[0] + " " + splittedName[1];
      lastName = splittedName[2] + " " + splittedName[3];
    } else if (wordsIncluded === 5) {
      firstName =
        splittedName[0] + " " + splittedName[1] + " " + splittedName[2];
      lastName = splittedName[3] + " " + splittedName[4];
    }
    return { firstName, lastName };
  }

  const setMortgageeDetails = (deeds, setMortgagees, setHasMortagee) => {
    if (
      Array.isArray(deeds) &&
      deeds.length > 0 &&
      deeds[0].lender_name &&
      typeof deeds[0].lender_name === "string" &&
      typeof deeds[0].loan_type === "string"
    ) {
      setMortgagees((prevMortgagees) => ({
        ...prevMortgagees,
        bankName: `${deeds[0].lender_name} (${deeds[0].loan_type})` || "",
      }));
      setHasMortagee(true);
    }
  };

  const getNumberOfStories = (stories) => {
    try {
      const numberPart = Number(stories);
      return numberPart > 4
        ? 4
        : !isNaN(numberPart) && numberPart > 0
        ? numberPart
        : 1;
    } catch (error) {
      console.error("Error occurred while parsing the number:", error);
      return 1;
    }
  };
};

export default useUpdateConstructionDetails;
