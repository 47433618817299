import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Select,
  Text,
} from "@chakra-ui/react";
import { useStarDataContext } from "../../StarDataContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { MdOutlineAddBox } from "react-icons/md";
import { FcInfo } from "react-icons/fc";
import ReactTooltip from "react-tooltip";

export const Deductibles = () => {
  const { t } = useTranslation();
  const { coverages, setCoverages, baseDD, validationError, stateCodeVal } =
    useStarDataContext();
  const [hurricaneData] = useState(baseDD?.deductibleDisc?.hurricaneDD ?? {});
  const [nonHurricaneData] = useState(
    baseDD?.deductibleDisc?.nonHurricaneDD ?? {}
  );
  const [scsDeductibleData] = useState(baseDD?.STARSCS ?? []);

  const aopDeductibles = [
    { value: 0.005, title: "5%" },
    { value: 0.01, title: "10%" },
    { value: 0.02, title: "20%" },
    { value: 0.03, title: "30%" },
    { value: 0.04, title: "40%" },
    { value: 0.05, title: "50%" },
  ];

  const windHailDeductibles = [
    { value: 0.005, title: "5%" },
    { value: 0.01, title: "10%" },
    { value: 0.02, title: "20%" },
    { value: 0.03, title: "30%" },
    { value: 0.04, title: "40%" },
    { value: 0.05, title: "50%" },
  ];

  const renderTootTip = (id) => {
    return (
      <>
        <ReactTooltip id={id} className={id && "custom-tooltip"}>
          <div>
            {id === "HurricaneDeductible" && (
              <>
                {coverages?.deductibles?.isCountyRisky === true ? (
                  <Box fontSize={"15px"}>
                    {`Your policy covers losses to your property from a hurricane,
                    but hurricane-related claims have their own deductible. This
                    is the amount you have to pay before we can pay for a
                    covered loss. You only have to pay the deductible once in a
                    policy year, even if there are several hurricanes. With us, 
                    your hurricane deductible is 5% of your home rebuilding cost,
                    but it's also a Smart Deductible, so you get a growing
                    amount of cash back every year you don't have a claim`}
                  </Box>
                ) : (
                  <Box fontSize={"15px"}>
                    {`Your policy covers losses to your property from a hurricane,
                    but hurricane-related claims have their own deductible. This
                    is the amount you have to pay before we can pay for a
                    covered loss. You only have to pay the deductible once in a
                    policy year, even if there are several hurricanes. With us,
                    your hurricane deductible is 2% of your home rebuilding
                    cost, but it's also a Smart Deductible, so you get a growing
                    amount of cash back every year you don't have a claim`}
                  </Box>
                )}
              </>
            )}
            {id === "NonHurricaneDeductible"  && (
              <Box fontSize={"15px"}>
                This deductible applies to losses that are not related to
                hurricanes. It’s the amount you pay before we pay for a covered
                loss. It applies to all of your coverages unless otherwise
                specified. (Liability coverages don’t have a deductible.) Unlike
                the hurricane deductible, you pay this one on each claim you
                file. The options we have provided are standard. Tip: Consider
                how much you could afford to pay in an emergency. The higher the
                deductible, the lower your premium.
              </Box>
            )}
          </div>
        </ReactTooltip>
        <style>
          {`
      .custom-tooltip {
        width: 500px;
        height: auto;
      }
    `}
        </style>
      </>
    );
  };
  return (
    <>
      <Box p={2} py={5}>
        <Grid
          templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
          columnGap={8}
        >
          <GridItem>
            <FormControl isRequired>
              <Flex alignItems={"center"}>
                <FormLabel fontSize={"15px"}>
                  {t("Non Hurricane Deductible (AOP)")}{" "}
                </FormLabel>
                <a
                  style={{
                    marginBottom: "10px",
                    marginLeft: "-10px",
                    cursor: "pointer",
                  }}
                  data-tip
                  data-for={"NonHurricaneDeductible"}
                >
                  {" "}
                  <Box ml={1}>
                    <FcInfo size={18} />
                  </Box>
                  {renderTootTip("NonHurricaneDeductible")}
                </a>
              </Flex>
              <Select
                style={{
                  height: "54px",
                  border: "2px solid var(--chakra-colors-neutral-500)",
                  borderRadius: "9px",
                }}
                value={coverages?.deductibles?.aop || ""}
                onChange={(e) =>
                  setCoverages({
                    ...coverages,
                    deductibles: {
                      ...coverages?.deductibles,
                      aop: e.target.value,
                    },
                  })
                }
              >
                <option value=""></option>
                {Object.values(nonHurricaneData).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
              {validationError.coverages && !coverages?.deductibles?.aop && (
                <Text color={"red"}>
                  {t("Non Hurricane Deductible are required")}.
                </Text>
              )}
            </FormControl>
          </GridItem>
          {stateCodeVal !== "ms" && (
            <GridItem>
              <FormControl isRequired>
                <FormLabel>{t("SCS Deductible")}</FormLabel>
                <Select
                  style={{
                    height: "54px",
                    border: "2px solid var(--chakra-colors-neutral-500)",
                    borderRadius: "9px",
                  }}
                  value={coverages?.deductibles?.scs || ""}
                  onChange={(e) => {
                    setCoverages({
                      ...coverages,
                      deductibles: {
                        ...coverages?.deductibles,
                        scs: e.target.value,
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {scsDeductibleData.map((item) => (
                    <option key={item.s_AppCodeName} value={item.s_AppCodeName}>
                      $
                      {Intl.NumberFormat("en-US", {
                        currency: "USD",
                      }).format(item.s_AppCodeNameForDisplay)}
                    </option>
                  ))}
                </Select>
                {validationError.coverages && !coverages?.deductibles?.scs && (
                  <Text color={"red"}>{t("SCS Deductible are required")}.</Text>
                )}
              </FormControl>
            </GridItem>
          )}
          <GridItem>
            <FormControl isRequired>
              <Flex alignItems={"center"}>
                <FormLabel>{t("Wind / Hail Hurricane Deductible")}</FormLabel>
                <a
                  style={{
                    marginBottom: "10px",
                    marginLeft: "-10px",
                    cursor: "pointer",
                  }}
                  data-tip
                  data-for={"HurricaneDeductible"}
                >
                  {" "}
                  <Box ml={1}>
                    <FcInfo size={18} />
                  </Box>
                  {renderTootTip("HurricaneDeductible")}
                </a>
              </Flex>
              <Select
                style={{
                  height: "54px",
                  border: "2px solid var(--chakra-colors-neutral-500)",
                  borderRadius: "9px",
                }}
                value={coverages?.deductibles?.hurricaneDeductible || ""}
                onChange={(e) => {
                  setCoverages({
                    ...coverages,
                    deductibles: {
                      ...coverages?.deductibles,
                      hurricaneDeductible: e.target.value,
                    },
                  });
                }}
              >
                <option value=""></option>
                {coverages?.deductibles?.isCountyRisky ? (
                  <option value="HURRA05P">5% Ded</option>
                ) : (
                  // stateCodeVal === "ms"
                  //   ? windHailDeductibles?.map((item) => (
                  //     <option key={item.title} value={item.value}>
                  //       {item.title}
                  //     </option>
                  //   ))
                  //   :
                  Object.entries(hurricaneData).map(([key, item]) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))
                )}
              </Select>
              {validationError.coverages &&
                !coverages?.deductibles?.hurricaneDeductible && (
                  <Text color={"red"}>
                    {t("Hail Hurricane Deductible are required")}.
                  </Text>
                )}
            </FormControl>
          </GridItem>
          {stateCodeVal !== "ms" && (
            <GridItem>
              <FormControl>
                <FormLabel mb={"4px"}>
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    Roof Deductibles?{" "}
                    {coverages?.deductibles?.roof ? "(Included)" : ""}
                    <Checkbox
                      icon={<MdOutlineAddBox size={20} />}
                      size="lg"
                      colorScheme="green"
                      isChecked={coverages?.deductibles?.roof ? true : false}
                      onChange={(e) => {
                        setCoverages({
                          ...coverages,
                          deductibles: {
                            ...coverages?.deductibles,
                            roof: e.target.checked ? "5000" : "",
                          },
                        });
                      }}
                      pt={"3px"}
                      pl={2}
                    />
                  </Flex>
                </FormLabel>
                <CurrencyInput
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  className="coverageSelectField"
                  prefix="$"
                  placeholder="$5,000"
                  disabled={true}
                  size="lg"
                  style={{
                    textAlign: "right",
                    border: "2px solid",
                    padding: "1.57rem",
                    borderRadius: "10px",
                    borderColor: "var(--chakra-colors-neutral-500)",
                    width: "100%",
                    backgroundColor: coverages?.deductibles?.roof
                      ? "var(--chakra-colors-neutral-100)"
                      : "white",
                  }}
                  value={coverages?.deductibles?.roof || ""}
                  onChange={(e) => {
                    setCoverages({
                      ...coverages,
                      deductibles: {
                        ...coverages?.deductibles,
                        roof: e.target.value.replace(/[^\d.]/g, ""),
                      },
                    });
                  }}
                />
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Box>
    </>
  );
};
