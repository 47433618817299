import React, { useState, useEffect, Fragment } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Col, Table, Button, Form, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import CommonHeader from "./CommonHeader";
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Select from "react-select";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { getZipDetails, saveMortgageeData, deleteMortgagee } from "../../../services/floodService";
import ZipDetailsView from '../../../../src/pages/quote/flood/quote/Modal/ZipDetailsView';
import { deletePolicyInterest } from "../../../services/quoteService";
import {mortgageeData as mortgageeDataApi} from "../../../services/coreSystemService";
import "./policy-details.scss";

const AdditionalInfoTab = (props) => {
  const { poTransPK, policyNumber, policyPK} = props;
  const dispatch = useDispatch();
  const [applicantData, setApplicantData] = useState({});
  const [additionalInfoData, setAdditionalInfoData] = useState({});
  const [household, setHoushold] = useState([]);
  const [additionalInterest, setAdditionalInterest] = useState([]);
  const [mortgagees, setMortgagees] = useState([]);
  const [priorPolicyLoss, setPriorPolicyLoss] = useState([]);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showMortgageeSearchModal, setShowMortgageeSearchModal] = useState(false);
  const { apiLoading } = useSelector(state => state.flood);
  const initOptions = [
    { value: "PRIMARY", label: t("First Mortgagee") },
    { value: "SECOND", label: t("Second Mortgagee") },
    { value: "LOSSPAYEE", label: t("Loss Payee") },
    { value: "DISASTER", label: t("Disaster") }
  ];
  const mortgageeJson = {
    "n_PolicyAddInterest_PK": null,
    "n_POTransaction_FK": poTransPK ?? null,
    "policyNumber": policyNumber ?? null,
    "policyPK": policyPK ?? null,
    "n_PersonInfoId_PK": null,
    "s_PersonType": null,
    "s_MortgageeType": null,
    "s_LoanNo": null,
    "s_AddionalIntType": null,
    "s_RelationType": null,
    "s_HouseholdType": null,
    "s_isDeleted": "N",
    "n_CorePersonPK": null,
    "n_CorePersonAddressPK": null,
    "n_CreatedUser": null,
    "d_CreatedDate": null,
    "n_UpdatedUser": null,
    "d_UpdatedDate": null,
    "person": {
      "n_PersonInfoId_PK": null,
      "s_PersonUniqueId": null,
      "s_EntityType": "ORGANISATION",
      "s_FirstName": null,
      "s_MiddleName": null,
      "s_LastOrganizationName": null,
      "s_FullLegalName": null,
      "d_BirthDate": null,
      "s_Occupation": null,
      "s_IsMailingDiff": null,
      "s_IsTempMailing": null,
      "n_CreatedUser": null,
      "d_CreatedDate": null,
      "n_UpdatedUser": null,
      "d_UpdatedDate": null
    },
    "address": {
      "n_PersonAddressesId_PK": null,
      "n_PersonInfoId_PK": null,
      "s_AddressTypeCode": "MAILING",
      "s_IsDefaultAddress": "Y",
      "s_AddressLine1": null,
      "s_AddressLine2": null,
      "n_CountyId_FK": null,
      "n_CityId_FK": null,
      "n_StateId_FK": null,
      "n_CountryId_FK": null,
      "s_PostalCode": null,
      "s_HouseNo": null,
      "s_StreetName": null,
      "s_PostalCodeSuffix": null,
      "s_Latitude": null,
      "s_Longitude": null,
      "s_CountryName": null,
      "s_StateName": null,
      "s_CountyName": null,
      "s_CityName": null,
      "s_ParcelId": null,
      "n_CreatedUser": null,
      "d_CreatedDate": null,
      "n_UpdatedUser": null,
      "d_UpdatedDate": null
    }
  }
  const [mortgageeData, setMortgageeData] = useState(mortgageeJson);
  const [selectInterest, setSelectInterest] = useState(initOptions[0]);
  const [interestOptions, setIntrestOptions] = useState([]);
  const [mortData, setMortData] = useState(false);
  const [streetName, setStreetName] = useState('');
  const [isZipSet, setIsZipSet] = useState(false);
  const [multipleCity, setMultipleCity] = useState(null);
  const [showZipModal, setShowZipModal] = useState(false);
  const [showMultipleCity, setShowMultipleCity] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [isValidate, setIsValidate] = useState(true);
  const [loanNo, setLoanNo] = useState('');
  const [search, setSearchProperty] = useState(null);
  const [googlePlace, setGooglePlace] = useState('');

  useEffect(() => {
    setApplicantData(props.applicationData)
  }, [props.applicationData]);

  useEffect(() => {
    setAdditionalInfoData(props.additionalData)
  }, [props.additionalData]);

  useEffect(() => {
    setMortgagees(props.mortgageeData)
  }, [props.mortgageeData]);

  useEffect(() => {
    setHoushold(props.householdData)
  }, [props.householdData]);

  useEffect(() => {
    setAdditionalInterest(props.addiIntData)
  }, [props.addiIntData]);

  useEffect(() => {
    setPriorPolicyLoss(props.priorPolicyLossData)
  }, [props.priorPolicyLossData]);

  const columnFormatter = (cell, row) => {
    if (props.applicationData.s_TranTypeScreenName == "Renewal" && props.applicationData.s_TransactionCycleCode == "QUOTE") {
      return (
        <div style={{width: "100%"}}>
				<Button id="common-btn" name="actionNameData"  style ={{ padding: "6px 10px", margin: 'auto' }} onClick={(e) => handleRemoveFields(cell, row)}><i className='fa fa-trash' /></Button>
			</div>
      );
    }
  };

  const mortageColumns = [
    {
      dataField: "s_PoMortgageeTypeCode",
      text: t("TYPE"),
      sort: true,
    },
    {
      dataField: "s_PersonUniqueId",
      text: t("CLIENT ID"),
      sort: true,
    },
    {
      dataField: "s_LoanNumber",
      text: t("LOAN NUMBER"),
      sort: true,
    },
    {
      dataField: "s_FullLegalName",
      text: t("MORTGAGEES NAME"),
      sort: true,
    },
    {
      dataField: "address",
      text: t("MORTGAGEES ADDRESS LOAN"),
      sort: true,
    },
    {
      dataField: "n_POMortgageePerson_Fk",
      text: (props.applicationData.s_TranTypeScreenName == "Renewal" && props.applicationData.s_TransactionCycleCode == "QUOTE") ? "DELETE" : "",
      formatter: (value, row) => columnFormatter(value, row),
      sort: (props.applicationData.s_TranTypeScreenName == "Renewal" && props.applicationData.s_TransactionCycleCode == "QUOTE") ? true : false,
    },
  ];

  const additionalColumns = [
    {
      dataField: "s_PartyInterestCode",
      text: t("INTEREST"),
      sort: true,
    },
    {
      dataField: "s_FullLegalName",
      text: t("INTEREST NAME"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "ACTION",
    //   sort: true,
    // },
  ];

  const householdColumns = [
    {
      dataField: "s_POApplicantTypeCode",
      text: t("RELATION"),
      sort: true,
    },
    {
      dataField: "s_PersonRoleType",
      text: t("TYPE"),
      sort: true,
    },
    {
      dataField: "s_FirstName",
      text: t("FIRST"),
      sort: true,
    },
    {
      dataField: "s_LastOrganizationName",
      text: t("LAST"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "ACTION",
    //   sort: true,
    // },
  ];

  const priorPolicyLossColumns = [
    {
      dataField: "d_LossDate",
      text: t("LOSS DATE"),
      sort: true,
    },
    {
      dataField: "s_LossType",
      text: t("LOSS TYPE"),
      sort: true,
    },
    {
      dataField: "s_LossDescription",
      text: t("LOSS DESC"),
      sort: true,
    },
    {
      dataField: "s_LossCatNo",
      text: t("LOSS CATNO"),
      sort: true,
    },
    {
      dataField: "n_LossAmount",
      text: t("LOSS AMT"),
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "ACTION",
    //   sort: true,
    // },
  ];

  // get zip details 
const getZipData = (zip) => {
  if (zip !== '') {
    dispatch(getZipDetails('10', zip, 'APPLICANT')).then((res) => {
      if (res.success === true) {
        var data = res.data;
        if (data.status === 'Y') {
          if (data.count === 1) {
            setZipDetailsData(res.data.zipData[0]);
          } else {
            showMultipleCityModal(data.zipData);
          }
        } else {
          toast.error(res.errorMsg);
          mortgageeData.address.s_CityName = '';
          mortgageeData.address.s_StateName = '';
        }
      }
    });
  } else {
    toast.error('Zip Code should not be empty!');
  }
}

// open Zip Modal
const showMultipleCityModal = (zipData) => {
  setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
  setShowZipModal(true);
  setShowMultipleCity(true);
}

// get selected zip data
const setZipDetailsData = (zipData) => {
  const values = mortgageeData;
  values.address.s_PostalCode = zipData.s_ZipCode;
  values.address.n_Zipcodes_PK = zipData.n_Zipcodes_PK;
  values.address.s_StateName = zipData.s_StateCode;
  values.address.n_CityId_FK = zipData.n_CityId_PK;
  values.address.n_CountyId_FK = zipData.n_CountyId_FK;
  values.address.n_StateId_FK = zipData.n_StateId_PK;
  values.address.n_CountryId_FK = zipData.n_CountryId_FK;
  values.address.s_CityName = zipData.s_CityName;
  setMortgageeData(values);
  setIsZipSet(!isZipSet);
  setShowZipModal(false);
  setShowMultipleCity(false)
}

  useEffect(() => {
    if (!show) {
      setLoanNo('')
      setMortgageeData(mortgageeJson);
      setSearchProperty(null)
      setStreetName(null)
      setGooglePlace(null)
    }
  }, [show]);

  useEffect(() => {
    let selectedInterestOptions = [];
    if (props.mortgageeData.length === 0) {
      selectedInterestOptions = initOptions.filter(item => item.value !== 'SECOND');
    } else {
      props.mortgageeData.filter(function (val) {
        if (val.s_PoMortgageeTypeCode === 'PRIMARY') {
          selectedInterestOptions = initOptions.filter(item => item.value !== 'PRIMARY');
        }
        if (val.s_PoMortgageeTypeCode === 'SECOND') {
          selectedInterestOptions = selectedInterestOptions.filter(item => item.value !== 'SECOND');
        }
        if (selectedInterestOptions.length == 0) {
          selectedInterestOptions = initOptions.filter(item => item.value !== 'SECOND');
        }
        return selectedInterestOptions;
      });
    }
    setIntrestOptions(selectedInterestOptions);
    setSelectInterest(selectedInterestOptions[0]);
  }, [show, props.mortgageeData]);

  const inputChange = (event) => {
    let tempData = mortgageeData;

    const { name, value, attributes } = event.target;
    const parentKey = attributes.parentobject.value;
    if (parentKey.length > 0) {
      tempData[parentKey][name] = value;
    } else {
      tempData[name] = value;
    }
    tempData.s_PersonType = selectInterest?.value;
    tempData.s_MortgageeType = selectInterest?.label;
    setMortData(true);
    setMortgageeData({...tempData});
  };

  const validation = () => {    
    if (([null, '', undefined].includes(mortgageeData.person.s_FullLegalName) || isEmpty(mortgageeData.person.s_FullLegalName)) && mortgageeData.s_PersonType !== "DISASTER") {
      toast.error("Enter Mortgagee Name");
      setIsValidate(false);
      setEnableSave(false);
    }
    else if (mortgageeData.s_PersonType == "DISASTER" && ([null, '', undefined].includes(mortgageeData.person.s_FirstName) || isEmpty(mortgageeData.person.s_FirstName))) {
      toast.error("Enter First Name");
        setIsValidate(false);
        setEnableSave(false);
    }
    else if (mortgageeData.s_PersonType == "DISASTER" && ([null, '', undefined].includes(mortgageeData.person.s_LastOrganizationName) || isEmpty(mortgageeData.person.s_LastOrganizationName))) {
      toast.error("Enter Last Name");
      setIsValidate(false);
      setEnableSave(false);
    }
    else if ([null, '', undefined].includes(mortgageeData.address.s_AddressLine1) || isEmpty(mortgageeData.address.s_AddressLine1)) {
      toast.error("Enter Mailing Address 1");
      setIsValidate(false);
      setEnableSave(false);
    }
    else if ([null, '', undefined].includes(mortgageeData.address.s_CityName) || [null, '', undefined].includes(mortgageeData.address.s_StateName) || [null, '', undefined].includes(mortgageeData.address.s_PostalCode)) {
      toast.error("Invalid Zip");
      setIsValidate(false);
      setEnableSave(false);
    }
    else if ([null, '', undefined].includes(mortgageeData.s_LoanNo) || isEmpty(mortgageeData.s_LoanNo)) {
      toast.error("Enter Loan Number");
      setIsValidate(false);
      setEnableSave(false);
    }
    else {
      setIsValidate(true);
      setEnableSave(true);
      handleSaveInterest();
    }
  };

  const handleSaveInterest = async () => {
    if (selectInterest?.value === 'DISASTER') {
      var fullName = mortgageeData.person.s_FirstName || '';
      if (fullName && fullName !== '') {
        if (mortgageeData.person.s_LastOrganizationName && mortgageeData.person.s_LastOrganizationName !== '') {
          fullName += ' ';
          fullName += mortgageeData.person.s_MiddleName || '';
        }
        fullName += ' ';
        fullName += mortgageeData.person.s_LastOrganizationName || '';
      }
    
      mortgageeData.person.s_FullLegalName = fullName;
      mortgageeData.s_PersonType = selectInterest.value;
      mortgageeData.s_MortgageeType = selectInterest.label;
    }

    await dispatch(saveMortgageeData(mortgageeData)).then((res) => {
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    })

    await dispatch(mortgageeDataApi(mortgageeData.n_POTransaction_FK));

    setShow(false);
    setShowMortgageeSearchModal(false);
  };

  const handleRemoveFields = async (value, row) => {
    if (await deleteData(value)) {
      setMortgageeData(mortgageeJson);
    }
  };

  const deleteData = async (personPK) => {
    if (personPK) {
      const params = {
        mortgageePersonPK: personPK,
      }
      var res = await dispatch(deleteMortgagee(params));
      if (res.success && res.data.Status === 'Y') {
        toast.success('Entry Deleted.');
        return true;
      }
    } else {
      return true;
    }
    await dispatch(mortgageeDataApi(mortgageeData.n_POTransaction_FK));
  }

  return (
    <Row>
      {/* <Col xs="12" className="billing-tab-row1">
        <CommonHeader />
      </Col> */}

      {/* <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Claim Information')}</span>
          </div>

          <Row
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(27, 43, 64, 0.2)",
              padding: "32px",
            }}
          >
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Total Claim')}</th>
                    <td>{Object.keys(applicantData).length > 0 && applicantData.totalClaims}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th> {t('Total Payment')}</th>
                    <td>{Object.keys(applicantData).length > 0 && applicantData.totalPayment}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Col> */}
      {props.applicationData.s_ProductCode !== "HiscoxFloodPlus" && (
  <Col xs="12" className="billing-tab-row2">
  <div id="dashboard-table-section">
    <div className="table-main-title-div">
      <span className="table-main-title-name">
        {t('Prior Insurance Information')}
      </span>
    </div>

    <Row
      style={{
        backgroundColor: "#fff",
        border: "1px solid rgba(27, 43, 64, 0.2)",
        padding: "32px",
      }}
    >
      <Col xs="12" sm="12" md="6" lg="4" xl="4">
        <Table borderless className="policy-details-info-table">
          <tbody>
            <tr>
              <th>{t('Prior Carrier Name')}</th>
              <td>{Object.keys(additionalInfoData).length > 0 && additionalInfoData.s_PriorCarrierName}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col xs="12" sm="12" md="6" lg="4" xl="4">
        <Table borderless className="policy-details-info-table">
          <tbody>
            <tr>
              <th>{t('Prior Policy No')}</th>
              <td>{Object.keys(additionalInfoData).length > 0 && additionalInfoData.s_PriorPolicyNo}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col xs="12" sm="12" md="6" lg="4" xl="4">
        <Table borderless className="policy-details-info-table">
          <tbody>
            <tr>
              <th>{t('Policy Exp. Date')}</th>
              <td>{Object.keys(additionalInfoData).length > 0 && additionalInfoData.d_PolicyExpirationDate}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  </div>
</Col>

      )}
      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t('Mortgagees Information')}
            </span>
            {(props.applicationData.s_TranTypeScreenName == "Renewal" && props.applicationData.s_TransactionCycleCode == "QUOTE") ?
            <>
              <div className="add-mortage-add-btn">
                <Button
                  id="common-outline-btn"
                  variant="outline-primary"
                  onClick={() => {
                    setShow(true);
                  }}
                  style={{
                    backgroundColor: show === true && "white",
                    color: show === true && "#64d6cd",
                  }}
                >
                  <span> + </span> {t('Add Row')}
                </Button>{" "}
              </div>
            </> : null
            }
            
          </div>
          <BootstrapTable
            wrapperClasses="policy-detail-table-main pdtmm"
            bootstrap4
            keyField="s_PoMortgageeTypeCode"
            data={mortgagees}
            columns={mortageColumns}
            hover
            bordered={false}
          />
        </div>
      </Col>
      {props.applicationData.s_ProductCode !== "HiscoxFloodPlus" && (
        <Col xs="12" className="billing-tab-row2">
          <div id="dashboard-table-section">
            <div className="table-main-title-div">
              <span className="table-main-title-name">{t('Household Members')}</span>
            </div>
            <BootstrapTable
              wrapperClasses="policy-detail-table-main pdtmm"
              bootstrap4
              keyField="relation"
              data={household}
              columns={householdColumns}
              hover
              bordered={false}
            />
          </div>
        </Col>
      )
      }
      {/* {props.applicationData.s_ProductCode !== "HiscoxFloodPlus" && ( */}
        <Col xs="12" className="billing-tab-row2">
          <div id="dashboard-table-section">
            <div className="table-main-title-div">
              <span className="table-main-title-name">{t('Additional Interests')}</span>
            </div>
            <BootstrapTable
              wrapperClasses="policy-detail-table-main pdtmm"
              bootstrap4
              keyField="relation"
              data={additionalInterest}
              columns={additionalColumns}
              hover
              bordered={false}
            />
          </div>
        </Col>
      {/* )} */}


      {props.applicationData.s_ProductCode !== "HiscoxFloodPlus" && (
        <Col xs="12" className="billing-tab-row2">
          <div id="dashboard-table-section">
            <div className="table-main-title-div">
              <span className="table-main-title-name">{t('List all losses in the last 5 years for all members of the household at any location')}</span>
            </div>
            <BootstrapTable
              wrapperClasses="policy-detail-table-main pdtmm"
              bootstrap4
              keyField="relation"
              data={priorPolicyLoss}
              columns={priorPolicyLossColumns}
              hover
              bordered={false}
            />
          </div>
        </Col>
      )}

      {show && (
        <Modal size="lg" show={show} className="add-mortage-modal">
          <Modal.Header id="add-mortage-modal-header">
            <Modal.Title className="text-white">
              {t("Add Mortgagee")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-mortage-modal-body">
            {/* <LoadingOverlay >
                    <Loader loading={apiLoading} /> */}
            <Row>
              <Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-2">
                <Form.Label className="myform-label">
                  {t("Select  Mortgagee")}
                </Form.Label>
                <Select
                  options={interestOptions}
                  className="custom-select-autocomplete"
                  value={selectInterest}
                  style={{
                    marginBottom: "5px !important",
                  }}
                  onChange={(e) => {
                    setSelectInterest(e);
                  }}
                />
              </Col>
            </Row>
            {/* </LoadingOverlay > */}
          </Modal.Body>
          <Modal.Footer className="add-mortage-modal-footer">
            <Button
              id="primary-grey-btn-flood"
              variant="outline-dark"
              onClick={() => setShow(false)}
              style={{
                marginRight: "1rem",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              // disabled={apiLoading}
            >
              {t("Cancel")}
            </Button>

            <Button
              // onClick={() => handleSaveInterest()}
              onClick={() => {
                setShowMortgageeSearchModal(true);
              }}
              id="primary-colored-btn"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
              // disabled={apiLoading}
            >
              {t("Next")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showMortgageeSearchModal && (
        <>
        <Modal size="lg" show={show} className="add-mortage-modal">
          <Modal.Header id="add-mortage-modal-header">
            <Modal.Title className="text-white">{t("Search Interest")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-mortage-modal-body">
            <LoadingOverlay>
              {
                !isEmpty(selectInterest) && selectInterest.value === "DISASTER" ? (
                <Fragment>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">{t('First Name')}
                        <span className="label-asterisk" color="#FF1E1E"> *</span>
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_FirstName"
                          parentobject="person"
                          value={mortgageeData.person.s_FirstName ?? null}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          onClick={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">
                          {t('Middle Name')}
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_MiddleName"
                          parentobject="person"
                          value={mortgageeData.person.s_MiddleName}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">{t('Last Name')}
                        <span className="label-asterisk" color="#FF1E1E"> *</span>
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_LastOrganizationName"
                          parentobject="person"
                          value={mortgageeData.person.s_LastOrganizationName}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Fragment>
              ) : (<Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="add-mortage-form-group"
                  >
                    <Form.Label className="myform-label">
                      {t("Mortgagee(s) Name")}
                      <span className="label-asterisk" color="#FF1E1E"> *</span>
                    </Form.Label>
                    <Form.Control
                      className="myform-input"
                      type="text"
                      placeholder=""
                      name="s_FullLegalName"
                      parentobject="person"
                      value={mortgageeData.person.s_FullLegalName}
                      onChange={(e) => {
                        inputChange(e);
                        setMortData(true);
                      }}
                      maxLength={50}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Mailing Address")} 1{" "}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_AddressLine1"
                    parentobject="address"
                    value={mortgageeData.address.s_AddressLine1}
                    onChange={(e) => {inputChange(e); setMortData(true);}}
                    maxLength={150}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Mailing Address")} 2
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_AddressLine2"
                    parentobject="address"
                    value={mortgageeData.address.s_AddressLine2}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    maxLength={50}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Zip Code")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_PostalCode"
                    parentobject="address"
                    value={mortgageeData.address.s_PostalCode}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    onBlur={(e) => getZipData(e.currentTarget.value)}
                    maxLength={5}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("City")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_CityName"
                    parentobject="address"
                    value={mortgageeData.address.s_CityName}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("State")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_StateName"
                    parentobject="address"
                    value={mortgageeData.address.s_StateName}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Loan No")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_LoanNo"
                    parentobject=""
                    value={mortgageeData.s_LoanNo}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    maxLength={20}
                  />
                </Form.Group>
              </Col>
              
            </LoadingOverlay>
          </Modal.Body>

          <Modal.Footer className="add-mortage-modal-footer">
            <Button
              variant="outline-dark"
              onClick={() => setShow(false)}
              className="mybtn-light"
              style={{
                marginRight: "1rem",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              // disabled={loading}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                validation(); setEnableSave(true)
              }}
              id="primary-colored-btn"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              {t("Submit")}
            </Button>
          </Modal.Footer>
        </Modal>
        {showMultipleCity && (
          <Modal size="md" show={show} className="zip-details-modal">
            <Modal.Body className="zip-details-modal-body">
              {multipleCity}
            </Modal.Body>
          </Modal>
        )}
      </>
      )}
    </Row>
  );
};
const mapStateToProps = (state) => {
  return {
    applicationData: state.coreSystem.applicationData,
    additionalData: state.coreSystem.additionalData,
    mortgageeData: state.coreSystem.mortgageeData,
    householdData: state.coreSystem.householdData,
    addiIntData: state.coreSystem.addiIntData,
    priorPolicyLossData: state.coreSystem.priorPolicyLossData
  };
};
export default connect(mapStateToProps)(AdditionalInfoTab);
