import { React, useEffect, useState, useRef } from "react";
import { Row, Col, Tabs, Tab, NavLink } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { showDataTableTotalText, EncryptOrDecrypt } from "../../services/commonService";
import { showCommissions, showCommStmtDetails,printBatchAgentStatement } from '../../services/commissionService';
import { useDispatch } from 'react-redux';
import CommissionStatementDetail from './CommissionStatementDetails';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useNavigate } from 'react-router-dom';
const CommissionStatement = () => {
    const dispatch = useDispatch();
    const commStmt_arr = [];
    const [commStmtArray, setCommStmtArray] = useState([]);
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [detailsData, setDetailsData] = useState([]);
    const [detailsTotal, setDetailsTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const scollToRef = useRef();
    const navigate = useNavigate();
	const agentSubModulesAccess = localStorage.getItem("agentSubModules") ? JSON.parse(
        localStorage.getItem("agentSubModules")
      ) : [];
    const devKey = process.env.REACT_APP_NODE_ENV;	
	  
	  
    useEffect(() => {
        const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
        dispatch(showCommissions(userDatas.n_AgencyAddlInfoId_PK))
            .then((res) => {
                setCommStmtArray(res.stmtArr)
            });
    }, []);
    const dateFormatter = (cell) => {
        var dt = new Date(cell);
        return (
            <span>
                {(
                    dt.getMonth() + 1).toString().padStart(2, '0')}-{
                    dt.getDate().toString().padStart(2, '0')}-{
                    dt.getFullYear().toString().padStart(4, '0')}
            </span>
        );
    }

    const getStmtLink = (cell, row, enumObject, rowIndex) => {
        return (
            <NavLink href="#" style={{ padding: "0px" }} onClick={() =>  getStmtDetails(row.n_PAAgentStatementMaster_PK, 'DATA')}>
                {row.s_StatementNo}
            </NavLink>
        );
    }

    const amtPriceFormatter = (cell) => {
        return (
            <div style={{ color: cell < 0 ? 'red' : '' }}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                }).format(Math.abs(cell))}
            </div>
        )
    }

    const getStmtDetails = (n_PAAgentStatementMaster_PK, action) => {
        const commissionElelment = document.getElementById("commissionStatementDetails");
        commissionElelment.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        setIsDetailLoading(true);
        dispatch(showCommStmtDetails(n_PAAgentStatementMaster_PK))
            .then((res) => {
                setDetailsData(res);
                setDetailsTotal(res.length);
                setIsDetailLoading(false);
            });
    }

    const commissionStatementPdf = (pAAgentStatementMasterPK) => {
        if (pAAgentStatementMasterPK != '') {
            setLoading(true);
            dispatch(printBatchAgentStatement(pAAgentStatementMasterPK))
            .then((res) => {
                window.open(res.url, '_blank');
                setLoading(false);
            });
        }
    }

    const pdfStringFormatter = (cell, row, enumObject, rowIndex) => {
        return (
            <NavLink href="#" target="_blank" style={{ padding: "0px", color: "blue" }} onClick={() => commissionStatementPdf(row.n_PAAgentStatementMaster_PK)}>
                STATEMENT
            </NavLink>
        )
      }
    const columns = [
        {
            dataField: "d_StatementDate",
            text: "Statement Date",
            formatter: dateFormatter
        },
        {
            dataField: "s_StatementNo",
            text: "Statement No",
            formatter: (value, row) => getStmtLink(value, row),
            // formatter: getStmtLink
        },
        {
            dataField: "n_StatementCommAmt",
            text: "Total Amount",
            formatter: amtPriceFormatter
        },
        {
            dataField: "n_PAAgentStatementMaster_PK",
            text: "PDF",
            formatter: pdfStringFormatter
            
        },
    ];
    return (
		
        <div className="" id="dashboard-table-section" >
			{agentSubModulesAccess.includes('COMMISSION_STATEMENT')?(
				<>
				<Col xs="12" style={{ padding: 0 }}>
                <div className="table-main-title-div">
                    <span className="table-main-title-name">
                    Commission Statement
                    </span>
                     
                    {agentSubModulesAccess.includes('ACH_INFO_AGENT') && <button className="btnCustom btnCustom-info pr-4 pl-4" style={{ border: "1px solid", fontSize: "15px", background:"var(--buttonColor)" }} onClick={() => navigate(`/ACH-Information`)}>
                        Manage ACH Information
                    </button>}
                </div>
            </Col>
            <LoadingOverlay>
                <Loader loading={loading} />
                <Col xs="12" style={{ padding: 0 }}>
                    <BootstrapTable
                        wrapperClasses="management-functional-table"
                        bootstrap4
                        keyField="n_PAAgentStatementMaster_PK"
                        data={commStmtArray}
                        columns={columns}
                        hover
                        ref={scollToRef}
                    />
                </Col>
            </LoadingOverlay>
                <br></br>
                <br></br>
                <br></br>
                <CommissionStatementDetail
                    isDetailLoading={isDetailLoading}
                    detailsData={detailsData}
                    detailsTotal={detailsTotal}
                />
				</>) : 
				<strong>You Don't Have Access to View This Module</strong> }
            
            </div>
    );
};

export default CommissionStatement;
