import Http from "../../../../../Http";

export async function requestBindQuoteApi(quoteId) {
  try {
    const url = `/api/v2/quote/star/request-bind-quote/${quoteId}`;
    const response = await Http.post(url);
    return response.data;
  } catch (err) {
    console.error(
      "Error in requestBindQuote:",
      err.response?.status,
      err.response?.data || err.message
    );
    throw err;
  }
}
