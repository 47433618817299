import Http from '../Http';
import * as action from '../store/actions';
import {initPrivatProducts, setPrivateProductData,setExcessProductData,initExcessProducts} from '../store/actions';
import {selectPrivateProducts} from "../store/selectors/private-flood";

/**
 * To get Empty Quote Object
 * @param {Array} selectedPropertyData as Selected Property Data
 * @returns
 */
export function getPropertyData(selectedPropertyData) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/quote/getPropertyDataAddr/10', selectedPropertyData)
        .then((res) => {
          dispatch(action.getPropertyDataFromAddress(res.data));
          dispatch(action.setIsLoading(false));
          return resolve(res);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
        dispatch(action.setIsLoading(false));
      });
    })
  );
}

/**
 * To get Empty Quote Object
 * @param {String} productPk as Product Pk
 * @returns
 */
export function getEmptyQuoteData(productPk, type = '', address = {}, tempQuoteNo = "",selectedAgencyWyoCodeData = {}) {
  var url = '/api/quote/emptyQuoteData/' + productPk;
  if (type !== '') {
    url = url + '/' + type
  }
  if (address) {
    let formattedAddress=JSON.stringify(address).replaceAll('#','%23');
    url = url + '?address=' + formattedAddress
  }
  if (tempQuoteNo) {
    url = url + '&quoteNo=' + tempQuoteNo
  }
  if(localStorage.getItem('uniqueKey')) {
    url = url + '&uniqueKey=' + localStorage.getItem('uniqueKey')
  }
  url = url + '&selectedAgencyWyoCodeData=' + JSON.stringify(selectedAgencyWyoCodeData)
  
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get(url)
        .then((res) => {
          dispatch(action.getEmptyQuoteData(res.data));
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * Create quote with empty details
 * @param {JSON} request
 * @returns
 */
export function createQuote(request) {
  var url = '/api/quote/create';

  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post(url, request)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

/**
 * To get Empty Quote Object
 * @param {String} productPk as Product Pk
 * @returns
 */
export function getBaseData(productPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/quote/getBaseData/' + productPk)
        .then((res) => {
          if (productPk === 10) {
            dispatch(action.getFloodSystemDD(res.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return resolve(err.response);
        }
        dispatch(action.setIsLoading(false));
      });
    })
  );
}

/**
 * To Get Initial Quote Api Object
 * @returns Object
 */
export function getInitQuoteApi() {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getInitQuoteApiObject')
        .then((res) => {
          dispatch(action.getInitQuoteApi(res.data));
          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  );
}

/**
 * To get Empty Quote Object
 * @param {String} code as Pivot Api Identifier
 * @param {String} view as Source Screen
 * @returns
 */
export function pivotApiCall(code, request, plan = '') {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/flood/pivotApiCall', request)
        .then((res) => {
          if (['ADDRANALYSISGEO'].includes(code) && res.data.data.uniqueKey) {
            localStorage.setItem('uniqueKey', res.data.data.uniqueKey || '');
            // reducer
            dispatch(action.getAddressAnalysisGeoData(res.data));
          }
          if (code === 'ZONE_DETERMINATION') {
            dispatch(action.getZDDetails(res.data));
          } else {
            if (!['ADDRANALYSISGEO', 'GET_POLICY'].includes(code)) {
              dispatch(action.getQuoteResponseDetails(res.data));
            }
          }
          if (plan === 'Basic') {
            dispatch(action.getBasicCvgResponse(res.data));
          }
          if (plan === 'Recommended') {
            dispatch(action.getRecommendedCvgResponse(res.data));
          }
          if (plan === 'Custom') {
            dispatch(action.getCustomCvgResponse(res.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        return reject(err)
      })
    })
  );
}

/**
 * To Get Basic Plan Options
 * @returns Object
 */
export function getPayPlanOptions(request) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/flood/getPlanOptions', request)
        .then((res) => {
          if (res.data.data && res.data.data.Basic) {
            dispatch(action.getBasicCvgDetails(res.data.data.Basic));
            dispatch(action.getCustomCvgDetails(res.data.data.Basic));
          }
          if (res.data.data && res.data.data.Recommended) {
            dispatch(action.getRecommendedCvgDetails(res.data.data.Recommended));
          }

          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  );
}

/**
 * To Get Deductibles Options
 * @returns Object
 */
export function getDeductibles() {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getDeductible')
        .then((res) => {
          if (res.data.success) {
            dispatch(action.getDeductibles(res.data.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  );
}

/**
 * To Get Zip details
 * @returns Object
 */
export function getZipDetails(product, zipCode, source) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/quote/getZipCodeDetails/' + product + '/' + zipCode + '/' + source)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  );
}

/**
 * To Get Mortgagee
 * @returns Object
 */
export function getSearchMortgagee(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/quote/searchMortgagee', data)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  )
}


/**
 * To Get quote data based on poTransPk
 * @returns Object
 */
export function getQuoteData(poTransPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getQuoteData/' + poTransPk)
        .then((res) => {
          dispatch(action.getEmptyQuoteData(res.data));
          dispatch(action.setIsLoading(false));
          return resolve();
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  );
}

/**
 * To Get Agency List based on value
 * @returns Object
 */
export function agencyList(search) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/quote/agencyList', search)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  );
}

/**
 * To Get Agent List based on agencyID
 * @returns Object
 */
export function agentList(agencyID, productPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/quote/agentList/' + agencyID + '/' + productPk)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch(function (error) { // catch
        dispatch(action.setIsLoading(false));
        return reject(error);
      });
    })
  );
}

/**
 * To get Flood Payment Info
 * @param {Array} poTransPk as poTransPk
 * @returns
 */
export function getFloodPaymentInfo(poTransPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/getInitPaymentData/' + poTransPk)
        .then((res) => {
          if (res.data) {
            dispatch(action.getInitPaymentData(res.data));
          }
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        return reject(err)
      })
    })
  );
}

/**
 * Flood Payment
 * @lokeshrangani
 * @param {Array} request as Request
 * @returns
 */
export function payOnline(request, type = '') {
  var url = '/api/flood/eCheckPayment';
  if (type === 'ACH') {
    url = '/api/flood/achPayment';
  }
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post(url, request)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve([res.data, null]);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        if (err.response) {
          return resolve([[], err.response.data.errors.text])
        } else {
          return resolve([[], err.message])
        }
      })
    })
  );
}

/**
 * Flood Rate/Save
 * @param {Array} request as Request
 * @returns
 */
export function rateorSaveApplication(request, type = '') {
  var url = '/api/flood/storeFloodQuoteData';
  if (type === 'Rate') {
    url = '/api/flood/storeFloodQuoteData';
  }
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post(url, request)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        return reject(err);
      })
    })
  );
}

/**
 * Flood Create Application
 * @param {Array} poTransPk as poTransPk
 * @returns
 */
export function createApplication(poTransPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/createQuoteCore/' + poTransPk)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        return reject(err);
      })
    })
  );
}

/**
 * Flood Bind Application
 * @param {String} poTransPK as poTransPK
 * @returns
 */
export function bindApplication(coreTransPK, poTransPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/bindApplication/' + coreTransPK + '/' + poTransPK)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        return reject(err.message);
      })
    })
  );
}

/**
 * FEMA - Pivot Api Response Log
 * @param {Object} as request
 * @returns
 */
export function pivotRespLog(request) {
  return dispatch => (
    new Promise((resolve, reject) => {
      var url = `/api/flood/getPivotApiLog?`;
      Object.entries(request).forEach(v => {
        if (v[1] !== '') {
          url = url + '&' + v[0] + '=' + v[1];
        }
      });
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        return reject(err.message);
      })
    })
  );
}

/**
 * To updated Empty Quote Object
 * @param {Object} reqJson
 * @returns
 */
export function getEditEmptyQuoteData(data) {
  return dispatch => (
    dispatch(action.getEditEmptyQuoteData(data))
  );
}

/**
 * To updated Property Data Autocomplete
 * @param {Object} reqJson
 * @returns
 */
export function propertyDataAutocompleteStore(data) {
  return dispatch => (
    dispatch(action.getPropertyDataAutocompleteStore(data))
  );
}

/**
 * To updated Initial Quote Object
 * @param {Object} reqJson
 * @returns
 */
export function updateInitQuoteApi(reqJson) {
  return dispatch => (
    dispatch(action.getInitQuoteApi(reqJson))
  );
}

/**
 * To updated Which Section For Pivot API
 * @param String whichSection
 * @returns
 */
export function updateWhichSection(whichSection) {
  return dispatch => (
    dispatch(action.getWhichSection(whichSection))
  );
}

/**
 * To updated Last Section
 * @param String lastSection
 * @returns
 */
export function updateLastSection(lastSection) {
  return dispatch => (
    dispatch(action.getLastSection(lastSection))
  );
}

/**
 * update payment details
 * @param Object data
 * @returns
 */
export function getEditPaymentData(data) {
  return dispatch => (
    dispatch(action.getInitPaymentData(data))
  );
}

/**
 * reset store
 * @param Object data
 * @returns
 */
export function resetFloodStore() {
  return dispatch => (
    dispatch(action.resetFloodStore())
  );
}

/**
 * To generated documents
 * @returns
 */
export function getGeneratedDocs(source, poTransPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/reports/generate/' + source + '/' + poTransPK)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          dispatch(action.setIsLoading(false));
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To generated documents
 * @returns
 */
export function getPremiumDetails(sourceID, isUuid) {
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/flood/premium/' + sourceID + '/' + isUuid)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          dispatch(action.setIsLoading(false));
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To get verify Agent Authorized state
 * @param {Array} params as Selected Address
 * @returns
 */
export function getAgentAuthorizeState(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/quote/getAgentAuthorizeState', params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

// Get Document List
export function getDocumentList(poTransPK) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get("/api/flood/getDocumentList/" + poTransPK).then((res) => {
        dispatch(action.setIsLoading(false));
        return resolve(res.data);
      }).catch((err) => {
        dispatch(action.setIsLoading(false));
        if (err.response) {
          return reject(err.response);
        }
      });
    });
}

// Upload Document
export function uploadDocument(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post("/api/flood/uploadDocument", params).then((res) => {
        dispatch(action.setIsLoading(false));
        return resolve(res.data);
      }).catch((err) => {
        dispatch(action.setIsLoading(false));
        if (err.response) {
          return reject(err.response);
        }
      });
    });
}

/**
 * To get verify CE License Of Agent
 * @returns
 */
export function validateCeLicense(params = '') {
	var url = '/api/flood/validateCeLicense';
	var subAgentPersonPk = 0;
	var agencyPersonPk = 0;
	
	if(params != '' && typeof params === 'object'){
		if(typeof params.subAgentPersonPk != 'undefined' && params.subAgentPersonPk != ''){
			subAgentPersonPk = params.subAgentPersonPk;
		}
		if(typeof params.agencyPersonPk != 'undefined' && params.agencyPersonPk != ''){
			agencyPersonPk = params.agencyPersonPk;
		}
	}
	
	if(subAgentPersonPk > 0 && agencyPersonPk > 0){
		url = '/api/flood/validateCeLicense?agentPersonPk='+subAgentPersonPk+'&agencyPersonPk='+agencyPersonPk;
	}else if(subAgentPersonPk ==0 && agencyPersonPk >0){
		url = '/api/flood/validateCeLicense?agencyPersonPk='+agencyPersonPk;
	}else if(subAgentPersonPk > 0 && agencyPersonPk == 0){
		url = '/api/flood/validateCeLicense?agentPersonPk='+subAgentPersonPk;
	}

  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To get pre request camcel quote data
 * @returns Promise
 */
export function getPreCancelTransData(policyPK, poTransPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/flood/cancel/getData/' + policyPK + '/' + poTransPK)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To save pre request camcel quote data
 * @returns Promise
 */
export function savePreCancelTransData(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/flood/cancel/saveData', params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To upload cancel quote required document
 * @returns Promise
 */
export function uploadCancelDocument(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/flood/cancel/uploadDocument', params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To generate temp url for view cancel quote documents
 * @returns Promise
 */
export function generateTempUrl(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/flood/cancel/generateTempUrl', params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To get pre request cancel refund amount
 * @returns Promise
 */
export function getRefundAmount(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/flood/cancel/getCancelRefund', params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To delete pre request cancel quote
 * @returns Promise
 */
export function deletePreCancelTransData(preCancelQuoteId) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/flood/cancel/deleteTrans/' + preCancelQuoteId)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * To update pre endorsement quote payment status
 * @returns Promise
 */
export function updateEndorsementQuotePaymentStatus(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/endorse/updatePaymentStatus', params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
    })
  );
}

/**
 * Get private product configurations
 * @returns Promise
 */
export function getPrivateProductConfigurations() {
  return (dispatch) => {
    dispatch(initPrivatProducts());
    const newAonEdgeExcess = [];
      const newState = [];
      newAonEdgeExcess['error'] = '';
      newAonEdgeExcess['quoteNumber'] = '';
      newAonEdgeExcess['premium'] = [];
      newAonEdgeExcess['quoteLink'] = '';
      newAonEdgeExcess['loading'] = false;
      newState['excessAonEdgeproduct'] = newAonEdgeExcess;

      dispatch(setExcessProductData(newState))
    dispatch(setPrivateProductData({loading: true}));
    return new Promise((resolve, reject) => {
      Http.get('/api/getProductDetails')
        .then(({data: productsData}) => {
          const newState = {loading: false};
          if (productsData.data) {
            let {nfipproduct, privateproduct} = productsData.data;
            nfipproduct = Object.values(nfipproduct).sort((a, b) => a.displayOrder - b.displayOrder);
            privateproduct = Object.values(privateproduct).sort((a, b) => a.displayOrder - b.displayOrder);
            newState['nfipproduct'] = nfipproduct;
            newState['privateproduct'] = privateproduct;
          }
          dispatch(setPrivateProductData(newState));
          return resolve();
        })
        .catch((err) => {
          const newState = {loading: false}
          if (err.response) {
            newState['error'] = err.response;
          }
          dispatch(setPrivateProductData(newState));
          return reject();
        });
    })
  };
}

export const getSingleProduct = (privateProducts, productType, productId, newData = null) => {
  let product = privateProducts[productType].find((product) => product.productId === productId)
  if (newData) {
    product = {...product, ...newData}
  }
  return product;
}
const getNewProductsState = (privateProducts, productType, productId, newData = null) => {
  privateProducts[productType] = privateProducts[productType].map((product) => {
    if (product.productId === productId) {
      product = {...product, ...newData}
    }
    return product;
  })
  return privateProducts
}
const quoteAPINotAvailable = (dispatch, getState, currentLocationState, privateProducts) => {
  const allProductIds = privateProducts.filter(product => {
    const isStateAvailable = product.authorizedStates.find((availableStateInfo) => availableStateInfo.s_StateName === currentLocationState)
    return product.isApiActive === 'Y' && product.isShow === 'Y' && isStateAvailable
  }).map(product => product.productId);
  for (let productId of allProductIds) {
    const productNames = {
      macneill: 'MacNeill',
      neptune: 'Neptune',
      cat: 'CATCoverage',
      ezflood: 'AonEdge'
    }
    const error = `${productNames[productId]} is not available right now, please try again later.`;
    const newProduct = {
      loading: false,
      error
    }
    dispatch(setPrivateProductData(getNewProductsState(selectPrivateProducts(getState()), 'privateproduct', productId, newProduct)))
  }
}

export function getHiscoxPremium(apiRequestParams,isExcessProduct = false, privateProductArray = []) {
  const hostname = process.env.REACT_APP_NODE_ENV === 'LOCAL' ? process.env.REACT_APP_DEV_API_URL : '';
  return async (dispatch, getState) => {
    const currentLocationState = (apiRequestParams?.addresses?.location?.state).toUpperCase();
    const state = getState();
    const privateProducts = privateProductArray;
    try {
      const availableProductIds = privateProducts.filter(product => {
        const isStateAvailable = product.authorizedStates.find((availableStateInfo) => availableStateInfo.s_StateName === currentLocationState)
        return product.isApiActive === 'Y' && product.isShow === 'Y' && isStateAvailable
      }).map(product => product.productId);
      // const availableProductIds = ['macneill'];
      const unavailableProducts = privateProducts.map(product => product.productId).filter(productId => !availableProductIds.includes(productId));
      for (let productId of unavailableProducts) {
        const productNames = {
          macneill: 'MacNeill',
          neptune: 'Neptune',
          cat: 'CATCoverage',
          ezflood: 'AonEdge'
        }
        const error = `${productNames[productId]} is not available in the selected state: ${apiRequestParams['addresses']['location']['state']}.`;
        const newProduct = {
          loading: false,
          error
        }
        dispatch(setPrivateProductData(getNewProductsState(selectPrivateProducts(getState()), 'privateproduct', productId, newProduct)))
      }
      if (availableProductIds.length > 0) {
        availableProductIds.forEach(async availableProductId => {
          dispatch(setPrivateProductData(getNewProductsState(selectPrivateProducts(getState()), 'privateproduct', availableProductId, {loading: true})))
          const privateProductDetails = selectPrivateProducts(state);
          const neptuneProduct = privateProductDetails?.privateproduct.find(product => product.productId === availableProductId);
          const privateProductQuoteNumber = neptuneProduct?.quoteNumber ?? '';
          if(privateProductQuoteNumber){
            apiRequestParams['privateProductQuoteNumber'] = privateProductQuoteNumber;
          }
          apiRequestParams = {
            ...apiRequestParams,
            availableProducts: [availableProductId],
          }
          const getQuoteListsResponse = await Http.post(`${hostname}/api/flood/private-products/quote`, apiRequestParams);
          if (getQuoteListsResponse.data.success) {
            const quoteData = getQuoteListsResponse.data.data;
            if (quoteData['neptune']) {
              const newNeptune = {
                loading: false
              }
              if(quoteData['neptune']['success']){
                const neptuneQuoteData = quoteData['neptune']['data'];
                newNeptune['quoteNumber'] = neptuneQuoteData['quoteNumber']
                newNeptune['premium'] = neptuneQuoteData['premium']
                newNeptune['quoteLink'] = neptuneQuoteData['quoteLink'];
                newNeptune['reportId'] = neptuneQuoteData['reportId'];
                newNeptune['error'] = null;
              }else{
                newNeptune['error'] = quoteData['neptune']['message'];
              }
              dispatch(setPrivateProductData(getNewProductsState(selectPrivateProducts(getState()), 'privateproduct', 'neptune', newNeptune)))
            }
            if (quoteData['ezflood']) {
              const newEzFlood = {
                loading: false
              };
              if(!quoteData['ezflood']['success']){
                newEzFlood['error'] = quoteData['ezflood']['message'];
              }else{
                const ezFloodData = quoteData['ezflood']['data'];
                newEzFlood['quoteNumber'] = ezFloodData['quoteNumber'];
                newEzFlood['premium'] = ezFloodData['premium'];
                newEzFlood['quoteLink'] = ezFloodData['quoteLink'];
                newEzFlood['reportId'] = ezFloodData['reportId'];
                newEzFlood['error'] = null;
              }
  
              dispatch(setPrivateProductData(getNewProductsState(selectPrivateProducts(getState()), 'privateproduct', 'ezflood', newEzFlood)))
            }
            if (quoteData['macneill']) {
              const newHiscox = {
                loading: false
              };
              if(quoteData['macneill']['success']) {
                const riskData = quoteData['macneill']['data'];
                newHiscox['premium'] = riskData['premium']
                newHiscox['quoteNumber'] = riskData['quoteNumber']
                newHiscox['reportId'] = riskData['reportId']
                newHiscox['error'] = null;

              }
              else{
                newHiscox['error'] = quoteData['macneill']['message'];
              }
              dispatch(setPrivateProductData(getNewProductsState(selectPrivateProducts(getState()), 'privateproduct', 'macneill', newHiscox)))
            }
            if (quoteData['cat']) {
              const newCatCoverage = {
                loading: false
              };
              if(!quoteData['cat']['success']){
                newCatCoverage['error'] = quoteData['cat']['message'];
              }else{
                const catCoverageData = quoteData['cat']['data'];
                newCatCoverage['quoteNumber'] = catCoverageData['quoteNumber'];
                newCatCoverage['premium'] = catCoverageData['premium'];
                newCatCoverage['quoteLink'] = catCoverageData['quoteLink'];
                newCatCoverage['reportId'] = catCoverageData['reportId'];
                newCatCoverage['error'] = null;
              }
  
              dispatch(setPrivateProductData(getNewProductsState(selectPrivateProducts(getState()), 'privateproduct', 'cat', newCatCoverage)))
            }
          } else {
            quoteAPINotAvailable(dispatch, getState, currentLocationState, privateProducts)
          }
        });
      }
    } catch (e) {
      quoteAPINotAvailable(dispatch, getState, currentLocationState, privateProducts)
    }
  }
}

export const isPrivateProductAvailable = (privateProducts, productType, privateProductId) => {
  try {
    return !!privateProducts[productType]
      .find(
        (product) =>
          product.productId === privateProductId &&
          // product.isApiActive === 'Y' &&
          product.isShow === 'Y'
      )
  } catch (e) {
    return false
  }
}
export const getProducerInfo = (agencyFk, agentFK) => {
  return new Promise(async(resolve, reject)=>{
    try {
      const response = await Http.get(`/api/getProducerDetails/${agencyFk}/${agentFK}`);
      return resolve(response.data);
    }catch (e) {
      return reject(e)
    }
  })
}

/**
 *
 * @param {String} poTransPk
 * @returns Promise
 */
export const getOptionsData = (poTransPk) => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/corePolicies/coverageOptionAB/" + poTransPk).then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}

/**
 * Flood Zone Determination Get Data (Status Query Request)
 * @param {String} determinationId as id
 * @returns
 */
export function massiveCertGetData(determinationId) {
  var url = '/api/flood/zoneDetermination/getData/' + determinationId;

  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get(url)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
          return reject(err);
        })
    })
  );
}

// To set temp quote no
export function setTempQuoteNo(quoteNo) {
  return (dispatch) => dispatch(action.setTempQuoteNo(quoteNo));
}

/**
 * Store Flood quote data on every step
 * @param {Object} params as params
 * @returns
 */
export function storeFloodQuoteStepData(params) {
  var url = '/api/quote/save';

  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setSaveLoading(true));
      Http.post(url, params)
        .then((res) => {
          dispatch(action.setSaveLoading(false));
          return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setSaveLoading(false));
          return reject(err);
        })
    })
  );
}

/**
 * Generate Temp url to view Flood quote document
 * @param {Object} n_PoDocumenttrackTrans_PK as n_PoDocumenttrackTrans_PK
 * @returns
 */
export function viewDocument(poDocumenttrackTransPK) {
  var url = '/api/quote/viewDocument';

  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post(url, poDocumenttrackTransPK)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          return reject(err);
        })
    })
  );
}

/**
 * To get Empty Quote Object
 * @param {String} productPk as Product Pk
 * @returns
 */
export function getPrivateProductsExcess() {
  return dispatch => {
    const newAonEdgeExcess = [];
      const newState = [];
      newAonEdgeExcess['error'] = '';
      newAonEdgeExcess['quoteNumber'] = '';
      newAonEdgeExcess['premium'] = [];
      newAonEdgeExcess['quoteLink'] = '';
      newAonEdgeExcess['loading'] = true;
      newState['excessAonEdgeproduct'] = newAonEdgeExcess;

      dispatch(setExcessProductData(newState))
    dispatch(setPrivateProductData({loading: true}));
    return new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.get('/api/getPrivateProductExcess')
      .then(({data: productsData}) => {
        const newState = {loading: false};
        if (productsData.data) {
          let {nfipproduct, privateproduct} = productsData.data;
          nfipproduct = Object.values(nfipproduct).sort((a, b) => a.displayOrder - b.displayOrder);
          privateproduct = Object.values(privateproduct).sort((a, b) => a.displayOrder - b.displayOrder);
          newState['nfipproduct'] = nfipproduct;
          newState['privateproduct'] = privateproduct;
        }
        dispatch(setExcessProductData(newState));
        return resolve();
      })  
      .catch((err) => {
        if (err.response) {
          return resolve(err.response);
        }
        dispatch(action.setIsLoading(false));
      });
    })
  };
}

export function getExcessPremium(apiRequestParams) {
  const hostname = process.env.REACT_APP_NODE_ENV === 'LOCAL' ? process.env.REACT_APP_DEV_API_URL : '';
  return async (dispatch, getState) => {
    const currentLocationState = apiRequestParams?.addresses?.location?.state;
    const state = getState();
    const marketPlaceProducts = selectPrivateProducts(state);
    const privateProducts = marketPlaceProducts['privateproduct'];
    try {
      const newAonEdgeExcess = {
        error: '',
        quoteNumber: '',
        premium: [],
        quoteLink: '',
        loading: true,
        reportId:0
      };
      
      const newState = {
        excessAonEdgeproduct: newAonEdgeExcess,
      };

      dispatch(setExcessProductData(newState))


      const availableProductIds = ['AonEdgeExcess'];
      if (availableProductIds.length > 0) {
        apiRequestParams = {
          availableProducts: availableProductIds,
          ...apiRequestParams,
        }
        try{
          const getQuoteListsResponse = await Http.post(`${hostname}/api/flood/private-products/quote`, apiRequestParams);
          if (getQuoteListsResponse.data.success) {
            const quoteData = getQuoteListsResponse.data.data;
            if (quoteData['AonEdgeExcess']) {
              const newAonEdgeExcess = {
                loading: false
              };
              if(!quoteData['AonEdgeExcess']['success']){
                newAonEdgeExcess['error'] = quoteData['AonEdgeExcess']['message'];
              }else{
                const AonEdgeExcess = quoteData['AonEdgeExcess']['data'];
                newAonEdgeExcess['quoteNumber'] = AonEdgeExcess['quoteNumber'];
                newAonEdgeExcess['premium'] = AonEdgeExcess['premium'];
                newAonEdgeExcess['quoteLink'] = AonEdgeExcess['quoteLink'];
                newAonEdgeExcess['reportId'] = AonEdgeExcess['reportId'];
              }
  
              const newState = {loading: false};
                newState['excessAonEdgeproduct'] = newAonEdgeExcess;
  
              dispatch(setExcessProductData(newState))
            }
          } 
        } catch (e){
          const newAonEdgeExcess = {
            loading: false
          };
          newAonEdgeExcess['error'] = 'something went wrong';
          const newState = {loading: false};
                newState['excessAonEdgeproduct'] = newAonEdgeExcess;
  
              dispatch(setExcessProductData(newState))
        }
      }
    } catch (e) {
      quoteAPINotAvailable(dispatch, getState, currentLocationState, privateProducts)
    }
  }
}

export function updatePrivateProductReport(reportId = 0,premium = 0) {
  const params = {
    'id' : reportId,
    'premium' : premium,
  }
  var url = '/api/flood/private-products/updatePvtProductReport';
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post(url, params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          return reject(err);
        })
    })
  );
}

export function getZoneDetermination(req){
  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post('/api/flood/getZoneDetermination', req)
        .then((res) => {
          dispatch(action.getZDDetails(res.data.data));
          dispatch(action.setIsLoading(false));
            return resolve(res.data);
        }).catch((err) => {
          dispatch(action.setIsLoading(false));
        return reject(err)
      })
    })
  );
}

/**
 * Save Mortgagee Data
 * @param {Array} request as Request
 * @returns
 */
export function saveMortgageeData(request, type = '') {
  var url = '/api/flood/saveMortgageeData';

  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post(url, request)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        return reject(err);
      })
    })
  );
}

/**
 * Delete Mortgagee Data
 * @param {Array} request as Request
 * @returns
 */
export function deleteMortgagee(request, type = '') {
  var url = '/api/flood/deleteMortgagee';

  return dispatch => (
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      Http.post(url, request)
        .then((res) => {
          dispatch(action.setIsLoading(false));
          return resolve(res.data);
        }).catch((err) => {
        dispatch(action.setIsLoading(false));
        return reject(err);
      })
    })
  );
}

/**
 * To GET selected agency Wyo code data
 * @param {Object} reqJson
 * @returns
 */
export function selectedAgencyWyoCode(data) {
  return dispatch => (
    dispatch(action.setSelectedAgencyWyoData(data))
  );
}

/*
* Get Pending Policy Details
 * @param { JSON } request as Request
 * @returns
 */
export function getPendingPolicies(url, request) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url, request)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          return reject(err);
        })
    })
  );
}