import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getEditEmptyQuoteData, storeFloodQuoteStepData } from "../../../../services/floodService";
import { Alert, Button, Modal } from "react-bootstrap";
import FloodAddress from "./flood-address/FloodAddress";
import FloodHome from "./flood-home/FloodHome";
import StartingEstimate from "./flood-detail/starting-estimate/StartingEstimate";
import FloodPropertyDetail from "./flood-detail/flood-property-detail/FloodPropertyDetail";
import FloodContactInformation from "./flood-detail/flood-contact-information/FloodContactInformation";
import WaitingPeriod from "./flood-detail/waiting-period/WaitingPeriod";
import ConstructionFormOne from "./flood-detail/construction/ConstructionFormOne";
import ConstructionFormTwo from "./flood-detail/construction/ConstructionFormTwo";
import DiscountForm from "./flood-detail/discounts/DiscountForm";
import DiscountFormOne from "./flood-detail/discounts/DiscountFormOne";
import DiscountFormTwo from "./flood-detail/discounts/DiscountFormTwo";
import DiscountFormThree from "./flood-detail/discounts/DiscountFormThree";
import PreFirmDiscount from "./flood-detail/discounts/PreFirmDiscount";
import FloodPricingPlan from "./flood-detail/flood-pricing-plan/FloodPricingPlan";
import ConstructionFormMH from "./flood-detail/construction/ConstructionFormMH";
import AddMortgagee from "./flood-detail/Mortgagee/AddMortgagee";
import PivotOutageMsg from "../../../common/PivotOutageMsg.js";
import ModeToggle from "../../../common/ModeToggle.js";
import Moment from 'moment';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { checkPivotOutage } from "../../../../services/commonService";

const Steps = () => {
  // get step Number
  let { number } = useParams();
  const isPivotOutage = checkPivotOutage();
  let view = null;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    emptyQuoteData,
    addressAnalysisGeoData,
    quoteResponseDetails,
    ZDDetailsFromAddress,
    tempQuoteNo,
    saveLoading,
    createQuoteResponse
  } = useSelector(state => state.flood);
  const [errorModal, setErrorModal] = useState(null);
  const [premium, setPremium] = useState(0);
  const [isFirmUpdated, setIsFirmUpdated] = useState(false);
  const [updateArray, setUpdateArray] = useState([]);

  // For All Input/Select Handler
  const handleChange = (e, fieldValue = '') => {
    var quoteDataVar = emptyQuoteData;
    if (fieldValue) {
      // fieldValue = fieldValue.trim().toUpperCase();
      var addInter = [];

      let firstName = fieldValue.InsuredFirstName
      let lastName = fieldValue.InsuredLastName
      if (fieldValue === "s_firstNameInsured") {
        firstName = e.target.value;
      }
      if (fieldValue === "s_lastNameInsured") {
        lastName = e.target.value;
      }

      let fullLegalName = firstName;
      if (fullLegalName) fullLegalName = fullLegalName + ' ';
      fullLegalName = fullLegalName + lastName;
      var addInJson = {
        "n_PolicyAddInterest_PK": null,
        "n_POTransaction_FK": null,
        "n_PersonInfoId_PK": null,
        "s_PersonType": "ADDITIONALINSURED",
        "s_MortgageeType": null,
        "s_LoanNo": null,
        "s_AddionalIntType": null,
        "s_RelationType": null,
        "s_HouseholdType": null,
        "s_isDeleted": "N",
        "n_CorePersonPK": null,
        "n_CorePersonAddressPK": null,
        "n_CreatedUser": null,
        "d_CreatedDate": null,
        "n_UpdatedUser": null,
        "d_UpdatedDate": null,
        "person": {
          "n_PersonInfoId_PK": null,
          "s_PersonUniqueId": null,
          "s_EntityType": "PERSON",
          "s_FirstName": firstName,
          "s_MiddleName": null,
          "s_LastOrganizationName": lastName,
          "s_FullLegalName": fullLegalName,
          "d_BirthDate": null,
          "s_Occupation": null,
          "s_IsMailingDiff": null,
          "s_IsTempMailing": null,
          "n_CreatedUser": null,
          "d_CreatedDate": null,
          "n_UpdatedUser": null,
          "d_UpdatedDate": null

        },
        "address": {
          "n_PersonAddressesId_PK": null,
          "n_PersonInfoId_PK": null,
          "s_AddressTypeCode": "MAILING",
          "s_IsDefaultAddress": "Y",
          "s_AddressLine1": quoteDataVar['personAddr']['s_AddressLine1'],
          "n_CountyId_FK": quoteDataVar['personAddr']['n_CountyId_FK'],
          "n_CityId_FK": quoteDataVar['personAddr']['n_CityId_FK'],
          "n_StateId_FK": quoteDataVar['personAddr']['n_StateId_FK'],
          "n_CountryId_FK": quoteDataVar['personAddr']['n_CountryId_FK'],
          "s_PostalCode": quoteDataVar['personAddr']['s_PostalCode'],
          "s_HouseNo": quoteDataVar['personAddr']['s_HouseNo'],
          "s_StreetName": quoteDataVar['personAddr']['s_AddressLine1'],
          "s_PostalCodeSuffix": quoteDataVar['personAddr']['s_PostalCodeSuffix'],
          "s_Latitude": quoteDataVar['personAddr']['s_Latitude'],
          "s_Longitude": quoteDataVar['personAddr']['s_Longitude'],
          "s_CountryName": quoteDataVar['personAddr']['s_CountryName'],
          "s_StateName": quoteDataVar['personAddr']['s_StateName'],
          "s_CountyName": quoteDataVar['personAddr']['s_CountyName'],
          "s_CityName": quoteDataVar['personAddr']['s_CityName'],
          "s_ParcelId": quoteDataVar['personAddr']['s_ParcelId'],
          "n_CreatedUser": null,
          "d_CreatedDate": null,
          "n_UpdatedUser": null,
          "d_UpdatedDate": null
        }
      }
      addInter.push(addInJson);
      if (addInter.length > 0) {
        quoteDataVar['propertyInfo']['s_IsAddInsureds'] = 'YES';
      } else {
        quoteDataVar['propertyInfo']['s_IsAddInsureds'] = 'NO';
      }

      quoteDataVar['additionaInsuredData'] = addInter;
    } else {
      const { name, value, attributes } = e.target;
      const parentKey = attributes.parentobject.value;
      quoteDataVar[parentKey][name] = value;
      // Update Date Of Construction If Year Built Changed
      if (name === 's_YearBuilt') {
        quoteDataVar[parentKey]['d_DateOfConstruction'] = value ? new Date(value + '-01-01') : '';
        setIsFirmUpdated(false);
      }
      handleUpdateArray(parentKey, name, value);
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  /**
   * Get First Name And Last name
   * @lokeshrangani
   * @param {String} fieldValue
   * @returns
   */
  const getFirstLastName = (fieldValue, isAddInsureds = false) => {
    var firstName = '';
    var lastName = '';
    var length = 0;
    fieldValue = fieldValue.trim();
    if (fieldValue.indexOf(",") === -1) {
      length = fieldValue.split(" ").length
      firstName = fieldValue.split(" ")[0] || '';
      lastName = fieldValue.substr(fieldValue.indexOf(" ") + 1) || '';

      if (length === 1) {
        lastName = firstName;
        firstName = ''
      }
    } else {
      firstName = fieldValue.substr(fieldValue.indexOf(",") + 1) || '';
      lastName = fieldValue.split(",")[0] || '';
    }
    if (isAddInsureds === true && firstName.length === 0) {
      firstName = lastName;
      lastName = ''
    }
    return [firstName.trim(), lastName.trim()];
  }

  /**
   * Find All Sub String After Split into AND or &
   * @lokeshrangani
   * @param {String} fieldValue
   * @param {String} char
   * @returns
   */
  const findAllSubString = (fieldValue, char) => {
    if (char.length === 0) return [];
    var idArray = [], stringsArray = [];
    var id = 0, idx = fieldValue.indexOf(char);
    // Find If String present in given string of Not
    while (idx !== -1) {
      idArray.push(idx);
      idx = fieldValue.indexOf(char, idx + 1);
    }

    if (idArray.length > 0) {
      // Get Array of String After Split it
      for (var i = 0; i <= idArray.length; i++) {
        if (id !== 0) {
          stringsArray.push(fieldValue.slice(id, idArray[i]).trim())
        } else {
          stringsArray.push(fieldValue.slice(0, idArray[i]).trim())
        }
        char === 'AND' ? id = idArray[i] + 3 : id = idArray[i] + 1;
      }
    }
    return stringsArray;
  }

  // For All App Switch Handler
  const handleSwitchChange = (e) => {
    const { name, attributes, checked } = e.target;
    var parentObjectName = attributes.parentobject.value;
    var quoteDataVar = emptyQuoteData;
    let value = "";
    if (checked) {
      value = attributes.checkedvalue.value;
      quoteDataVar[parentObjectName][name] = attributes.checkedvalue.value;
    } else {
      value = attributes.unCheckedValue.value;
      quoteDataVar[parentObjectName][name] = attributes.unCheckedValue.value;

      // Updated Dependent Question Value
      if (name === 's_IsPriorNFIPLapsed' && ['', 'NO'].includes(attributes.unCheckedValue.value)) {
        quoteDataVar[parentObjectName]['s_IsCommunitySuspension'] = '';
      }
      if (name === 's_IsNewPurchase' && ['', 'NO'].includes(attributes.unCheckedValue.value)) {
        quoteDataVar[parentObjectName]['isSellerDec'] = '';
      }
    }
    handleUpdateArray(parentObjectName, name, value);
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // For All Date Handler
  const handleDateChange = (date, name, parent) => {
    var quoteDataVar = emptyQuoteData;
    if (parent) {
      quoteDataVar[parent][name] = date;
      handleUpdateArray(parent, name, date);
    } else {
      if (name == 'effectiveDate') {
        handleUpdateArray('transactionInfo', 'd_TransEffectiveFrom', date);
      }
      quoteDataVar[name] = date;
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // For All Radio Button Handler
  const handleRadioChange = (e) => {
    const { name, value, id, attributes } = e.target;
    var quoteDataVar = emptyQuoteData;
    quoteDataVar[id][name] = value;
    handleUpdateArray(id, name, value);
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // check date if valid or not
  const checkDateisValid = (date) => {
    return !isNaN(Date.parse(date));
  }

  // To Updated address analysis geo pivot data details into emptyQuoteData
  useEffect(() => {
    if (emptyQuoteData) {
      if (emptyQuoteData.propertyInfo && [null, ""].includes(emptyQuoteData.propertyInfo.s_FloodZone)) {
        if (addressAnalysisGeoData && Object.keys(addressAnalysisGeoData).length > 0) {
          if (addressAnalysisGeoData.success === true && addressAnalysisGeoData.data) {
            const emptyQuote = emptyQuoteData;
            var analysisData = addressAnalysisGeoData.data;
            var communityDetail = analysisData.communityDetail || [];
            if (communityDetail.community) {
              var community = communityDetail.community;
              emptyQuote.propertyInfo.s_CommunityNumber = (community.stateCode + community.communityIdNumber) || '';

              var NFIPCommunityFIRMDate = (community.initialFirmDate || '');
              var InitFirmDate = '';
              if (!['', null, '0000-00-00', undefined].includes(NFIPCommunityFIRMDate)) {
                InitFirmDate = NFIPCommunityFIRMDate;
              }
              var CurrentFirmDate = (community.currentFirmDate || '');
              var currFirmDate = '';
              if (!['', null, '0000-00-00', undefined].includes(CurrentFirmDate)) {
                currFirmDate = CurrentFirmDate;
              }

              emptyQuote.propertyInfo.d_CurrentFirmDate = currFirmDate;
              emptyQuote.propertyInfo.d_InitialFirmDate = InitFirmDate;

              // var floodProgramType = community.programType || '';
              // emptyQuote.propertyInfo.s_FloodProgramType = (floodProgramType.includes('Regular Program') ? 'FLOODREGULAR' : floodProgramType.includes('Emergency') ? 'FLOODEMERGENCY' : '') || null;
            }

            var floodZoneDetermination = analysisData.floodZoneDetermination;
            if (floodZoneDetermination) {
              emptyQuote.propertyInfo.s_FloodZone = (['X500', 'X100'].includes(floodZoneDetermination.floodHazardZone) ? 'X' : floodZoneDetermination.floodHazardZone || '');
              emptyQuote.personAddr.s_stateFips = floodZoneDetermination.stateCountyFIPSCode || "";
              emptyQuote.propertyInfo.s_MapSuffix = floodZoneDetermination.mapSuffix || '';
              emptyQuote.propertyInfo.s_PanelNumber = floodZoneDetermination.mapPanel || '';
            }

            dispatch(getEditEmptyQuoteData(emptyQuote));
          }
        }
      }
    }
  }, [emptyQuoteData])

  /**
   * Set Updated ZD Details into QuoteData
   */
  useEffect(() => {
    if (ZDDetailsFromAddress && emptyQuoteData && Object.keys(ZDDetailsFromAddress).length > 0
      && Object.keys(emptyQuoteData).length > 0
    ) {
      setzDDetails(ZDDetailsFromAddress);

    }
  }, [ZDDetailsFromAddress, emptyQuoteData])

  const setzDDetails = async (zDData) => {
    // setting zone determination 
    const emptyQuote = { ...emptyQuoteData };
    const NFIPCommunityFIRMDate = zDData.NFIPCommunityFIRMDate;
    let InitFirmDate = '';
    if (NFIPCommunityFIRMDate !== '' && NFIPCommunityFIRMDate !== null && NFIPCommunityFIRMDate !== '0000-00-00') {
      InitFirmDate = NFIPCommunityFIRMDate;
    }

    emptyQuote.propertyInfo.s_CountyName = (zDData.CountyName || '');
    emptyQuote.propertyInfo.s_CommunityNumber = (zDData.CommunityNumber || '');
    emptyQuote.propertyInfo.d_InitialFirmDate = InitFirmDate
    const NFIPCommunityParticipationStatusType = zDData.NFIPCommunityParticipationStatusType || '';
    emptyQuote.propertyInfo.s_FloodProgramType = (NFIPCommunityParticipationStatusType.includes('Regular') ? 'FLOODREGULAR' : NFIPCommunityParticipationStatusType.includes('Emergency') ? 'FLOODEMERGENCY' : '') || null;

    const ProtectedAreaIndicator = zDData.cbrs || '';
    emptyQuote.propertyInfo.s_CBRSorOPA = (ProtectedAreaIndicator === 'Y' ? 'YES' : (ProtectedAreaIndicator === 'N' ? 'NO' : 'NO'));
    emptyQuote.propertyInfo.d_CurrentFirmDate = (zDData.CurrentFirmDate || '');
    emptyQuote.propertyInfo.s_PanelNumber = (zDData.PanelNumber || '');
    emptyQuote.propertyInfo.s_MapSuffix = (zDData.MapSuffix || '');
    var floodZone = (zDData.Floodzone || '');
    emptyQuote.propertyInfo.s_FloodZone = (['X500', 'X100'].includes(floodZone) ? 'X' : floodZone || '');
    var elevation = "";
    if (zDData.BaseElevation) {
      elevation = zDData.BaseElevation; // use upto 1 decimal; already done from backend
    }
    emptyQuote.propertyInfo.s_BaseElevation = (elevation || null);
    emptyQuote.personAddr.s_countyFips = zDData.CountyFips != undefined ? zDData.CountyFips.slice(-3) : '';
    emptyQuote.personAddr.s_stateFips = zDData.StateFips ?? '';
    emptyQuote.personAddr.s_Latitude = zDData.Latitude ?? '';
    emptyQuote.personAddr.s_Longitude = zDData.Longitude ?? '';

    // prior flood zone determination
    if (zDData?.isPropertyNewlyMapped && zDData?.isPropertyNewlyMapped === 'YES') {
      emptyQuote.propertyInfo.s_IsPropNewlyMapped = zDData?.isPropertyNewlyMapped;

      if (zDData?.priorDeterminationDetails) {
        let priorDeterminationDetails = zDData?.priorDeterminationDetails;

        emptyQuote.propertyInfo.s_PriorCommunityNumber = priorDeterminationDetails?.priorCommunityNumber;
        emptyQuote.propertyInfo.s_PriorMapSuffix = priorDeterminationDetails?.priorMapSuffix;
        emptyQuote.propertyInfo.s_PriorPanelNumber = priorDeterminationDetails?.priorPanelNumber;
        emptyQuote.propertyInfo.s_PriorFloodZone = priorDeterminationDetails?.priorFloodZone;
        emptyQuote.propertyInfo.d_PriorMapDate = priorDeterminationDetails?.priorMapDate;
      }
    }

    // Zone determination end
    await dispatch(getEditEmptyQuoteData(emptyQuote));
  }

  // To Updated ZD Details into QuoteData
  /* useEffect(() => {
     if (Object.keys(ZDDetails).length > 0 && Object.keys(ZDDetails.data).length > 0) {
       const emptyQuote = emptyQuoteData;

       // Setting ZD Details
       if (ZDDetails.success && ZDDetails.data && ZDDetails.data.Status == 'COMPLETE') {
         const zDData = ZDDetails.data.determination
         if (zDData && zDData.community && Object.keys(zDData.community).length > 0) {
           var community = zDData.community
           var NFIPCommunityFIRMDate = (community.entry_dt || '');
           var InitFirmDate = '';
           if (NFIPCommunityFIRMDate !== '' && NFIPCommunityFIRMDate !== null && NFIPCommunityFIRMDate !== '0000-00-00') {
             InitFirmDate = NFIPCommunityFIRMDate;
           }

           emptyQuote.propertyInfo.s_CountyName = (zDData.county.countyname || '');
           emptyQuote.propertyInfo.s_CommunityNumber = (community.cid || '');
           emptyQuote.propertyInfo.d_InitialFirmDate = InitFirmDate

       var NFIPCommunityParticipationStatusType = zDData.community.type || '';
           emptyQuote.propertyInfo.s_FloodProgramType = (NFIPCommunityParticipationStatusType.includes('Regular') ? 'FLOODREGULAR' : NFIPCommunityParticipationStatusType.includes('Emergency') ? 'FLOODEMERGENCY' : '') || null;
         }

     var ProtectedAreaIndicator = zDData.cbrs || '';
           emptyQuote.propertyInfo.s_CBRSorOPA = ProtectedAreaIndicator === 'Y' ? 'YES' : ProtectedAreaIndicator === 'N' ? 'NO' : 'NO';

         if (zDData && zDData.firm && zDData.firm && Object.keys(zDData.firm).length > 0) {
           var firm = zDData.firm
           var CurrentFirmDate = '';
           var NFIPMapPanelDate = (firm.eff_date || '');
           if (NFIPMapPanelDate !== '' && NFIPMapPanelDate !== null && NFIPMapPanelDate !== '0000-00-00') {
             CurrentFirmDate = NFIPMapPanelDate;
           }

           emptyQuote.propertyInfo.d_CurrentFirmDate = CurrentFirmDate
           emptyQuote.propertyInfo.s_PanelNumber = (firm.panel || '');
           emptyQuote.propertyInfo.s_MapSuffix = (firm.suffix || '');


         }

     if (zDData && zDData.floodzone && Object.keys(zDData.floodzone).length > 0) {
       var floodZone = (zDData.floodzone.fld_zone || '');
       emptyQuote.propertyInfo.s_FloodZone = (['X500', 'X100'].includes(floodZone) ? 'X' : floodZone || '');
     }

     if (zDData && zDData.position && Object.keys(zDData.position).length > 0) {
       if(zDData.position.elevation){
         var elevation = Math.round(zDData.position.elevation);
       }else{
         var elevation = ''
       }
       emptyQuote.propertyInfo.s_BaseElevation = (elevation  || null);
     }


         if (zDData && zDData.county && Object.keys(zDData.county).length > 0) {
           var identification = zDData.county;
           emptyQuote.personAddr.s_countyFips = identification.countyfips.slice(-3);
           emptyQuote.personAddr.s_stateFips = identification.statefips;
         }
       }

       dispatch(getEditEmptyQuoteData(emptyQuote));
     }
   }, [dispatch, ZDDetails, emptyQuoteData]);*/
  //  useEffect(() => {
  //    if (Object.keys(ZDDetails).length > 0 && Object.keys(ZDDetails.data).length > 0) {
  //      const emptyQuote = emptyQuoteData;

  //      // Setting ZD Details
  //      if (ZDDetails.success && ZDDetails.data && ZDDetails.data.Status === 'COMPLETE') {
  //        const zDData = ZDDetails.data.FLOOD_DETERMINATION
  //        const property = ZDDetails.data.PROPERTY
  //        if (zDData && zDData._COMMUNITY_INFORMATION && Object.keys(zDData._COMMUNITY_INFORMATION.data).length > 0) {
  //          var community = zDData._COMMUNITY_INFORMATION.data
  //          var NFIPCommunityFIRMDate = (community.NFIPCommunityFIRMDate || '');
  //          var InitFirmDate = '';
  //          if (NFIPCommunityFIRMDate !== '' && NFIPCommunityFIRMDate !== null && NFIPCommunityFIRMDate !== '0000-00-00') {
  //            InitFirmDate = new Date(NFIPCommunityFIRMDate);
  //          }
  //          emptyQuote.propertyInfo.s_CountyName = (community.NFIPCounty || '');
  //          emptyQuote.propertyInfo.s_CommunityNumber = (community.NFIPCommunityIdentifier || '');
  //          emptyQuote.propertyInfo.d_InitialFirmDate = InitFirmDate
  //        }

  //        if (zDData && zDData._BUILDING_INFORMATION && zDData._BUILDING_INFORMATION.data && Object.keys(zDData._BUILDING_INFORMATION.data).length > 0) {
  //          var building = zDData._BUILDING_INFORMATION.data
  //          var floodZone = (building.NFIPFloodZoneIdentifier || '');
  //          var CurrentFirmDate = '';
  //          var NFIPMapPanelDate = (building.NFIPMapPanelDate || '');
  //          if (NFIPMapPanelDate !== '' && NFIPMapPanelDate !== null && NFIPMapPanelDate !== '0000-00-00') {
  //            CurrentFirmDate = new Date(NFIPMapPanelDate);
  //          }

  //          emptyQuote.propertyInfo.d_CurrentFirmDate = CurrentFirmDate
  //          emptyQuote.propertyInfo.s_PanelNumber = (building.NFIPMapPanelIdentifier || '');
  //          emptyQuote.propertyInfo.s_MapSuffix = (building.NFIPMapPanelSuffixIdentifier || '');
  //          emptyQuote.propertyInfo.s_FloodZone = (['X500', 'X100'].includes(floodZone) ? 'X' : floodZone || '');
  //          emptyQuote.propertyInfo.s_BaseElevation = (building.BaseFloodElevationFeetCount || null);
  //        }

  //        if (zDData && Object.keys(zDData._INSURANCE_INFORMATION).length > 0) {
  //          var NFIPCommunityParticipationStatusType = zDData._INSURANCE_INFORMATION.data.NFIPCommunityParticipationStatusType || '';
  //          emptyQuote.propertyInfo.s_FloodProgramType = (NFIPCommunityParticipationStatusType === 'Regular' ? 'FLOODREGULAR' : NFIPCommunityParticipationStatusType === 'Emergency' ? 'FLOODEMERGENCY' : '') || null;

  //          var ProtectedAreaIndicator = zDData._INSURANCE_INFORMATION.data.ProtectedAreaIndicator || '';
  //          emptyQuote.propertyInfo.s_CBRSorOPA = ProtectedAreaIndicator === 'Y' ? 'YES' : ProtectedAreaIndicator === 'N' ? 'NO' : null;
  //        }

  //        if (property && property._IDENTIFICATION && Object.keys(property._IDENTIFICATION.data).length > 0) {
  //          var identification = property._IDENTIFICATION.data;
  //          emptyQuote.personAddr.s_countyFips = identification.CountyFIPSCode;
  //          emptyQuote.personAddr.s_stateFips = identification.StateFIPSCode;
  //        }
  //      }
  //      dispatch(getEditEmptyQuoteData(emptyQuote));
  //    }
  //  }, [dispatch, ZDDetails, emptyQuoteData]);

  useEffect(() => {

    if (Object.keys(quoteResponseDetails).length > 0) {
      if (quoteResponseDetails.data && Object.keys(quoteResponseDetails.data).length > 0) {
        if (isPivotOutage == 'true') {
          setPremium(quoteResponseDetails.data.premium || 0);
        } else {
          var code = quoteResponseDetails.data.code || 0;
          if ([200, 201].includes(code) && Object.keys(quoteResponseDetails.data.rreQuote).length > 0) {
            setPremium(quoteResponseDetails.data.rreQuote.premium.totalAnnualPayment || 0);
          } else {
            showError();
            setPremium(0);
          }
        }

      }
    }
  }, [quoteResponseDetails, createQuoteResponse]);

  // trigger to update Firm Type Based on d_DateOfConstruction & d_InitialFirmDate
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0 && emptyQuoteData.propertyInfo.d_DateOfConstruction && emptyQuoteData.propertyInfo.d_InitialFirmDate && !isFirmUpdated) {
      var temp = false;
      if ((new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) <= new Date('1974-12-31')) || (new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) < new Date(emptyQuoteData.propertyInfo.d_InitialFirmDate))) {
        temp = true;
      }
      // default historical question to no if it is post firm, and if it is pre firm ask the question
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.propertyInfo.s_FloodConstructionType = temp ? 'FLDPREFIRM' : 'FLDPOSTFIRM';

      dispatch(getEditEmptyQuoteData(emptyQuote));
      setIsFirmUpdated(true);
    }
  }, [emptyQuoteData, isFirmUpdated]);

  const showError = () => {
    const resp = quoteResponseDetails && Object.keys(quoteResponseDetails.data).length > 0 ? quoteResponseDetails.data : {};

    resp && Object.keys(resp).length > 0 && resp.code && ![200, 201].includes(resp.code) && (
      setErrorModal(<Modal show={true}>
        <Modal.Header>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            resp && Object.keys(resp).length > 0 && ![200, 201].includes(resp.code) && (
              <React.Fragment>
                <span style={{ color: 'red' }}>
                  {resp.message}
                </span>
                {
                  resp.fieldErrors && resp.fieldErrors.length > 0 && (
                    <ul>
                      {
                        resp.fieldErrors.map((val, key) => {
                          return <li key={key}>{val.field} - {val.message}</li>
                        })
                      }
                    </ul>
                  )
                }
                {
                  resp.ruleFailures && resp.ruleFailures.length > 0 && (
                    <ul>
                      {
                        resp.ruleFailures.map((val, key) => {
                          if (val.rule && val.rule.severity !== 'WARNING' && val.exception && val.exception !== '') {
                            return <li key={key}>{val.exception}</li>
                          }
                        })
                      }
                    </ul>
                  )
                }
              </React.Fragment>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="outline-dark" onClick={() => setErrorModal(null)} autoFocus={true}>Close</Button>
        </Modal.Footer>
      </Modal>)
    )
  }

  /**
   * Update data on every step
   */
  const handleUpdateArray = (parent, key, value) => {
    let currentUpdateArray = { ...updateArray };
    if (!currentUpdateArray[parent]) {
      currentUpdateArray[parent] = {};
    }
    currentUpdateArray[parent][key] = value;
    // map pk 
    if (emptyQuoteData && Object.keys(emptyQuoteData).length > 0) {
      let tablePK = '';

      switch (parent) {
        case "personInfo":
          tablePK = 'n_PersonInfoId_PK';
          break;

        case "personAddr":
        case "personAddrMail":
        case "tempMailingAddr":
          tablePK = 'n_PersonAddressesId_PK';
          break;

        case "personPhoneContact":
        case "personEmailContact":
          tablePK = 'n_CommID_PK';
          break;

        case "propertyInfo":
          tablePK = 'n_floodAdditionalInfoPK';
          break;

        case "policyInfo":
          tablePK = 'n_PolicyNoId_PK';
          break;

        case "transactionInfo":
          tablePK = 'n_potransaction_PK';
          break;

        default:
          tablePK = '';
          break;
      }

      if (tablePK) {
        currentUpdateArray[parent][tablePK] = emptyQuoteData[parent][tablePK];
      }
    }

    setUpdateArray(currentUpdateArray);
  }

  /**
   * Save quote data on every step
   */
  const saveQuoteData = async () => {
    let request = { ...updateArray };
    if (request && Object.keys(request).length == 0) return false;
    // change date formate to Y-m-d
    for (const [key, val] of Object.entries(request)) {
      if (['personInfo', 'propertyInfo', 'policyInfo', 'transactionInfo'].includes(key)) {
        for (const [innerKey, innerVal] of Object.entries(val)) {
          if ((innerKey.indexOf('d_') === 0) || ['s_PriorPolicyExpDt'].includes(innerKey)) {
            if (checkDateisValid(innerVal)) {
              request[key][innerKey] = Moment(innerVal).format('YYYY-MM-DD')
            }
          }
        }
      }
    }

    await dispatch(storeFloodQuoteStepData(request)).then((res) => {
      if (res && res.status == 'Y') {
        setUpdateArray([]);
      }
    }).catch((err) => {
      toast.error(err.message || "Something went wrong");
    });
  }

  switch (number) {
    case 'address':
      view = <FloodAddress />;
      break;
    case 'home-type':
      view = <FloodHome />;
      break;
    case '1':
      view = <FloodPropertyDetail handleChange={handleChange} handleDateChange={handleDateChange} saveQuoteData={saveQuoteData}
        handleSwitchChange={handleSwitchChange} checkDateisValid={checkDateisValid} handleUpdateArray={handleUpdateArray} />;
      break;
    case '2':
      view = <StartingEstimate premium={premium} />;
      break;
    case '3':
      view = <FloodContactInformation handleChange={handleChange} saveQuoteData={saveQuoteData} />;
      break;
    case '4':
      view = <WaitingPeriod handleChange={handleChange} handleDateChange={handleDateChange} handleUpdateArray={handleUpdateArray} saveQuoteData={saveQuoteData}
        checkDateisValid={checkDateisValid} />;
      break;
    case '5':
      view = <ConstructionFormOne handleSwitchChange={handleSwitchChange} handleDateChange={handleDateChange} saveQuoteData={saveQuoteData}
        checkDateisValid={checkDateisValid} premium={premium} />;
      break;
    case '6':
      view = <ConstructionFormTwo handleChange={handleChange} handleSwitchChange={handleSwitchChange}
        handleRadioChange={handleRadioChange} checkDateisValid={checkDateisValid} saveQuoteData={saveQuoteData}
        premium={premium} />;
      break;
    case '7':
      view = <DiscountForm handleSwitchChange={handleSwitchChange} saveQuoteData={saveQuoteData} premium={premium} />;
      break;
    case '8':
      view = <DiscountFormOne handleSwitchChange={handleSwitchChange} handleChange={handleChange}
        handleDateChange={handleDateChange} checkDateisValid={checkDateisValid} saveQuoteData={saveQuoteData}
        premium={premium} />;
      break;
    /*case '9':
      view = <DiscountFormTwo handleSwitchChange={handleSwitchChange} handleChange={handleChange} handleDateChange={handleDateChange} checkDateisValid={checkDateisValid} premium={premium} />;
      break;*/
    case '10':
      view = <DiscountFormThree handleSwitchChange={handleSwitchChange} handleChange={handleChange} saveQuoteData={saveQuoteData} premium={premium} />;
      break;
    case '11':
      view = <PreFirmDiscount handleSwitchChange={handleSwitchChange} saveQuoteData={saveQuoteData} premium={premium} />;
      break;
    case '12':
      view = <FloodPricingPlan handleChange={handleChange} setzDDetails={setzDDetails} />;
      break;
    case '13':
      view = <ConstructionFormMH handleChange={handleChange} handleSwitchChange={handleSwitchChange} />;
      break;
    case '14':
      view = <AddMortgagee handleChange={handleChange} handleSwitchChange={handleSwitchChange} />;
      break;
    default:
      view = <FloodAddress />;
  }

  return (
    <>
      <LoadingOverlay>
        <Loader loading={saveLoading} />
        <PivotOutageMsg />
        {/* <ModeToggle step={number}/> */}
        {
          tempQuoteNo && (
            <Alert variant="info" style={{ padding: "8px" }} className="text-center">
              <b>{t("Quote No")} : {tempQuoteNo || ""}</b>
            </Alert>
          )
        }
        {view}
        {errorModal}
      </LoadingOverlay>
    </>
  );
};

export default Steps;
